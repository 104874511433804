import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { APIProvider } from '@vis.gl/react-google-maps';
import * as Sentry from "@sentry/react";

const isDev = import.meta.env.VITE_ENVIRONMENT_NAME === 'development';
const isLocalhostInUrl = window.location.hostname.search('localhost') !== -1;

Sentry.init({
  // TODO move to env variable
  dsn: "https://832dfdc0a415f9911ff68f5f0e490d09@o4504684638240768.ingest.us.sentry.io/4507692505956352",
  environment: import.meta.env.VITE_ENVIRONMENT_NAME,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    console.log(event)
    console.log(hint)
    if (event.exception && event.event_id && !hint?.mechanism?.handled) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      enableScreenshot: true,
      showBranding: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: isDev ? 1.0 : 0.1, // 100% in dev, 10% in production
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  tracesSampleRate: isDev ? 1.0 : 0.1, // 100% in dev, 10% in production
  enabled: !isDev && !isLocalhostInUrl,
});

Sentry.setTag("hostname", window.location.hostname);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary fallback={
    <>
      <p>An unexpected error has occurred. The ROGO software team has been notified. Please reach out to software@rogoag.com or use our <a href="https://airtable.com/appG2uelyCZuENMT8/pagzG52leZoMbzYb8/form" target="_blank">software feedback form</a> with any additional questions or concerns.</p>
      <p>Please try to refresh your browser and visit <a href="https://portal.rogoag.com/jobs/dashboard">your dashboard</a> again</p>
    </>
  }>
    <StrictMode>
      <APIProvider apiKey={"AIzaSyAh7mmN6OadSgjbbF1UDvb_a0PokVeZ9q8"}>
        <App />
      </APIProvider>
    </StrictMode>
  </Sentry.ErrorBoundary>
);
