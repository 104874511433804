import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material';

interface ButtonAppBarProps {
  drawerOpen: boolean;
  setDrawerVisibility: (open: boolean) => void;
  logout: () => void;
  title: string;
}

export default function ButtonAppBar(props: ButtonAppBarProps) {
  const theme = useTheme();
  // const pathname = location.pathname;
  // let title = 'Home';
  // if (pathname && pathname.length > 1) {
  //   // console.log(pathname);
  //   // console.log(pathname.split('/').pop())
  //   // console.log(pathname.split('/').pop() ?? 'Home');
  //   title = titleCase((pathname.split('/').pop() ?? 'Home').replace("_", " "));
  // }
  // console.log(location);

  return (
    <Box style={{ zIndex: theme.zIndex.drawer + 1 }} sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => props.setDrawerVisibility(!props.drawerOpen)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
          {/* <Button color="inherit" onClick={() => navigate('/login')}>Login</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}