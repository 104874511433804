import _ from 'lodash';
import Airtable from 'airtable';
import { TableIds, TableIdToObjectMapping } from './rogo.at';

/* INSTANCE */
/**
 * Creates an instance of Airtable.
 *
 * @param {string} apiKey - The API key for Airtable.
 * @param {string} baseID - The base ID for Airtable.
 * @returns {Airtable.Base} An instance of Airtable.
 */
function createInstance(apiKey, baseID, noRetryIfRateLimited = false) {
  return new Airtable({ apiKey, noRetryIfRateLimited }).base(baseID);
}

/* CRUD METHODS */
/**
 * Finds a record in a table.
 *
 * @param {Airtable.Table<T extends Airtable.FieldSet>} table - The table to search in.
 * @param {string} recordID - The ID of the record to find.
 * @returns {Promise<ATRecord<T>>} A promise that resolves with the found record.
 */
function find(table, recordID) {
  return new Promise((res, rej) => {
    table.find(recordID, function (err, record) {
      if (err) {
        rej(err);
        return;
      }

      const rec = {
        id: record.id,
        ...record.fields
      };

      res(rec);
    });
  });
}

/**
 * Selects records from a table.
 *
 * @param {Airtable.Table<T extends Airtable.FieldSet>} table - The table to select from.
 * @param {Airtable.SelectOptions} [query={}] - The query to use for selection.
 * @param {{ skipMapping: boolean, transform: (T => any) }} [options={}] - The options for selection.
 * @returns {Promise<ATRecord<T>[]>} A promise that resolves with the selected records.
 */
function select(table, query = {}, options = {}) {
  let records = [];

  const promise = new Promise((res, rej) => {
    table
      .select(query)
      .eachPage(function page(r, fetchNextPage) {
        records.push(...r);
        fetchNextPage();
      }, function done(err) {
        if (err) {
          rej(err);
          return;
        }

        if (!options || !options.skipMapping) {
          records = _.map(
            records,
            (record) => {
              record.fields.id = record.id;
              record.fields.table = table.name;
              if (options.transform) {
                return options.transform(record.fields);
              }
              return record.fields;
            },
          );
        }

        res(records);
      });
  });

  return promise
    .catch((err) => {
      console.log(err);
      return Promise.reject('An error occurred while selecting from Airtable.');
    });
};

/**
 * 
 * @param {string} table 
 * @param {*} query 
 * @param {*} options 
 * @returns 
 */
export function selectOne(table, query = {}, options = {}) {
  return select(table, query, options)
    .then((records) => {
      if (records.length === 0) {
        return Promise.reject('No records found.');
      }

      return records[0];
    });
}

/**
 * Updates records in a table.
 *
 * @param {Airtable.Table<T extends Airtable.FieldSet>} table - The base to update in.
 * @param {ATRecord<Partial<T>>[]} recordsToUpdate - The records to update.
 * @returns {Promise<ATRecord<T>[]>} A promise that resolves with the updated records.
 */
function update(table, recordsToUpdate) {
  const recordsChunked = mapAndChunk(recordsToUpdate);
  const promises = [];

  _.each(recordsChunked, (records) => {
    const promise = new Promise((res, rej) => {
      table
        .update(records, function (err, r) {
          if (err) {
            rej(err);
            return;
          }

          res(r);
        });
    });

    promises.push(promise);
  });

  return Promise.all(promises)
    .then(dechunkAndMap)
    .catch((err) => {
      console.log(err);
      return Promise.reject('An error occurred while updating Airtable records.');
    });
};

/**
 * Replaces records in a table.
 *
 * @param {Airtable.Base<T>} base - The base to replace in.
 * @param {} recordsToReplace - The records to replace.
 * @returns {Promise<any>} A promise that resolves when the records have been replaced.
 */
function replace(base, recordsToReplace) {
  const recordsChunked = mapAndChunk(recordsToReplace);
  const promises = [];

  _.each(recordsChunked, (records) => {
    const promise = new Promise((res, rej) => {
      base
        .replace(records, function (err, r) {
          if (err) {
            rej(err);
            return;
          }

          res(r);
        });
    });

    promises.push(promise);
  });

  return Promise.all(promises)
    .then(dechunkAndMap)
    .catch((err) => {
      console.log(err);
      return Promise.reject('An error occurred while replacing Airtable records.');
    });
};

function create(base, recordsToCreate) {
  const recordsChunked = mapAndChunk(recordsToCreate);
  const promises = [];

  _.each(recordsChunked, (records) => {
    const promise = new Promise((res, rej) => {
      base
        .create(records, function (err, r) {
          if (err) {
            rej(err);
            return;
          }

          res(r);
        });
    });

    promises.push(promise);
  });

  return Promise.all(promises)
    .then(dechunkAndMap)
    .catch((err) => {
      console.log(err);
      return Promise.reject('An error occurred while creating Airtable records.');
    });
}

function remove(base, recordsToRemove) {
  const recordIDsToRemoveChunked = _(recordsToRemove)
    .map('id')
    .chunk(10)
    .value();

  const promises = [];

  _.each(recordIDsToRemoveChunked, (recordIDs) => {
    const promise = new Promise((res, rej) => {
      base
        .destroy(recordIDs, function (err, r) {
          if (err) {
            rej(err);
            return;
          }

          res(r);
        });
    });

    promises.push(promise);
  });

  return Promise.all(promises)
    .catch((err) => {
      console.log(err);
      return Promise.reject('An error occurred while removing Airtable records.');
    });
}

function mapAndChunk(records) {
  let fields = _.reduce(records, (merged, record) => _.extend({}, merged, record));
  fields = _(fields)
    .keys()
    .pull('id', 'table')
    .value();

  const recordsChunked = _(records)
    .map((record) => ({
      id: record.id,
      fields: _.pick(record, fields),
    }))
    .chunk(10)
    .value();

  return recordsChunked;
}

function dechunkAndMap(chunkedRecords) {
  return _(chunkedRecords)
    .flatten()
    .map((record) => {
      record.fields.id = record.id;
      return record.fields;
    })
    .value();
}

/* ERROR HANDLING */
const LIST_DELIMITER = '\n';
const CODE_DELIMITER = '::';

class AirtableErrorMsg {
  constructor(errorMsg) {
    this.errorMsg = errorMsg
  }
}

class AirtableError {
  constructor(err) {
    let errorCode, errorMsg;

    switch (typeof err) {
      case 'string':
        [errorCode, errorMsg] = _.split(err, CODE_DELIMITER);
        break;
      default:
        ({ errorCode, errorMsg } = err);
    }

    this.errorCode = errorCode;
    this.errorMsg = errorMsg;
  }
}

class AirtableErrorList {
  constructor(csv = '', serviceID = '') {
    this.list = [];

    if (csv != '') {
      this.list = _(csv)
        .split(LIST_DELIMITER)
        .uniq()
        .map((errorStr) => new AirtableError(errorStr))
        .value();
    }

    this.copy = _.cloneDeep(this.list);
    this.serviceID = serviceID || 'Unknown Service';
  }

  add(errorMsg) {
    if (errorMsg instanceof AirtableErrorMsg) {
      ({ errorMsg } = errorMsg);
    }

    if (!this.find(errorMsg)) {
      const error = new AirtableError({
        errorCode: this.serviceID,
        errorMsg,
      });

      this.list.push(error);
    }
  }

  remove(errorMsg) {
    if (errorMsg instanceof AirtableErrorMsg) {
      ({ errorMsg } = errorMsg);
    }

    this.list = _.reject(
      this.list,
      { errorMsg },
    );
  }

  removeLike(partialErrorMsg) {
    this.list = _.reject(
      this.list,
      ({ errorMsg }) => errorMsg.includes(partialErrorMsg),
    );
  }

  find(errorMsg) {
    if (errorMsg instanceof AirtableErrorMsg) {
      ({ errorMsg } = errorMsg);
    }

    return _.find(
      this.list,
      { errorCode: this.serviceID, errorMsg },
    );
  }

  has(errorMsg) {
    return !!this.find(errorMsg);
  }

  hasErrors() {
    return this.list.length > 0;
  }

  clear() {
    this.list = [];
  }

  filterById(id) {
    return _.filter(
      this.list,
      ({ errorCode }) => errorCode === id,
    )
  }

  filterService() {
    return this.filterById(this.serviceID)
  }

  clearService() {
    this.list = _.reject(
      this.list,
      ({ errorCode }) => errorCode === this.serviceID,
    );
  }

  revert() {
    this.list = _.cloneDeep(this.copy);
  }

  serialize() {
    const serialized = _(this.list)
      .map((error) => `${error.errorCode}${CODE_DELIMITER}${error.errorMsg}`)
      .join(LIST_DELIMITER);

    if (!serialized) {
      return '';
    }

    return serialized;
  }
}

/**
 * 
 * @param {string} tableName 
 * @param  {string[]} fieldNames 
 * @returns string[]
 */
function getFieldIds(tableName, fieldNames) {
  const mappingTable = TableIdToObjectMapping[TableIds[tableName]];
  return fieldNames.map((fieldName) => fieldName in mappingTable ? mappingTable[fieldName] : fieldName);
}

/**
 * 
 * @param {string} tableName 
 * @param {string} fieldName 
 * @returns string
 */
function getFieldId(tableName, fieldName) {
  const mappingTable = TableIdToObjectMapping[TableIds[tableName]];
  if (!mappingTable) {
    console.warn(`Table ${tableName} not found in TableIdToObjectMapping`);
    return fieldName;
  }
  if (fieldName in mappingTable) {
    return mappingTable[fieldName];
  }
  console.warn(`Field ${fieldName} not found in Table ${tableName}`);

  return fieldName;
}

function isNullOrUndefined(obj) {
  return typeof obj === "undefined" || obj === null;
}

function getField(record, fieldName, defaultValue = undefined, isFieldName = true) {
  if (!record) {
    return defaultValue;
  }
  
  const fieldId = (isFieldName && record && record.table) ? getFieldId(record.table, fieldName) : fieldName;

  const recordIdValue = record[fieldId]
  if (!isNullOrUndefined(recordIdValue)) {
    return recordIdValue;
  }

  const recordNameValue = record[fieldName];
  if (!isNullOrUndefined(recordNameValue)) {
    return recordNameValue;
  }

  return defaultValue;
}

function setField(record, fieldName, value, isFieldName = true) {
  const fieldId = (isFieldName && record && record.table) ? getFieldId(record.table, fieldName) : fieldName;
  record[fieldId] = value;
}

export {
  createInstance,
  find,
  select,
  update,
  replace,
  create,
  remove,
  getFieldId,
  getFieldIds,
  getField,
  setField,
  AirtableError,
  AirtableErrorMsg,
  AirtableErrorList,
};
