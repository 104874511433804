import { IAirtableAttachment } from "@rogoag/airtable";
import { upload } from "./api/s3_ops";
import { wktToGeoJSON } from "@terraformer/wkt";
import { Geometry, FeatureCollection, Feature, Polygon, MultiPolygon, Point } from "geojson";
import { DownloadOptions, ZipOptions } from "@mapbox/shp-write";
import shpwrite from '@mapbox/shp-write';

import { Circle, Quadtree, Rectangle } from '@timohausmann/quadtree-ts';
import { RogoFeatures } from "./types";
import { SHP } from "./geojson_converters/shp";
import JSZip from "jszip";


const combineFeatureColletions = (featureCollections: FeatureCollection[]) => {
    const featureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    };
    featureCollections.forEach((fc) => {
        featureCollection.features = featureCollection.features.concat(fc.features);
    });
    return featureCollection;
}

const featureCollectionToFeatures = (featureCollections: FeatureCollection[]) => {
    return featureCollections.reduce((acc, featureCollection) => {
        return acc.concat(featureCollection.features);
    }, [] as Feature[]);
}

// This function takes a list of feature collections that might include polygons or points
// sometimes a feature collection might be a set of polygons that is included inside another polygon
// this function will group those polygons together 
export async function groupFeatures(featureCollections: FeatureCollection[]) {
    const results: Record<string, FeatureCollection[]> = {
        'Boundaries': [],
        'Zones': [],
        'Points': [],
    }
    for (const fc of featureCollections) {
        if (fc.features.length === 0) {
            continue;
        }

        const shapeTypes = new Set(fc.features.map(f => f.geometry.type));
        // console.log(shapeTypes);

        if (fc.features.length === 1 && fc.features[0].geometry.type !== 'Point') {
            results['Boundaries'].push(fc);
            continue;
        }

        if (fc.features[0].geometry.type === 'Point') {
            results['Points'].push(fc);
            continue;
        }

        results['Zones'].push(fc);
    }

    const boundariesBlob = await SHP.fromGeoJSON(combineFeatureColletions(results['Boundaries']));
    const zonesBlob = await SHP.fromGeoJSON(combineFeatureColletions(results['Zones']));
    const pointsBlob = await SHP.fromGeoJSON(combineFeatureColletions(results['Points']));

    const zip2 = new JSZip();

    zip2.file('Boundaries.zip', boundariesBlob);
    zip2.file('Zones.zip', zonesBlob);
    zip2.file('Points.zip', pointsBlob);

    const content2 = await zip2.generateAsync({ type: "blob" });

    return content2;
    
    const features = featureCollectionToFeatures(featureCollections);
    const startTime = Date.now();
    // iterate through each feature collection to find the maxs and mins
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;
    let featuresWithoutBoundingBoxes = 0;
    let featureCounter: Record<string, number> = {};
    let featuresByType: Record<string, RogoFeatures[]> = {};
    for (const feature of features) {
        featureCounter[feature.geometry.type] = featureCounter[feature.geometry.type] ? featureCounter[feature.geometry.type] + 1 : 1;
        // @ts-ignore
        featuresByType[feature.geometry.type] = featuresByType[feature.geometry.type] ? featuresByType[feature.geometry.type].concat(feature) : [feature];

        // if the feature is a point...
        if (feature.geometry.type === 'Point') {
            // @ts-ignore
            const point = feature.geometry.coordinates as number[];
            minLat = Math.min(minLat, point[1]);
            maxLat = Math.max(maxLat, point[1]);
            minLng = Math.min(minLng, point[0]);
            maxLng = Math.max(maxLng, point[0]);
        } else {
            // @ts-ignore
            const coordinates = feature.geometry.type === 'Polygon' ? [feature.geometry.coordinates] : feature.geometry.coordinates;
            let featureMinLat = Infinity;
            let featureMaxLat = -Infinity;
            let featureMinLng = Infinity;
            let featureMaxLng = -Infinity;

            for (const coord of coordinates) {
                for (const ring of coord) {
                    for (const point of ring) {
                        featureMinLat = Math.min(featureMinLat, point[1]);
                        featureMaxLat = Math.max(featureMaxLat, point[1]);
                        featureMinLng = Math.min(featureMinLng, point[0]);
                        featureMaxLng = Math.max(featureMaxLng, point[0]);

                        minLat = Math.min(minLat, point[1]);
                        maxLat = Math.max(maxLat, point[1]);
                        minLng = Math.min(minLng, point[0]);
                        maxLng = Math.max(maxLng, point[0]);
                    }
                }
            }
            feature.bbox = [featureMinLng, featureMinLat, featureMaxLng, featureMaxLat];
        }
    }

    const width = maxLng - minLng;
    const height = maxLat - minLat;
    // console.log(minLat, maxLat, minLng, maxLng, width, height, featuresWithoutBoundingBoxes, featureCounter);
    // console.log('x', minLng, 'y', minLat, 'width', width, 'height', height);
    // 40.05443166 40.3146053796262 -86.84744698169293 -85.875893 0 {Polygon: 4944, Point: 25154, MultiPolygon: 465}

    const polygonsOnly = features.filter(f => f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon');

    const polygonQuadTree = new Quadtree({
        x: minLng,
        y: minLat,
        width,
        height,
        maxLevels: 10,
    });
    const rectanglePolygons: Rectangle<RogoFeatures>[] = [];
    for (const feature of polygonsOnly) {        
        const bbox = feature.bbox as [number, number, number, number];
        const x = bbox[0];
        const y = bbox[1];
        const r = new Rectangle({
            x,
            y,
            width: bbox[2] - bbox[0],
            height: bbox[3] - bbox[1],
            data: feature
        });
        // @ts-ignore
        rectanglePolygons.push(r);
        polygonQuadTree.insert(r);
        // console.log('x', x, 'y', y);
    }

    const zip = new JSZip();
    for (const r of rectanglePolygons) {
        const intersecting = polygonQuadTree.retrieve(r);
        // @ts-ignore
        // console.log(r, intersecting.map(i => i.data));
        // const features = intersecting.map(i => (i.data as Feature));

        // const buffer = await SHP.fromGeoJSON({
        //     type: 'FeatureCollection',
        //     features: features
        // });

        // const filename = `${Math.round(Math.random()*100000)}.zip`;
        // zip.file(filename, buffer);
    }

    const content = await zip.generateAsync({ type: "blob" });
    console.log(`groupFeatures took ${Date.now() - startTime}ms`);
    return content;
}

export function titleCase(str: string) {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function stringRandomColor(input: string) {
    let hash = 0;
    for (var i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    console.log(hash);
    return hash.toFixed(16)
}

export function wktStringsToGeoJSON(wkts: string[]) {
    const featureCollecion: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    };
    wkts.map(wkt => {
        const feature: Geometry | Feature | FeatureCollection = wktToGeoJSON(wkt);
        if (feature.type === 'FeatureCollection') {
            featureCollecion.features = featureCollecion.features.concat(feature.features);
        } else if (feature.type === 'Feature') {
            featureCollecion.features.push(feature);
        } else {
            featureCollecion.features.push({
                type: 'Feature',
                geometry: feature,
                properties: {}
            });
        }
    })
    return featureCollecion;

}

export function geoJSONToJSONFile(geoJSON: FeatureCollection, filename: string) {
    // write to geojson file
    const geojson = JSON.stringify(geoJSON);
    const blob = new Blob([geojson], { type: 'application/json' });
    const file = new File([blob], `${filename}.geojson`); // field.ID}_${(new Date()).valueOf()}_pts
    return file;
}

export async function geoJSONToSHPFile(geoJSON: FeatureCollection, filename: string) {
    // write to geojson file
    const geojson = JSON.stringify(geoJSON);
    const options: DownloadOptions & ZipOptions = {
        types: {
            polygon: `${filename}_bnd`,
            point: `${filename}_pts`,
        },
        compression: 'DEFLATE',
        outputType: 'blob'
    };
    const blobData = await shpwrite.zip<"blob">(geoJSON, options)
    const file = new File([blobData], `${filename}.zip`, { type: 'application/zip' });
    return file;
}

// @ts-ignore
export function stringToColor(input: string) {
    var colors = [
        "#e51c23",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#5677fc",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#259b24",
        "#8bc34a",
        "#afb42b",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b"
    ]
    var hash = 0;
    if (input.length === 0) return hash.toString();
    for (var i = 0; i < input.length; i++) {
        hash = input.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
}

// default typed local storage getter with optional default value
export function localStorageGet<T>(key: string, defaultValue: T): T | undefined {
    const localStorageRawValue = localStorage.getItem(key);
    if (localStorageRawValue && localStorageRawValue !== '') {
        return JSON.parse(localStorageRawValue) as T;
    }
    // this should only happen initially, then never again
    // this is so as a dev we can make edits to the value in unique 
    // circumstances. This guarantees it exists in local storage
    //localStorageSet(key, defaultValue);
    return defaultValue;
}

// default typed local storage setter
export function localStorageSet<T>(key: string, value: T) {
    if (!value) {
        localStorage.removeItem(key);
        return;
    }
    localStorage.setItem(key, JSON.stringify(value));
}

// default typed local storage generator
export function LocalStorageGenerator<T>(
    key: string,
    defaultValue: T,
    {
        // can force this to be undefined because we are requiring our default value
        getter = (defaultValue: T) => localStorageGet<T>(key, defaultValue)!,
        setter = (value: T | undefined) => localStorageSet(key, value)
    } = {}
) {
    return {
        key,
        get: () => getter(defaultValue),
        set: setter,
        reset: () => setter(defaultValue),
    }
};

export function toSamplingEventDateString(date: Date) {
    // Weekday MMM DD YYYY
    let dayOfTheWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    let month = date.toLocaleDateString('en-US', { month: 'short' });
    let day = date.toLocaleDateString('en-US', { day: 'numeric' });
    let year = date.toLocaleDateString('en-US', { year: 'numeric' });
    return `${dayOfTheWeek} ${month} ${day} ${year}`;
}

export function file2Buffer(file: File): Promise<ArrayBuffer | null> {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        const readFile = function (event: ProgressEvent<FileReader>) {
            const buffer = reader.result;
            resolve(buffer as ArrayBuffer | null);
        }

        reader.addEventListener('load', readFile)
        reader.readAsArrayBuffer(file)
    })
}

export function flattenObjects<T extends string | number | symbol, U>(records: Record<T, U>[]): Record<T, U> {
    return records.reduce((acc, record) => {
        return { ...acc, ...record };
    }, {} as Record<T, U>);
}

export function flattenArrayObjects<T extends string | number | symbol, U>(records: Record<T, U[]>[]): Record<T, U[]> {
    // TODO should do this better...
    if (records.length === 0) return {} as Record<T, U[]>;

    const result: Record<T, U[]> = { ...records[0] };
    // reset all keys of result
    for (const key of Object.keys(result)) {
        // @ts-ignore
        result[key] = [];
    }
    for (const record of records) {
        for (const [key, value] of Object.entries(record)) {
            // @ts-ignore
            if (!result[key]) {
                // @ts-ignore
                result[key] = [];
            }
            // @ts-ignore
            result[key] = result[key].concat(value);
        }
    }
    return result

    // return records.reduce((acc, record) => {
    //     return { ...acc, ...record };
    // }, {} as Record<T, U>);
}

export function allStringsMatchUniqely(strings: string[], substrings: string[]) {
    for (let searchString of substrings) {
        let count = 0;
        for (let stringToken of strings) {
            if (searchString.includes(stringToken)) {
                count++;
            }
        }
        if (count !== 1) {
            return false;
        }
    }
    return true;
}

export async function uploadFilesToS3(fileList: File[] | undefined) {
    if (!fileList) return [];
    console.log(`uploadFilesToS3 fileList: `, fileList);
    // upload files to S3
    // return URLs
    const fileArray = Array.from(fileList);
    const uploadResults = await Promise.all(fileArray.map(async (file) => {
        // @ts-ignore
        const blob = new Blob([file], { type: file.geoType });
        // @ts-ignore
        const result = await upload(file.name, blob, file.geoType);
        return {
            url: result.Location,
            filename: file.name,
        } as IAirtableAttachment;
    }))

    return uploadResults;
}

export function fileListToAirtableAttachment(fileList: File[]): IAirtableAttachment[] {
    const attachments: IAirtableAttachment[] = [];
    if (!fileList) return attachments;
    for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        // @ts-ignore
        const blob = new Blob([file], { type: file.geoType });
        attachments.push({
            filename: fileList[i].name,
            url: URL.createObjectURL(blob),
            size: fileList[i].size,
            // @ts-ignore
            type: fileList[i].geoType,
            id: '',
        });
    }
    return attachments;
}

export function sleep(ms: number) {
    return new Promise<void>((r) => setTimeout(r, ms));
}
