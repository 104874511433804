import { AccountCircle, ElevatorSharp, Fullscreen, Send } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  ListItemIcon,
  lighten,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  Stack,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { ATRecord, Deals, DealsFieldIdMapping, getField } from "@rogoag/airtable";
import {
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_GroupingState,
  MRT_ColumnOrderState,
  MRT_ColumnDef,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ExpandedState,
  MRT_ExpandAllButton,
  MRT_PaginationState,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import { useState, useMemo, useEffect, ChangeEvent } from "react";
import { GeoFileType, JobCreationData, JobsPriorityOvrd, SamplingEvent } from "../types";
import { submitJobs } from "../api/rogo_ops";
import { toast } from "react-toastify";
import { allStringsMatchUniqely } from "../utils";
import { FeatureCollection, Geometry, GeoJsonProperties } from "geojson";

interface JobsImportTableProps {
  jobs: JobCreationData[];
  updateJobs: (jobs: JobCreationData[]) => void;
  deals: ATRecord<Deals>[];
  setDrawerState: (open: boolean) => void;
  setLoading: (task: string | boolean) => void;
  resetForm: () => void;
  allDeals: ATRecord<Deals>[];
  // boundaryOptions?: File[];
  // pointsOptions?: File[];
  // zonesOptions: File[];
  allOptions: Record<GeoFileType, File[]>;
  // setPointsSelection?: (field: string, value: File | null, oldFile: File | null) => void;
  // showPointsFileSelection?: boolean;
}

export default function JobImportTable(props: JobsImportTableProps) {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(true);
  const [jobs, setJobs] = useState<JobCreationData[]>(props.jobs);
  const [allShapefiles, setAllShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  const [availableShapefiles, setAvailableShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  const [readySelectionState, setReadySelectionState] = useState<Record<string, boolean>>({});
  const [recordBoundaryState, setRecordBoundaryState] = useState<Record<string, boolean>>({});
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({
    'farm': false,
  });
  const [grouping, setGrouping] = useState<MRT_GroupingState>([
    'grower'
  ]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
    'grower',
    'field',
  ]);
  // these are the potential options for the autocomplete fields
  const [gridSizes] = useState<number[]>([... new Set([
    ...props.allDeals.flatMap((deal) => { return getField(deal, "Density/Grid #serviceinfo") }),
    ...props.jobs.flatMap((job) => job.gridSize ? [job.gridSize] : [])])
  ]);
  const [depths] = useState<number[]>([... new Set([
    ...props.allDeals.flatMap((deal) => { return getField(deal, "Depth (in.) #serviceinfo") }),
    ...props.jobs.flatMap((job) => job.depth ? [job.depth] : [])])
  ]);
  const [labPackages] = useState<string[]>([... new Set<string>([
    ...props.allDeals.flatMap((deal) => {
      const field = getField(deal, 'Test Pckg Display');
      return field !== undefined && field !== '' ? field : [];
    }),
    ...props.jobs.flatMap((job) => job.labPackage ? [job.labPackage] : []),
    ...["No test package specified"]
  ])]);
  const [micros] = useState<number[]>([...new Set(
    props.allDeals.flatMap((deal) => { return getField(deal, 'Freq of Add-Ons #Every Nth Sample  #bill') })),
  ...props.jobs.flatMap((job) => job.frequencyOfMicros ? [job.frequencyOfMicros] : [])
  ]);

  // this changes the amount of rows
  /*const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (isFullScreen) {
      setPagination({ pageIndex: 0, pageSize: 15 }); // larger page size for full screen
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 }); // default page size
    }
  }, [isFullScreen]);*/


  // these functions are for the checkboxes
  const handleIndividualSampleChange = (event: ChangeEvent<HTMLInputElement>, job: JobCreationData) => {
    const newJobs = [...jobs];
    const newJob = newJobs.find((j) => j.temporaryId === job.temporaryId);
    if (!newJob) return;
    const index = newJobs.indexOf(newJob);
    newJob.readyToSampleNow = event.target.checked;
    newJobs[index] = newJob;
    setJobs(newJobs);

    const growerName = job.growerName;
    const allAreSelected = newJobs.filter(j => j.growerName === growerName).every(j => j.readyToSampleNow);
    setReadySelectionState(prevState => ({
      ...prevState,
      [growerName]: allAreSelected,
    }));
  };

  const handleAggregatedSampleChange = (event: ChangeEvent<HTMLInputElement>, growerName: string) => {
    const isChecked = event.target.checked;
    const newJobs = jobs.map((job) =>
      job.growerName === growerName ? { ...job, readyToSampleNow: isChecked } : job
    );
    setJobs(newJobs);
    setReadySelectionState(prevState => ({
      ...prevState,
      [growerName]: isChecked,
    }));
  };

  const handleRecordBoundaryChange = (event: ChangeEvent<HTMLInputElement>, job: JobCreationData) => {
    const newJobs = [...jobs];
    const newJob = newJobs.find((j) => j.temporaryId === job.temporaryId);
    if (!newJob) return;
    const index = newJobs.indexOf(newJob);
    newJob.recordBoundary = event.target.checked;
    newJobs[index] = newJob;
    setJobs(newJobs);

    const growerName = job.growerName;
    const allAreSelected = newJobs.filter(j => j.growerName === growerName).every(j => j.recordBoundary);
    setRecordBoundaryState(prevState => ({
      ...prevState,
      [growerName]: allAreSelected,
    }));
  };

  const handleRecordBoundaryAggregatedChange = (event: ChangeEvent<HTMLInputElement>, growerName: string) => {
    const isChecked = event.target.checked;
    const newJobs = jobs.map((job) =>
      job.growerName === growerName ? { ...job, recordBoundary: isChecked } : job
    );
    setJobs(newJobs);
    setRecordBoundaryState(prevState => ({
      ...prevState,
      [growerName]: isChecked,
    }));
  };

  const checkErrors = (jobs: JobCreationData[]) => {
    for (const job of jobs) {
        if (job.gridSize == undefined || job.gridSize == 0 || isNaN(parseFloat( job.gridSize.toString()))) {
          setValidationErrors((prev) => ({ ...prev, [job.temporaryId]: "Density must be a number" }));
        } else if (job.depth == undefined || job.depth == 0 || isNaN(parseFloat(job.depth.toString()))) {
          setValidationErrors((prev) => ({ ...prev, [job.temporaryId]: "Depth must be a number" }));
        } else {
          setValidationErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[job.temporaryId];
            return newErrors;
          });
        }
    }
  };


  const findDeal = (deals: Deals[], density: number, depth: number, labPackage: string, micros: number | string) => {
    return deals.find((deal: Deals) => {

      const dealDensity = getField(deal as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
      const dealDepth = getField(deal as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo');
      const dealLabPackage = getField(deal as ATRecord<Partial<Deals>>, 'Test Pckg Display');
      const dealMicros = getField(deal as ATRecord<Partial<Deals>>, 'Freq of Add-Ons #Every Nth Sample  #bill');

      micros = parseFloat(micros.toString())
      return (
        dealDensity == density &&
        dealDepth == depth &&
        dealMicros == micros &&
        (dealLabPackage == labPackage ||
          ((labPackage == '' || labPackage == 'No test package specified') && (dealLabPackage == '' || dealLabPackage == undefined))
        )
      )
    });
  }


  useEffect(() => {
    if (props.allOptions.Boundary) {
      props.allOptions.Boundary.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }
    if (props.allOptions.Points) {
      props.allOptions.Points.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }
    if (props.allOptions.Zones) {
      props.allOptions.Zones.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }

    setAllShapefiles({ ...props.allOptions });
    setAvailableShapefiles({ ...props.allOptions });
  }, [props.allOptions]);


  useEffect(() => {
    // finds matching deals for jobs
    const updatedJobs = props.jobs.map(job => ({
      ...job,
      deal: (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()
    }));


    setJobs(updatedJobs);
  }, [props.jobs, props.deals]);


  // useEffect(() => {
  //   console.log(`JOBIMPORTTABLE JOBS CHANGED TO: ${jobs.length}`);
  //   const allShapefiles = {...props.allOptions};
  //   const newAvailableShapefiles = { ...availableShapefiles };
  //   for (const job of jobs) {
  //     if (job.boundary.length) {
  //       // find potentially matching boundary file
  //       const matchingBoundary = allShapefiles["Boundary"].find(file => file.name === job.boundary[0].name);
  //       if (matchingBoundary) {
  //         job.boundary = [matchingBoundary];
  //         availableShapefiles["Boundary"] = availableShapefiles["Boundary"].filter(file => file.name !== matchingBoundary.name);
  //       } else {
  //         console.log(`No matching boundary file found for ${job.boundary[0].name}`);
  //         job.boundary = [];
  //       }
  //     }

  //     if (job.points.length) {
  //       // find potentially matching points file
  //       const matchingPoints = allShapefiles["Points"].find(file => file.name === job.points[0].name);
  //       if (matchingPoints) {
  //         job.points = [matchingPoints];
  //         availableShapefiles["Points"] = availableShapefiles["Points"].filter(file => file.name !== matchingPoints.name);
  //       } else {
  //         console.log(`No matching points file found for ${job.points[0].name}`);
  //         job.points = [];
  //       }
  //     }

  //     if (job.zones.length) {
  //       // find potentially matching zones file
  //       const matchingZones = allShapefiles["Zones"].find(file => file.name === job.zones[0].name);
  //       if (matchingZones) {
  //         job.zones = [matchingZones];
  //         availableShapefiles["Zones"] = availableShapefiles["Zones"].filter(file => file.name !== matchingZones.name);
  //       } else {
  //         console.log(`No matching zones file found for ${job.zones[0].name}`);
  //         job.zones = [];
  //       }
  //     }
  //   }

  //   setAllShapefiles(allShapefiles);
  //   setAvailableShapefiles(newAvailableShapefiles);
  //   setJobs(jobs);

  // }, [props.allOptions]);

  /*const uniqueGridSizes = new Set<number>();
  for (const deal of props.alldeals) {
    const gridSize = getField(deal, "Density/Grid #serviceinfo");
    if (gridSize) {
      uniqueGridSizes.add(gridSize);
    }
  }*/


  const columns = useMemo<MRT_ColumnDef<JobCreationData>[]>(
    () =>
      [
        {
          accessorKey: 'growerName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'grower',
          filterVariant: 'autocomplete',
          header: 'Grower',
        },
        {
          accessorKey: 'farmName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'farm',
          filterVariant: 'autocomplete',
          header: 'Farm',
        },
        {
          accessorKey: 'fieldName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'field',
          size: 150,
          filterVariant: 'autocomplete',
          header: 'Field',
          aggregationFn: 'count',       
          AggregatedCell: ({ cell }) => {
            return <Box sx={{ backgroundColor: '#d4d5d6', fontWeight: 'bold', padding: '4px', borderRadius: '4px' }}>
              # of Fields: {cell.getValue() as string}
            </Box>
          }
        },
        {
          id: 'readyToSampleNow',
          header: 'Ready?',
          minSize: 100,
          size: 100,
          accessorKey: 'readyToSampleNow',
          enableEditing: false,
          Cell: ({ cell, row }) => {
            row.original.readyToSampleNow = jobs.find(f => f.temporaryId === row.original.temporaryId)?.readyToSampleNow || false;
            return <Checkbox 
                checked={row.original.readyToSampleNow} 
                onChange={(event) => handleIndividualSampleChange(event, row.original)}
                />
            
          },
          AggregatedCell: ({ row }) => {
            const growerName = row.original.growerName;
            return <Checkbox 
              checked={readySelectionState[growerName] || false}
              indeterminate={!(readySelectionState[growerName] || false) && jobs.some(job => job.growerName === growerName && job.readyToSampleNow)}
              onChange={(event) => handleAggregatedSampleChange(event, growerName)}
              />

          }
        },
        {
          id: "deal",
          header: "Configuration",
          accessorKey: 'deal',
          enableEditing: false,
          size: 400,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              fullWidth = {true}
              size = "small"
              value={props.allDeals.find((deal) => deal.id === jobs.find((job) => job.temporaryId === row.original.temporaryId)?.deal ) || { id: 'custom', label: 'No Linked Configuration' }}
              options={[...props.allDeals, { id: 'custom', label: 'No Linked Configuration' }]}
              renderInput={(params) => (
                <TextField
                  error={!!validationErrors?.[row.original.temporaryId]}
                  helperText={validationErrors?.[row.original.temporaryId]}
                  {...params} />
              )}
              onChange={(event, newValue) => {
                  const newJobs = [...jobs];
                  const newJob = newJobs.find((job) => {
                    return job.temporaryId === row.original.temporaryId
                  });
                  if (!newJob) return;
                  const index = newJobs.indexOf(newJob);
                  newJob.deal = newValue?.id || 'custom';
                  row._valuesCache.deal = newJob.deal;
                  if (newJob.deal !== 'custom') {
                    const testPackage = getField(newValue as ATRecord<Partial<Deals>>, 'Test Pckg Display') || 'No test package specified';
                    newJob.gridSize = getField(newValue as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
                    row._valuesCache.gridSize = newJob.gridSize;
                    newJob.depth = getField(newValue as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo') ?? 0;
                    row._valuesCache.depth = newJob.depth;
                    newJob.labPackage = Array.isArray(testPackage) ? testPackage.join('') : testPackage;
                    row._valuesCache.labPackage = newJob.labPackage;
                    newJob.frequencyOfMicros = parseInt(getField(newValue as ATRecord<Partial<Deals>>, 'Freq of Add-Ons #Every Nth Sample  #bill')?.toString() || '0');
                    row._valuesCache.frequencyOfMicros = newJob.frequencyOfMicros;
                  }
                  newJobs[index] = newJob;
                  setJobs(newJobs);

              }}
              getOptionLabel={(option) => {
                
                if (option && option.id === 'custom') {
                  return 'No linked Configuration';
                }

                const density = getField(option as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
                const depth = getField(option as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo');
                const dealType = getField(option as ATRecord<Partial<Deals>>, "Sites Type #serviceinfo")
                const testPackage = getField(option as ATRecord<Partial<Deals>>, 'Test Pckg Display')  || 'No test package specified';
                const dealLabelFields = [density, dealType, depth, testPackage];
                const specialTag = getField(option as ATRecord<Partial<Deals>>, 'Sampling Type Special');
                if (specialTag) {
                  dealLabelFields.push(specialTag);
                }
                const season = getField(option as ATRecord<Partial<Deals>>, "Season");
                dealLabelFields.push(season);
                return `${density} Acre ${dealLabelFields.join(" - ")}`;
              }}
              />
          },
          AggregatedCell: ({ row }) => {
            return <><a style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Update Group: &nbsp;</a><Autocomplete
              fullWidth = {true}
              value = {undefined}
              options={[...props.allDeals, { id: 'custom', label: 'Unlink All Configurations for Group' }]}
              renderInput={(params) => (
                <TextField
                  {...params} />
              )}
              onChange = {
                (event, newValue) => {
                  const dealId = newValue?.id || 'custom';
                  const newJobs = [...jobs];
                  const gridSize = getField(newValue as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
                  const depth = getField(newValue as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo') ?? 0;
                  const testPackage = getField(newValue as ATRecord<Partial<Deals>>, 'Test Pckg Display') || 'No test package specified';
                  const frequencyOfMicros = parseInt(getField(newValue as ATRecord<Partial<Deals>>, 'Freq of Add-Ons #Every Nth Sample  #bill')?.toString() || '0');

                  for (const job of newJobs) {
                    if (job.growerName === row.original.growerName) {
                      job.deal = dealId;
                      if (dealId != 'custom') {
                        job.gridSize = gridSize;
                        job.depth = depth;
                        job.labPackage = Array.isArray(testPackage) ? testPackage.join('') : testPackage;
                        job.frequencyOfMicros = frequencyOfMicros;
                      }
                    }
                  }

                  if (row.subRows) {
                    for (const subRow of row.subRows) {
                      if (subRow.original.growerName === row.original.growerName) {
                        subRow._valuesCache.deal = dealId;
                        subRow._valuesCache.gridSize = gridSize;
                        subRow._valuesCache.depth = depth;
                        subRow._valuesCache.labPackage = Array.isArray(testPackage) ? testPackage.join('') : testPackage;
                        subRow._valuesCache.frequencyOfMicros = frequencyOfMicros;
                      }
                    }
                  }
                  setJobs(newJobs);
                }
              }
              getOptionLabel={(option) => {
                if (option && option.id === 'custom') {
                  return 'Unlink All Configurations for Group';
                }

                const density = getField(option as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
                const depth = getField(option as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo');
                const dealType = getField(option as ATRecord<Partial<Deals>>, "Sites Type #serviceinfo")
                const testPackage = getField(option as ATRecord<Partial<Deals>>, 'Test Pckg Display') || 'No test package specified';
                const dealLabelFields = [density, dealType, depth, testPackage];
                const specialTag = getField(option as ATRecord<Partial<Deals>>, 'Sampling Type Special');
                if (specialTag) {
                  dealLabelFields.push(specialTag);
                }
                const season = getField(option as ATRecord<Partial<Deals>>, "Season");
                dealLabelFields.push(season);
                return `${density} Acre ${dealLabelFields.join(" - ")}`;
              }}
            /></>;
          }
        },
        {
          id: "gridSize",
          header: "Density",
          accessorKey: 'gridSize',
          enableEditing: false,
          size: 160,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value={jobs.find((job) => job.temporaryId === row.original.temporaryId)?.gridSize || ""}
              options={gridSizes}
              renderInput={(params) => (
                <TextField
                  label="Acre density"
                  variant="outlined"
                  error={!!validationErrors?.[cell.id]}
                  helperText={validationErrors?.[cell.id]}
                  required
                  {...params} />
              )}
              onBlur={(event) => {
                const newValue = (event.target as HTMLInputElement).value;

                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return (
                    job.temporaryId === row.original.temporaryId
                  );
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.gridSize = newValue ? parseFloat(newValue.toString()) : undefined;
                row._valuesCache.gridSize = newJob.gridSize;
                newJob.deal = (findDeal(props.allDeals, newJob.gridSize ?? 0, newJob.depth, newJob.labPackage ?? '',newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                row._valuesCache.deal = newJob.deal;
                newJobs[index] = newJob;
                setJobs(newJobs);

                checkErrors(newJobs);
              }}
              getOptionLabel={(gridSize) => {
                return gridSize.toString();
              }}
            />
            
          },
          AggregatedCell: ({ row }) => {
            return <Autocomplete
               freeSolo
               style={{ width: '100%' }}
               id="grid-size-autocomplete"
               value = {undefined}
               options={gridSizes}
               renderInput={(params) => (   
                 <TextField
                   label="Acre density" variant="outlined"
                   {...params} />
               )}
               onChange={(event, newValue) => {
                  const newJobs = [...jobs];
                  for (const job of newJobs) {
                    if (job.growerName === row.original.growerName) {
                      job.gridSize = newValue ? parseFloat(newValue.toString()) : undefined;
                      job.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                    }
                  }

                  if (row.subRows) {
                    for (const subRow of row.subRows) {
                      const job = subRow._valuesCache;
                      if (subRow.original.growerName === row.original.growerName) {
                        subRow._valuesCache.gridSize = newValue;
                        subRow._valuesCache.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                      }
                    }
                  }
                  console.log(newJobs);
                  setJobs(newJobs);
                  checkErrors(newJobs);
               }}
               getOptionLabel={(gridSize) => {
                 return gridSize.toString();
               }}
             />
           }
        },

        {
          id: 'depth',
          header: 'Depth',
          accessorKey: 'depth',
          size: 100,
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value={jobs.find((job) => job.temporaryId === row.original.temporaryId)?.depth.toString() || ""}
              options={depths}
              renderInput={(params) => (
                <TextField
                  label="Depth"
                  variant="outlined"
                  error={!!validationErrors?.[cell.id]}
                  helperText={validationErrors?.[cell.id]}
                  required
                  {...params} />
              )}
              onBlur={(event) => {
                const newValue = (event.target as HTMLInputElement).value;
                if (newValue == null || newValue == "" || isNaN(parseFloat(newValue.toString()))) {
                  setValidationErrors((prev) => ({ ...prev, [cell.id]: "Depth must be a number" }));
                  console.log("validationErrors", validationErrors);
                  return;
                } else {
                  setValidationErrors((prev) => {
                    const newErrors = { ...prev };
                    delete newErrors[cell.id];
                    return newErrors;
                  });
                }
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.depth = parseFloat((event.target as HTMLInputElement).value);
                row._valuesCache.depth = newJob.depth;
                newJob.deal = (findDeal(props.allDeals, newJob.gridSize ?? 0, newJob.depth, newJob.labPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || '').toString();
                row._valuesCache.deal = newJob.deal;
                newJobs[index] = newJob;
                setJobs(newJobs);

                checkErrors(newJobs);

              }}
              getOptionLabel={(depth) => {
                return depth.toString();
              }} />
          },
          AggregatedCell: ({ row }) => {
             return <Autocomplete
                freeSolo
                style={{ width: '100%' }}
                id="grid-size-autocomplete-aggregate"
                value = {null}
                options={depths}
                renderInput={(params) => (
                  <TextField
                    label="Depth"
                    variant="outlined"
                    {...params} />
                )}
                onBlur={(event) => {
                  const newValue = parseFloat((event.target as HTMLInputElement).value);
                  //console.log("newValue aggergate depth", newValue);
                  if (isNaN(newValue)) {
                    return;
                  }
                  const newJobs = [...jobs];
                  for (const job of newJobs) {
                    if (job.growerName === row.original.growerName) {
                      job.depth = parseFloat(newValue.toString());
                      job.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()
                    }
                  }

                  if (row.subRows) {
                    for (const subRow of row.subRows) {
                      const job = subRow._valuesCache;
                      if (subRow.original.growerName === row.original.growerName) {
                        subRow._valuesCache.depth = newValue;
                        subRow._valuesCache.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                      }
                    }
                  }
                  //console.log(newJobs);
                  setJobs(newJobs);
                  checkErrors(newJobs);
                }}/>
           }
        },
        {
          id: 'labPackage',
          header: 'Lab Package',
          accessorKey: 'labPackage',
          size: 220,
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value={jobs.find((job) => job.temporaryId === row.original.temporaryId)?.labPackage || ""}
              options={labPackages}
              renderInput={(params) => (
                <TextField
                  label="Lab Package"
                  variant="outlined"
                  {...params} />
              )}
              onBlur={(event) => {
                const newValue = (event.target as HTMLInputElement).value;

                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.labPackage = (event.target as HTMLInputElement).value;
                row._valuesCache.labPackage = newJob.labPackage;
                newJob.deal = (findDeal(props.allDeals, newJob.gridSize ?? 0, newJob.depth, newJob.labPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                row._valuesCache.deal = newJob.deal;
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}/>
          },
            AggregatedCell: ({ row }) => {
             return <Autocomplete
               freeSolo
               style={{ width: '100%' }}
               id="lab-package-autocomplete"
               value = {null}
               options={labPackages}
               renderInput={(params) => (
                 <TextField
                   label="Lab Package"
                   variant="outlined"
                   {...params} />
               )}
               onChange={(event, newValue) => {
                const newJobs = [...jobs];
                console.log("newValue", newValue);

                for (const job of newJobs) {
                  if (job.growerName === row.original.growerName) {
                    job.labPackage = Array.isArray(newValue) ? newValue.join('') : (newValue ?? 'No test package specified'); // Add null check here
                    job.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '' ,job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                  }
                }

                if (row.subRows) {
                   for (const subRow of row.subRows) {
                    const job = subRow._valuesCache;
                    if (subRow.original.growerName === row.original.growerName) {
                      subRow._valuesCache.labPackage = Array.isArray(newValue) ? newValue.join('') : (newValue ?? 'No test package specified');
                      subRow._valuesCache.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                    }
                   }
                }
                console.log(newJobs);
                setJobs(newJobs);
               }}/>
           }
        },
        {
          id: 'frequencyOfMicros',
          header: 'Micros',
          accessorKey: 'frequencyOfMicros',
          enableEditing: false,
          size: 100,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value={jobs.find((job) => job.temporaryId === row.original.temporaryId)?.frequencyOfMicros || ""}
              options={[...new Set(props.allDeals.flatMap((deal) => {return getField(deal, 'Freq of Add-Ons #Every Nth Sample  #bill')}))]}
              getOptionLabel={(frequency) => {
                return frequency.toString();
              }} 
              renderInput={(params) => (
                <TextField
                  label="Frequency of Micros"
                  variant="outlined"
                  error={!!validationErrors?.[cell.id]}
                  helperText={validationErrors?.[cell.id]}
                  required
                  {...params} />
              )}

              onBlur={(event) => {
                const newValue = (event.target as HTMLInputElement).value;
                console.log(newValue, "newValue")
                console.log(newValue != "")
                if ((newValue != "") && isNaN(parseFloat(newValue.toString()))) {
                  setValidationErrors((prev) => ({ ...prev, [cell.id]: "Frequency of Micros must be a number" }));
                  console.log("validationErrors", validationErrors);
                  return;
                } else {
                  setValidationErrors((prev) => {
                    const newErrors = { ...prev };
                    delete newErrors[cell.id]; 
                    return newErrors;
                  });
                }
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.frequencyOfMicros = parseFloat((event.target as HTMLInputElement).value);
                row._valuesCache.frequencyOfMicros = newJob.frequencyOfMicros;
                newJob.deal = (findDeal(props.allDeals, newJob.gridSize ?? 0, newJob.depth, newJob.labPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                row._valuesCache.deal = newJob.deal;
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}/>
            },
          AggregatedCell: ({ row }) => {
            return <Autocomplete
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value = {null}
              options={micros}
              getOptionLabel={(frequency) => {
                return frequency.toString();
              }} 
              renderInput={(params) => (
                <TextField
                  label="Frequency of Micros"
                  variant="outlined"
                  {...params} />
              )}
              onBlur={(event) => {
                const newValue = (event.target as HTMLInputElement).value;
                if ((newValue != "") && isNaN(parseFloat(newValue.toString()))) {
                  return;
                }
                const newJobs = [...jobs];
                for (const job of newJobs) {
                  if (job.growerName === row.original.growerName) {
                    if (newValue !== null) {
                      job.frequencyOfMicros = parseFloat(newValue.toString());
                    }
                    job.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                  }
                }

                if (row.subRows) {
                  for (const subRow of row.subRows) {
                    const job = subRow._valuesCache;
                    if (subRow.original.growerName === row.original.growerName) {
                      job.frequencyOfMicros = parseFloat(newValue.toString());
                      job.deal = (findDeal(props.allDeals, job.gridSize ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                    }
                  
                  }
                }
                console.log(newJobs);
                setJobs(newJobs);
              }} />
            }
        },
        {
          id: 'fieldPriority',
          header: 'Priority',
          accessorKey: 'fieldPriority',
          minSize: 250,
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              style={{
                width: '100%',
                padding: 0,
                // height: '50%',
              }}
              defaultValue={row.getValue('fieldPriority')}
              options={JobsPriorityOvrd}
              renderInput={(params) => (
                <TextField
                  style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
                  {...params} />
              )}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.fieldPriority = newValue || "Regular Turn (default)";
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(event) => {
                return event; //event.id;
              }}
            />
          },
        },
        {
          id: "boundary",
          header: "Boundary Files",
          accessorKey: 'boundary',
          enableEditing: false,
          minSize: 300,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              style={{
                width: '100%',
                padding: 0,
                // height: '50%',
              }}
              options={availableShapefiles["Boundary"] || []}
              renderInput={(params) => (
                <TextField
                  style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
                  {...params} />
              )}
              value={cell.getValue<File[]>().length ? cell.getValue<File[]>()[0] : null}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                const newAvailableShapefiles = { ...availableShapefiles };
                const availableOptions = newAvailableShapefiles["Boundary"];

                const oldBoundaryFile = newJob.boundary.length ? newJob.boundary[0] : null;
                if (oldBoundaryFile) {
                  availableOptions.push(oldBoundaryFile);
                }

                if (newValue) {
                  const index = availableOptions.indexOf(newValue);
                  if (index >= 0) {
                    availableOptions.splice(index, 1);
                  }
                }

                //newJob.boundary = newValue ? [newValue] : [];
                newJob.boundary = newValue ? [newValue] : [];
                row._valuesCache.boundary = newValue ? [newValue] : [];
                row.toggleSelected();

                availableOptions.sort((file1, file2) => file1.name.localeCompare(file2.name));

                setAvailableShapefiles(newAvailableShapefiles);
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(file) => {
                return file?.name || "";
              }}
            />
          },
        },
        {
          id: "points",
          header: "Points Files",
          accessorKey: 'points',
          enableEditing: false,
          minSize: 300,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              style={{
                width: '100%',
                padding: 0,
                // height: '50%',
              }}
              options={availableShapefiles["Points"] || []}
              renderInput={(params) => (
                <TextField
                  style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
                  {...params} />
              )}
              groupBy={(option) => {
                return option.geoType || '';
              }}
              value={cell.getValue<File[]>().length ? cell.getValue<File[]>()[0] : null}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                const newAvailableShapefiles = { ...availableShapefiles };
                const availablePoints = availableShapefiles["Points"];

                const oldPointsFile = newJob.points.length ? newJob.points[0] : null;
                if (oldPointsFile) {
                  availablePoints.push(oldPointsFile);
                }

                if (newValue) {
                  const index = availablePoints.indexOf(newValue);
                  if (index >= 0) {
                    availablePoints.splice(index, 1);
                  }
                }

                newJob.points = newValue ? [newValue] : [];
                row._valuesCache.points = newValue ? [newValue] : [];
                availablePoints.sort((file1, file2) => file1.name.localeCompare(file2.name));

                setAvailableShapefiles(newAvailableShapefiles);
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(event) => {
                return event?.name || ""; //event.id;
              }}
            />
          },
        },
        {
          id: "zones",
          header: "Zone Files",
          accessorKey: 'zones',
          enableEditing: false,
          minSize: 300,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              size="small"
              style={{
                width: '100%',
                padding: 0,
                // height: '50%',
              }}
              options={availableShapefiles["Zones"] || []}
              renderInput={(params) => (
                <TextField
                  style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
                  {...params} />
              )}
              groupBy={(option) => {
                return option.geoType || '';
              }}
              value={cell.getValue<File[]>().length ? cell.getValue<File[]>()[0] : null}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.temporaryId === row.original.temporaryId
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                const newAvailableShapefiles = { ...availableShapefiles };
                const availableZones = availableShapefiles["Zones"];

                const oldZonesFile = newJob.zones.length ? newJob.zones[0] : null;
                if (oldZonesFile) {
                  availableZones.push(oldZonesFile);
                }

                if (newValue) {
                  const index = availableZones.indexOf(newValue);
                  if (index >= 0) {
                    availableZones.splice(index, 1);
                  }
                }

                newJob.zones = newValue ? [newValue] : [];
                row._valuesCache.zones = newValue ? [newValue] : [];
                availableZones.sort((file1, file2) => file1.name.localeCompare(file2.name));

                setAvailableShapefiles(newAvailableShapefiles);
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(event) => {
                return event?.name || ""; //event.id;
              }}
            />
          },
        },
        // {
        //   id: "selectedEvent",
        //   header: "Target Layer",
        //   accessorKey: 'selectedEvent',
        //   enableEditing: false,
        //   minSize: 400,
        //   Cell: ({ cell, row }) => {
        //     return <Autocomplete
        //       style={{
        //         width: '100%',
        //         padding: 0,
        //         // height: '50%',
        //       }}

        //       // value={props.deals.find((deal) => {
        //       //   return deal.id === cell.getValue<string>();
        //       // }) || null}
        //       options={row.original.samplingEventOptions!}
        //       renderInput={(params) => (
        //         <TextField
        //           style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
        //           {...params} />
        //       )}
        //       groupBy={(option) => {
        //         return option.type || '';
        //       }}
        //       value={cell.getValue<SamplingEvent>()}
        //       onChange={(event, newValue) => {
        //         const newJobs = [...jobs];
        //         const newJob = newJobs.find((job) => {
        //           return job.temporaryId === row.original.temporaryId
        //         });
        //         if (!newJob) return;
        //         const index = newJobs.indexOf(newJob);
        //         newJob.syncCode = newValue?.id || '';
        //         newJob.selectedEvent = newValue;
        //         newJobs[index] = newJob;
        //         setJobs(newJobs);
        //       }}
        //       getOptionLabel={(event) => {
        //         return event.label; //event.id;
        //       }}
        //     />
        //   },
        // },
        // {
        //   accessorKey: "syncCode",
        //   id: "syncCode",
        //   header: "Event ID",
        //   enableEditing: true,
        //   muiEditTextFieldProps: ({ cell, row }) => ({
        //     type: 'text',
        //     required: !row.original.syncCodeReadonly,
        //     disabled: row.original.syncCodeReadonly,
        //     onChange: (event) => {
        //       const newJobs = [...jobs];
        //       const newJob = newJobs.find((job) => {
        //         return job.temporaryId === row.original.temporaryId
        //       });
        //       if (!newJob) return;
        //       const index = newJobs.indexOf(newJob);
        //       newJob.syncCode = event.target.value;
        //       newJobs[index] = newJob;
        //       setJobs(newJobs);
        //     }
        //   }),
        // },
        {
          id: 'labAccountNumber',
          header: 'Lab Account',
          accessorKey: 'labAccountNumber',
          enableEditing: true,
          size: 140,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            required: true,
            disabled: row.original.syncCodeReadonly,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            defaultValue: row.getValue('labAccountNumber'),
            onBlur: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.temporaryId === row.original.temporaryId
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.labAccountNumber = event.target.value;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
          // AggregatedCell: ({ row }) => {
          //   return <TextField
          //     type="text"
          //     onChange={(event) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           job.labAccountNumber = event.target.value;
          //         }
          //       }
          //       setJobs(newJobs);
          //     }}
          //   />
          // }
        },
        {
          id: 'recordBoundary',
          header: 'Record Boundary?',
          minSize: 120,
          size: 120,
          accessorKey: 'recordBoundary',
          enableEditing: false,
          Cell: ({ cell, row }) => {
            row.original.recordBoundary = jobs.find(j => j.temporaryId === row.original.temporaryId)?.recordBoundary || false;
            return (
              <Checkbox
                checked={row.original.recordBoundary}
                onChange={(event) => handleRecordBoundaryChange(event, row.original)}
              />
            );
          },
          AggregatedCell: ({ row }) => {
            const growerName = row.original.growerName;
            return (
              <Checkbox
                checked={recordBoundaryState[growerName] || false}
                indeterminate={!recordBoundaryState[growerName] && jobs.some(job => job.growerName === growerName && job.recordBoundary)}
                onChange={(event) => handleRecordBoundaryAggregatedChange(event, growerName)}
              />
            );
          }
        },
        {
          id: 'labPresubmissionCode',
          header: 'Lab Submission Code',
          accessorKey: 'labPresubmissionCode',
          enableEditing: true,
        },
        {
          id: 'growerCell',
          header: 'Grower Cell',
          accessorKey: 'growerCell',
          enableEditing: true,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            onBlur: (event) => {
                const value = event.target.value;
              if (value != "" && !value.match(/^\+?(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)(?=.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?).*$/)) {
                  setValidationErrors((prev) => ({ ...prev, [cell.id]: "Invalid phone number" }));
                  return;
                } else 
                  setValidationErrors((prev) => {
                    const newErrors = { ...prev };
                    delete newErrors[cell.id];
                    return newErrors;
                  });
            }
          }),
        }
      ],
    [availableShapefiles, props.jobs, validationErrors, readySelectionState, recordBoundaryState],
  );


  function allJobsHaveNoFiles(jobs: JobCreationData[]) {
    for (let job of jobs) {
      if (job.boundary && job.boundary.length > 0) return false;
      if (job.zones && job.zones.length > 0) return false;
      if (job.points && job.points.length > 0) return false;
    }
    return true;
  }

  const table = useMaterialReactTable({
    columns,
    data: props.jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    // getRowId: (row) => row.fieldName,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableColumnDragging: false,
    enableCellActions: true,
    enableEditing: true,
    // enableFacetedValues: true,
    editDisplayMode: 'table', // ('modal', 'row', 'cell', 'table', and 'custom' are also
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    groupedColumnMode: 'remove',
    muiTableProps: {
      sx: { maxHeight: '90vh' }
    },
    onExpandedChange: (isExpanded) => {
      setExpanded(isExpanded);
    },
    enableStickyFooter: true,
    // enableRowSelection: true,
    // enableRowSelection: (row) => {
    //   console.log(row.getValue('labPackage'), row.getValue('labAccountNumber'), row.getValue('gridSize'), row.getValue('syncCode'));
    //   return !!row.getValue('labPackage') && !!row.getValue('labAccountNumber') && !!row.getValue('gridSize') && !!row.getValue('syncCode');
    // },
    state: {
      rowSelection,
      columnVisibility,
      grouping,
      expanded: expanded,
      density: 'compact',
      pagination,
      //isFullScreen
    },
    onPaginationChange: (pagination) => {
      setPagination(pagination);
    },
    /*onIsFullScreenChange: (isFullScreen) => {
      setIsFullScreen(isFullScreen);
    },*/
    onRowSelectionChange: (rows) => {
      const newRowSelection = typeof rows === 'function' ? rows(rowSelection) : rows;
      // open the drawer if rows are selected
      props.setDrawerState(Object.keys(newRowSelection).length > 0);

      console.log(newRowSelection);

      // newRowSelection is an object with numeric indeces as keys and boolean values
      // we can map these onto the `data` object to know which rows are selected
      // const selectedRowIds = Object.keys(newRowSelection).map((key) => {
      //   const index = parseInt(key);
      //   if (!isNaN(index) && newRowSelection[key]) {
      //     return data[index].id;
      //   }
      // });
      // const displayedBoundaries: Record<string, object> = {};
      // Object.entries(jobBoundaries).forEach(([key, value]) => {
      //   if (selectedRowIds.includes(key)) {
      //     displayedBoundaries[key] = value;
      //   }
      // })
      // props.setDisplayBoundaries(displayedBoundaries);
      setRowSelection(newRowSelection);
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center" color={"secondary"}>
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        size: 200,
        
        
        // muiTableBodyCellProps: ({ row }) => ({
        //   sx: (theme) => ({
        //     color:
        //       row.depth === 0
        //         ? theme.palette.primary.main
        //         : row.depth === 1
        //           ? theme.palette.secondary.main
        //           : undefined,
        //   }),
        // }),
        // size: 200,
      },
    },
    onColumnVisibilityChange: (column) => {
      setColumnVisibility(column);
    },
    onColumnOrderChange: (columns) => {
      setColumnOrder(columns);
    },
    onGroupingChange: (grouping) => {
      setGrouping(grouping);
    },
    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        backgroundColor: row.depth === 0
          ? '#d4d5d6' 
            : 'inherit', 
          
      },
    }),
    layoutMode: "grid",
    initialState: {
      showGlobalFilter: true,
      grouping,
      pagination: {
        pageIndex: 0,
        pageSize: 20
      },
      columnPinning: {
        left: ['grower', 'field']
      },
      columnVisibility: { 'farm': false },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            // p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {Object.values(validationErrors).some((error) => !!error) && (
                <Typography color="error">Fix errors before submitting</Typography>
              )}
              <Button color="info" variant="contained" onClick={() => {
                const cleanString = (str: string) => {
                  return str.toLowerCase().replace(/ /g, "_").replace(/,/g, "");
                }
                let boundaries_identified = 0;
                let points_identified = 0;
                let zones_identified = 0;
                // are all field names unique?
                const fieldNames = jobs.map((job) => job.fieldName);
                const uniqueFieldNames = new Set(fieldNames);
                const allFieldNamesUnique = fieldNames.length === uniqueFieldNames.size;
                let allBoundariesMatchUniquely = false;
                if (allFieldNamesUnique) {
                    allBoundariesMatchUniquely = allStringsMatchUniqely(allShapefiles["Boundary"].map(file => file.name), fieldNames);
                }
                console.log(`Searching for matching files for ${jobs.length} jobs...`);
                for (const job of jobs) {
                  job.boundary = [];
                  job.points = [];
                  job.zones = [];
                  const grower = cleanString(job.growerName);
                  const farm = cleanString(job.farmName || "");
                  const field = cleanString(job.fieldName);
                  console.log(grower, farm, field);
                  for (const file of allShapefiles["Boundary"]) {
                    const filename = cleanString(file.name);
                    let fileMatches = filename.includes(field);
                    if (!allFieldNamesUnique) {
                      fileMatches = fileMatches && filename.includes(grower) && (!farm || filename.includes(farm));
                    }
                    if (fileMatches) {
                      job.boundary = [file];
                      console.log(`matched ${filename}`);
                      boundaries_identified++;
                      break;
                    }
                  }

                  for (const file of allShapefiles["Points"]) {
                    const filename = cleanString(file.name);
                    let fileMatches = filename.includes(field);
                    if (!allFieldNamesUnique) {
                      fileMatches = fileMatches && filename.includes(grower) && (!farm || filename.includes(farm));
                    }
                    if (fileMatches) {
                      job.points = [file];
                      points_identified++;
                      break;
                    }
                  }

                  for (const file of allShapefiles["Zones"]) {
                    const filename = cleanString(file.name.toLowerCase());
                    let fileMatches = filename.includes(field);
                    if (!allFieldNamesUnique) {
                      fileMatches = fileMatches && filename.includes(grower) && (!farm || filename.includes(farm));
                    }
                    if (fileMatches) {
                      job.zones = [file];
                      zones_identified++;
                      break;
                    }
                  }
                }

                for (const job of jobs) {
                  for (const boundary of job.boundary) {
                    const index = availableShapefiles["Boundary"].findIndex((file) => file.name === boundary.name);
                    if (index >= 0) {
                      availableShapefiles["Boundary"].splice(index, 1);
                    }
                  }

                  for (const points of job.points) {
                    const index = availableShapefiles["Points"].findIndex((file) => file.name === points.name);
                    if (index >= 0) {
                      availableShapefiles["Points"].splice(index, 1);
                    }
                  }

                  for (const zones of job.zones) {
                    const index = availableShapefiles["Zones"].findIndex((file) => file.name === zones.name);
                    if (index >= 0) {
                      availableShapefiles["Zones"].splice(index, 1);
                    }
                  }
                }

                toast.info(`Identified ${boundaries_identified} boundary files, ${points_identified} points files, and ${zones_identified} zones files for ${jobs.length} jobs.`);

                setAvailableShapefiles({...availableShapefiles});
                props.updateJobs([...jobs]);
              }}>
                Auto Match Files
              </Button>
              <Button
                color="info"
                //disabled={!jobs.every(job => job.boundaryGeoJSON || job.boundary.length > 0)}
                onClick={async () => {
                  props.setLoading('submit');
                  if (validationErrors && Object.keys(validationErrors).length > 0) {
                    toast.error("Please correct all validation errors before submitting");
                    props.setLoading(false);
                    return;
                  }              
                  
                  if(allJobsHaveNoFiles(jobs)) {
                    if (!window.confirm('No shapefiles attached. Proceed?')) {
                      return;
                    }
                  }

                  const creating = toast.loading(`Creating ${jobs.length} Jobs...`)

                  jobs.forEach((job) => {
                    job.deal = (job.deal === 'custom') ? undefined : job.deal;
                  });


                  try {
                    await submitJobs(jobs);
                    toast.success(`Created ${jobs.length} Jobs!`);
                    props.resetForm();
                  } catch (e) {
                    console.error(e);
                    toast.error("Failed to import jobs");
                  } finally {
                    toast.dismiss(creating);
                    props.setLoading(false);
                  }
                }}
                variant="contained"
              >
                Create Jobs
              </Button>
                <MRT_ToggleFullScreenButton table={table}/>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};


