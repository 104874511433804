import { AccountCircle, Send } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  ListItemIcon,
  lighten,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  Stack
} from "@mui/material";
import { ATRecord, Deals, getField } from "@rogoag/airtable";
import {
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_GroupingState,
  MRT_ColumnOrderState,
  MRT_ColumnDef,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ExpandedState,
  MRT_ExpandAllButton,
  MRT_PaginationState,
  getMRT_Rows
} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import { GeoFileType, JobCreationData, JobsPriorityOvrd } from "../types";
import { jobsToBulkUpload, submitJobs } from "../api/rogo_ops";
import { toast } from "react-toastify";

interface JobsImportTableProps {
  jobs: JobCreationData[];
  updateJobs: (jobs: JobCreationData[]) => void;
  deals: ATRecord<Deals>[];
  setDrawerState: (open: boolean) => void;
  setLoading: (task: string | boolean) => void;
  resetForm: () => void;
  // boundaryOptions?: File[];
  // pointsOptions?: File[];
  // zonesOptions: File[];
  // allOptions: Record<GeoFileType, File[]>;
  // setPointsSelection?: (field: string, value: File | null, oldFile: File | null) => void;
  // showPointsFileSelection?: boolean;
}

const validateRequired = (value: string) => !!value.length;

export default function ProAgricaImportTable(props: JobsImportTableProps) {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(true);
  const [jobs, setJobs] = useState<JobCreationData[]>(props.jobs);
  // const [allShapefiles, setAllShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  // const [availableShapefiles, setAvailableShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  // console.log(allShapefiles, availableShapefiles);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({
    'farm': false,
  });
  const [grouping, setGrouping] = useState<MRT_GroupingState>([
    'grower'
  ]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
    'grower',
    'field',
  ]);

  // useEffect(() => {
  //   if (props.allOptions.Boundary) {
  //     props.allOptions.Boundary.sort((file1, file2) => file1.name.localeCompare(file2.name));
  //   }
  //   if (props.allOptions.Points) {
  //     props.allOptions.Points.sort((file1, file2) => file1.name.localeCompare(file2.name));
  //   }
  //   if (props.allOptions.Zones) {
  //     props.allOptions.Zones.sort((file1, file2) => file1.name.localeCompare(file2.name));
  //   }

  //   setAllShapefiles({ ...props.allOptions });
  //   setAvailableShapefiles({ ...props.allOptions });
  // }, [props.allOptions]);


  useEffect(() => {
    setJobs(props.jobs);
  }, [props.jobs]);

  // useEffect(() => {
  //   console.log(`ProAgricaImportTable JOBS CHANGED TO: ${jobs.length}`);
  //   const allShapefiles = {...props.allOptions};
  //   const newAvailableShapefiles = { ...availableShapefiles };
  //   for (const job of jobs) {
  //     if (job.boundary.length) {
  //       // find potentially matching boundary file
  //       const matchingBoundary = allShapefiles["Boundary"].find(file => file.name === job.boundary[0].name);
  //       if (matchingBoundary) {
  //         job.boundary = [matchingBoundary];
  //         availableShapefiles["Boundary"] = availableShapefiles["Boundary"].filter(file => file.name !== matchingBoundary.name);
  //       } else {
  //         console.log(`No matching boundary file found for ${job.boundary[0].name}`);
  //         job.boundary = [];
  //       }
  //     }

  //     if (job.points.length) {
  //       // find potentially matching points file
  //       const matchingPoints = allShapefiles["Points"].find(file => file.name === job.points[0].name);
  //       if (matchingPoints) {
  //         job.points = [matchingPoints];
  //         availableShapefiles["Points"] = availableShapefiles["Points"].filter(file => file.name !== matchingPoints.name);
  //       } else {
  //         console.log(`No matching points file found for ${job.points[0].name}`);
  //         job.points = [];
  //       }
  //     }

  //     if (job.zones.length) {
  //       // find potentially matching zones file
  //       const matchingZones = allShapefiles["Zones"].find(file => file.name === job.zones[0].name);
  //       if (matchingZones) {
  //         job.zones = [matchingZones];
  //         availableShapefiles["Zones"] = availableShapefiles["Zones"].filter(file => file.name !== matchingZones.name);
  //       } else {
  //         console.log(`No matching zones file found for ${job.zones[0].name}`);
  //         job.zones = [];
  //       }
  //     }
  //   }

  //   setAllShapefiles(allShapefiles);
  //   setAvailableShapefiles(newAvailableShapefiles);
  //   setJobs(jobs);

  // }, [props.allOptions]);

  const uniqueGridSizes = new Set<number>();
  for (const deal of props.deals) {
    const gridSize = getField(deal, "Density/Grid #serviceinfo");
    if (gridSize) {
      uniqueGridSizes.add(gridSize);
    }
  }

  const [gridSizes] = useState<number[]>(Array.from(uniqueGridSizes));

  // console.log(gridSizes);

  const columns = useMemo<MRT_ColumnDef<JobCreationData>[]>(
    () =>
      [
        {
          accessorKey: 'growerName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'grower',
          filterVariant: 'autocomplete',
          header: 'Grower',
          // Cell: ({ cell, row }) => <TextField value={""} />,
        },
        {
          accessorKey: 'farmName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'farm',
          filterVariant: 'autocomplete',
          header: 'Farm',
        },
        {
          accessorKey: 'fieldName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
          enableEditing: false,
          id: 'field',
          // size: 0,
          filterVariant: 'autocomplete',
          header: 'Field',
          aggregationFn: 'count',
          AggregatedCell: ({ cell }) => {
            return `Fields: ${cell.getValue()}`;
          }
        },
        {
          id: 'readyToSampleNow',
          header: 'Ready?',
          minSize: 100,
          size: 100,
          accessorKey: 'readyToSampleNow',
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Checkbox
              style={{ padding: 0 }}
              checked={cell.getValue<boolean>()}
              onChange={(event) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.fieldGuid === row.original.fieldGuid;
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.readyToSampleNow = event.target.checked;
                row._valuesCache.readyToSampleNow = newJob.readyToSampleNow;
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
            />
          },
          AggregatedCell: ({ row }) => {
            return <Checkbox
              style={{ padding: 0 }}
              checked={row.getValue('readyToSampleNow')}
              onChange={(event) => {
                const newJobs = [...jobs];
                for (const job of newJobs) {
                  if (job.growerExternalId === row.original.growerExternalId) {
                    job.readyToSampleNow = event.target.checked;
                  }
                }
                const rows = getMRT_Rows(table);
                rows.forEach(row => {
                  row._valuesCache.readyToSampleNow = event.target.checked;
                })
                setJobs(newJobs);
              }}
            />
          }
        },
        {
          id: 'fieldPriority',
          header: 'Priority',
          accessorKey: 'fieldPriority',
          minSize: 250,
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Autocomplete
              style={{
                width: '100%',
                padding: 0,
                // height: '50%',
              }}
              value={row.getValue('fieldPriority')}
              options={JobsPriorityOvrd}
              renderInput={(params) => (
                <TextField
                  style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
                  {...params} />
              )}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.fieldGuid === row.original.fieldGuid;
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.fieldPriority = newValue || "Regular Turn (default)";
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(event) => {
                return event; //event.id;
              }}
            />
          },
          // AggregatedCell: ({ row }) => {
          //   return <Autocomplete
          //     style={{
          //       width: '100%',
          //       padding: 0,
          //       // height: '50%',
          //     }}
          //     value={row.getValue('fieldPriority')}
          //     options={JobsPriorityOvrd}
          //     renderInput={(params) => (
          //       <TextField
          //         style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
          //         {...params} />
          //     )}
          //     onChange={(event, newValue) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           // @ts-ignore
          //           job.fieldPriority = newValue || "Regular Turn (default)";
          //         }
          //       }
          //       setJobs(newJobs);
          //     }}
          //     getOptionLabel={(event) => {
          //       return event; //event.id;
          //     }}
          //   />
          // }
        },
        {
          id: 'depth',
          header: 'Depth',
          accessorKey: 'depth',
          enableEditing: true,
          size: 100,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'number',
            required: true,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            value: row.getValue('depth'),
            onChange: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.fieldGuid === row.original.fieldGuid;
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.depth = parseFloat(event.target.value);
              row._valuesCache.depth = newJob.depth;
              newJob.deal = undefined;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
          // AggregatedCell: ({ row }) => {
          //   return <TextField 
          //     type="number" 
          //     onChange={(event) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           job.depth = parseFloat(event.target.value);
          //         }
          //       }
          //       console.log(newJobs);
          //       setJobs(newJobs);
          //     }}
          //   />
          // }
        },
        // {
        //   accessorFn: (row) => {
        //     return row.points.length > 0;
        //   },
        //   enableEditing: false,
        //   id: 'pointsAttached',
        //   header: 'Points Included',
        //   Cell: ({ cell }) => {
        //     return cell.getValue<boolean>() ? <CheckBox /> : null;
        //   }
        // // },
        // {
        //   accessorFn: (row) => {
        //     return "files"
        //   },
        //   enableEditing: false,
        //   id: 'points',
        //   header: 'Points',
        //   Cell: ({ row }) => (
        //     <Button
        //       disabled={row.original.pointsAttached}
        //       color="info"
        //       onClick={() => {
        //         console.log("Points");
        //       }}
        //       variant="contained"
        //     >
        //       Upload
        //     </Button>
        //   )
        // },
        {
          id: "gridSize",
          header: "Density",
          accessorKey: 'gridSize',
          enableEditing: false,
          size: 160,
          muiEditTextFieldProps: ({ cell, row }) => ({
            required: true
          }),
          Cell: ({ cell, row }) => {
            return <Autocomplete
              freeSolo
              style={{ width: '100%' }}
              id="grid-size-autocomplete"
              value={row.getValue('gridSize')}
              options={gridSizes}
              renderInput={(params) => (
                <TextField
                  label="Acre density" variant="outlined"
                  {...params} />
              )}
              onChange={(event, newValue) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.fieldGuid === row.original.fieldGuid;
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.gridSize = newValue ? parseFloat(newValue.toString()) : undefined;
                newJob.deal = undefined;
                row._valuesCache.gridSize = newValue ? parseFloat(newValue.toString()) : undefined;
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
              getOptionLabel={(gridSize) => {
                return gridSize.toString();
              }}
            />
          },
          // AggregatedCell: ({ row }) => {
          //   return <Autocomplete
          //     freeSolo
          //     style={{ width: '100%' }}
          //     id="grid-size-autocomplete"
          //     options={gridSizes}
          //     renderInput={(params) => (
          //       <TextField
          //         label="Acre density" variant="outlined"
          //         {...params} />
          //     )}
          //     onChange={(event, newValue) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           job.gridSize = newValue ? parseFloat(newValue.toString()) : undefined;
          //         }
          //       }
          //       setJobs(newJobs);
          //     }}
          //     getOptionLabel={(gridSize) => {
          //       return gridSize.toString();
          //     }}
          //   />
          // }
        },
        // {
        //   id: "deal",
        //   header: "Deal",
        //   accessorKey: 'deal',
        //   enableEditing: false,
        //   Cell: ({ cell, row }) => {
        //     return <Autocomplete
        //       value={props.deals.find((deal) => {
        //         return deal.id === cell.getValue<string>();
        //       }) || null}
        //       options={props.deals}
        //       renderInput={(params) => (
        //         <TextField
        //           {...params} />
        //       )}
        //       onChange={(event, newValue) => {
        //         const newJobs = [...jobs];
        //         const newJob = newJobs.find((job) => {
        //           return job.externalId === row.original.externalId;
        //         });
        //         if (!newJob) return;
        //         const index = newJobs.indexOf(newJob);
        //         newJob.deal = newValue?.id || '';
        //         newJobs[index] = newJob;
        //         setJobs(newJobs);
        //       }}
        //       getOptionLabel={(deal) => {
        //         const testPckg = getField(deal, "Test Pckg Display");
        //         const labShortname = getField(deal, "Lab Shortname");
        //         const sitesType = getField(deal, "Sites Type #serviceinfo");
        //         const density = getField(deal, "Density/Grid #serviceinfo");

        //         return `${density} Acre ${sitesType}${testPckg ? ' - ' : ''}${testPckg || ''}`;
        //       }}
        //     />
        //   }
        // },
        // {
        //   id: "points",
        //   header: "Points Files",
        //   accessorKey: 'points',
        //   enableEditing: false,
        //   minSize: 500,
        //   Cell: ({ cell, row }) => {
        //     return <Autocomplete
        //       style={{
        //         width: '100%',
        //         padding: 0,
        //         // height: '50%',
        //       }}
        //       options={availableShapefiles["Points"] || []}
        //       renderInput={(params) => (
        //         <TextField
        //           style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
        //           {...params} />
        //       )}
        //       groupBy={(option) => {
        //         return option.type || '';
        //       }}
        //       value={cell.getValue<File[]>().length ? cell.getValue<File[]>()[0] : null}
        //       onChange={(event, newValue) => {
        //         const newJobs = [...jobs];
        //         const newJob = newJobs.find((job) => {
        //           return job.fieldGuid === row.original.fieldGuid;
        //         });
        //         if (!newJob) return;
        //         const index = newJobs.indexOf(newJob);
        //         const newAvailableShapefiles = { ...availableShapefiles };
        //         const availablePoints = availableShapefiles["Points"];

        //         const oldPointsFile = newJob.points.length ? newJob.points[0] : null;
        //         if (oldPointsFile) {
        //           availablePoints.push(oldPointsFile);
        //         }

        //         if (newValue) {
        //           const index = availablePoints.indexOf(newValue);
        //           if (index >= 0) {
        //             availablePoints.splice(index, 1);
        //           }
        //         }

        //         newJob.points = newValue ? [newValue] : [];
        //         row._valuesCache.points = newValue ? [newValue] : [];
        //         availablePoints.sort((file1, file2) => file1.name.localeCompare(file2.name));

        //         setAvailableShapefiles(newAvailableShapefiles);
        //         newJobs[index] = newJob;
        //         setJobs(newJobs);
        //       }}
        //       getOptionLabel={(event) => {
        //         return event?.name || ""; //event.id;
        //       }}
        //     />
        //   },
        // },
        // {
        //   id: "zones",
        //   header: "Zone Files",
        //   accessorKey: 'zones',
        //   enableEditing: false,
        //   minSize: 500,
        //   Cell: ({ cell, row }) => {
        //     return <Autocomplete
        //       style={{
        //         width: '100%',
        //         padding: 0,
        //         // height: '50%',
        //       }}
        //       options={availableShapefiles["Zones"] || []}
        //       renderInput={(params) => (
        //         <TextField
        //           style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
        //           {...params} />
        //       )}
        //       groupBy={(option) => {
        //         return option.type || '';
        //       }}
        //       value={cell.getValue<File[]>().length ? cell.getValue<File[]>()[0] : null}
        //       onChange={(event, newValue) => {
        //         const newJobs = [...jobs];
        //         const newJob = newJobs.find((job) => {
        //           return job.fieldGuid === row.original.fieldGuid;
        //         });
        //         if (!newJob) return;
        //         const index = newJobs.indexOf(newJob);
        //         const newAvailableShapefiles = { ...availableShapefiles };
        //         const availableZones = availableShapefiles["Zones"];

        //         const oldZonesFile = newJob.zones.length ? newJob.zones[0] : null;
        //         if (oldZonesFile) {
        //           availableZones.push(oldZonesFile);
        //         }

        //         if (newValue) {
        //           const index = availableZones.indexOf(newValue);
        //           if (index >= 0) {
        //             availableZones.splice(index, 1);
        //           }
        //         }

        //         newJob.zones = newValue ? [newValue] : [];
        //         row._valuesCache.zones = newValue ? [newValue] : [];
        //         availableZones.sort((file1, file2) => file1.name.localeCompare(file2.name));

        //         setAvailableShapefiles(newAvailableShapefiles);
        //         newJobs[index] = newJob;
        //         setJobs(newJobs);
        //       }}
        //       getOptionLabel={(event) => {
        //         return event?.name || ""; //event.id;
        //       }}
        //     />
        //   },
        // },
        // {
        //   id: "selectedEvent",
        //   header: "Target Layer",
        //   accessorKey: 'selectedEvent',
        //   enableEditing: false,
        //   minSize: 400,
        //   Cell: ({ cell, row }) => {
        //     return <Autocomplete
        //       style={{
        //         width: '100%',
        //         padding: 0,
        //         // height: '50%',
        //       }}

        //       // value={props.deals.find((deal) => {
        //       //   return deal.id === cell.getValue<string>();
        //       // }) || null}
        //       options={row.original.samplingEventOptions!}
        //       renderInput={(params) => (
        //         <TextField
        //           style={{ padding: 0 }} //, height: 10, fontSize: 20 }}
        //           {...params} />
        //       )}
        //       groupBy={(option) => {
        //         return option.type || '';
        //       }}
        //       value={cell.getValue<SamplingEvent>()}
        //       onChange={(event, newValue) => {
        //         const newJobs = [...jobs];
        //         const newJob = newJobs.find((job) => {
        //           return job.fieldGuid === row.original.fieldGuid;
        //         });
        //         if (!newJob) return;
        //         const index = newJobs.indexOf(newJob);
        //         newJob.syncCode = newValue?.id || '';
        //         newJob.selectedEvent = newValue;
        //         newJobs[index] = newJob;
        //         setJobs(newJobs);
        //       }}
        //       getOptionLabel={(event) => {
        //         return event.label; //event.id;
        //       }}
        //     />
        //   },
        // },
        // {
        //   accessorKey: "syncCode",
        //   id: "syncCode",
        //   header: "Event ID",
        //   enableEditing: true,
        //   muiEditTextFieldProps: ({ cell, row }) => ({
        //     type: 'text',
        //     required: !row.original.syncCodeReadonly,
        //     disabled: row.original.syncCodeReadonly,
        //     onChange: (event) => {
        //       const newJobs = [...jobs];
        //       const newJob = newJobs.find((job) => {
        //         return job.fieldGuid === row.original.fieldGuid;
        //       });
        //       if (!newJob) return;
        //       const index = newJobs.indexOf(newJob);
        //       newJob.syncCode = event.target.value;
        //       newJobs[index] = newJob;
        //       setJobs(newJobs);
        //     }
        //   }),
        // },
        {
          id: 'labAccountNumber',
          header: 'Lab Account',
          accessorKey: 'labAccountNumber',
          enableEditing: true,
          size: 140,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            required: true,
            disabled: false,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            value: row.getValue('labAccountNumber'),
            onChange: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.fieldGuid === row.original.fieldGuid;
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.labAccountNumber = event.target.value;
              row._valuesCache.labAccountNumber = newJob.labAccountNumber;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
          // AggregatedCell: ({ row }) => {
          //   return <TextField
          //     type="text"
          //     onChange={(event) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           job.labAccountNumber = event.target.value;
          //         }
          //       }
          //       setJobs(newJobs);
          //     }}
          //   />
          // }
        },
        {
          id: 'frequencyOfMicros',
          header: 'Micros',
          accessorKey: 'frequencyOfMicros',
          enableEditing: true,
          size: 100,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'number',
            required: true,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            value: row.getValue('frequencyOfMicros'),
            onChange: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.fieldGuid === row.original.fieldGuid;
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.frequencyOfMicros = parseFloat(event.target.value);
              row._valuesCache.frequencyOfMicros = newJob.frequencyOfMicros;
              newJob.deal = undefined;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
        },
        {
          id: 'labPackage',
          header: 'Lab Package',
          accessorKey: 'labPackage',
          enableEditing: true,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            required: true,
            disabled: false,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            value: row.getValue('labPackage'),
            onChange: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.fieldGuid === row.original.fieldGuid;
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.labPackage = event.target.value;
              row._valuesCache.labPackage = newJob.labPackage;
              newJob.deal = undefined;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
          // AggregatedCell: ({ row }) => {
          //   return <TextField
          //     type="text"
          //     onChange={(event) => {
          //       const newJobs = [...jobs];
          //       for (const job of newJobs) {
          //         if (job.growerExternalId === row.original.growerExternalId) {
          //           job.labPackage = event.target.value;
          //         }
          //       }
          //       setJobs(newJobs);
          //     }}
          //   />
          // }
        },
        {
          id: 'recordBoundary',
          header: 'Record Boundary?',
          minSize: 100,
          size: 180,
          accessorKey: 'recordBoundary',
          enableEditing: false,
          Cell: ({ cell, row }) => {
            return <Checkbox
              style={{ padding: 0 }}
              checked={cell.getValue<boolean>()}
              onChange={(event) => {
                const newJobs = [...jobs];
                const newJob = newJobs.find((job) => {
                  return job.fieldGuid === row.original.fieldGuid;
                });
                if (!newJob) return;
                const index = newJobs.indexOf(newJob);
                newJob.recordBoundary = event.target.checked;
                newJob.deal = undefined;
                newJobs[index] = newJob;
                setJobs(newJobs);
              }}
            />
          },
          AggregatedCell: ({ row }) => {
            return <Checkbox
              style={{ padding: 0 }}
              checked={row.getValue('recordBoundary')}
              onChange={(event) => {
                const newJobs = [...jobs];
                for (const job of newJobs) {
                  if (job.growerExternalId === row.original.growerExternalId) {
                    job.recordBoundary = event.target.checked;
                  }
                }

                const rows = getMRT_Rows(table);
                rows.forEach(row => {
                  row._valuesCache.recordBoundary = event.target.checked;
                })

                setJobs(newJobs);
              }}
            />
          }
        },
        {
          id: 'submissionNotes',
          header: 'Submission Notes',
          accessorKey: 'submissionNotes',
          enableEditing: true,
          muiEditTextFieldProps: ({ cell, row }) => ({
            type: 'text',
            required: true,
            disabled: false,
            error: !!validationErrors?.[cell.id],
            helperText: validationErrors?.[cell.id],
            value: row.getValue('submissionNotes'),
            onChange: (event) => {
              const newJobs = [...jobs];
              const newJob = newJobs.find((job) => {
                return job.fieldGuid === row.original.fieldGuid;
              });
              if (!newJob) return;
              const index = newJobs.indexOf(newJob);
              newJob.submissionNotes = event.target.value;
              row._valuesCache.submissionNotes = newJob.submissionNotes;
              newJob.deal = undefined;
              newJobs[index] = newJob;
              setJobs(newJobs);
            }
          }),
        },
        {
          id: 'growerCell',
          header: 'Grower Cell',
          accessorKey: 'growerCell',
          enableEditing: true,
        }
      ],
    [props.jobs],
  );

  const table = useMaterialReactTable({
    columns,
    data: props.jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    // getRowId: (row) => row.fieldName,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableColumnDragging: false,
    enableFullScreenToggle: true,
    // enableFacetedValues: true,
    editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    enableEditing: true,
    groupedColumnMode: 'remove',
    muiTableProps: {
      sx: { maxHeight: '800px' }
    },
    onExpandedChange: (isExpanded) => {
      setExpanded(isExpanded);
    },
    enableStickyFooter: true,
    // enableRowSelection: true,
    // enableRowSelection: (row) => {
    //   console.log(row.getValue('labPackage'), row.getValue('labAccountNumber'), row.getValue('gridSize'), row.getValue('syncCode'));
    //   return !!row.getValue('labPackage') && !!row.getValue('labAccountNumber') && !!row.getValue('gridSize') && !!row.getValue('syncCode');
    // },
    state: {
      rowSelection,
      columnVisibility,
      grouping,
      expanded: expanded,
      density: 'compact',
      pagination,
    },
    onPaginationChange: (pagination) => {
      setPagination(pagination);
    },
    onRowSelectionChange: (rows) => {
      const newRowSelection = typeof rows === 'function' ? rows(rowSelection) : rows;
      // open the drawer if rows are selected
      props.setDrawerState(Object.keys(newRowSelection).length > 0);

      console.log(newRowSelection);

      // newRowSelection is an object with numeric indeces as keys and boolean values
      // we can map these onto the `data` object to know which rows are selected
      // const selectedRowIds = Object.keys(newRowSelection).map((key) => {
      //   const index = parseInt(key);
      //   if (!isNaN(index) && newRowSelection[key]) {
      //     return data[index].id;
      //   }
      // });
      // const displayedBoundaries: Record<string, object> = {};
      // Object.entries(jobBoundaries).forEach(([key, value]) => {
      //   if (selectedRowIds.includes(key)) {
      //     displayedBoundaries[key] = value;
      //   }
      // })
      // props.setDisplayBoundaries(displayedBoundaries);
      setRowSelection(newRowSelection);
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        // muiTableBodyCellProps: ({ row }) => ({
        //   sx: (theme) => ({
        //     color:
        //       row.depth === 0
        //         ? theme.palette.primary.main
        //         : row.depth === 1
        //           ? theme.palette.secondary.main
        //           : undefined,
        //   }),
        // }),
        // size: 200,
      },
    },
    onColumnVisibilityChange: (column) => {
      setColumnVisibility(column);
    },
    onColumnOrderChange: (columns) => {
      setColumnOrder(columns);
    },
    onGroupingChange: (grouping) => {
      setGrouping(grouping);
    },
    layoutMode: "grid",
    initialState: {
      showGlobalFilter: true,
      grouping,
      pagination: {
        pageIndex: 0,
        pageSize: 20
      },
      columnPinning: {
        left: ['grower', 'field']
      },
      columnVisibility: { 'farm': false },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30, 50],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color="info"
                onClick={async () => {
                  const blobData = await jobsToBulkUpload(jobs);
                  const url = window.URL.createObjectURL(blobData);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = `${(new Date()).toISOString()}.zip`;
                  a.click();
                }}>
                Download Bulk Import
              </Button>
              <Button
                color="info"
                //disabled={!jobs.every(job => job.boundaryGeoJSON || job.boundary.length > 0)}
                onClick={async () => {
                  props.setLoading('submit');
                  const creating = toast.loading(`Creating ${jobs.length} Jobs...`);
                  try {
                    await submitJobs(jobs);


                    // const jobsToSubmit: JobCreationData[] = [];
                    // for (const [key, jobSelected] of Object.entries(rowSelection)) {
                    //   if (jobSelected) {
                    //     const job = jobs[parseInt(key)];
                    //     jobsToSubmit.push(job);
                    //     // console.log(job.labAccountNumber);
                    //   }
                    // }
                    // const createdJobs: string[] = []
                    // await submitJobs(jobs);
                    // await Promise.all(jobs.map((job) => {
                    //   return submitJob(job).then((id) => {
                    //     createdJobs.push(id);
                    //     // toast.success(`ROGO Job ${id} created for ${job.fieldName}!`);
                    //   });
                    // }));

                    toast.success(`Created ${jobs.length} Jobs!`);
                    props.resetForm();
                    // rowSelection
                    // await submitJob(data);
                  } catch (e) {
                    console.error(e);
                    toast.error("Failed to import jobs");
                  } finally {
                    toast.dismiss(creating);
                    props.setLoading(false);
                  }
                }}
                variant="contained"
              >
                Create Jobs
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};
