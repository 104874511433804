import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { getUser, login } from '../api/airtable_ops';
import { RogoPortalUser } from '../hooks/UserContext';
import { Typography } from '@mui/material';
import { LoadingComponent } from './LoadingComponent';
import * as Sentry from "@sentry/react";

interface LoginProps {
  setLoggedIn: (user: RogoPortalUser) => void;
}

export default function LoginForm(props: LoginProps) {
  const [username, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmailError('');
    // validate that username is an email with regex
    if (!emailPattern.test(event.target.value.toString())) {
      setEmailError("Invalid email address");
    }

    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    try {
      setError('');
      if (!username) {
        setEmailError('');
        return;
      }
      if (!password) {
        setPasswordError('');
        return;
      }
      setLoading(true);
      event.preventDefault();
      const user = await login({ username, password });
      if (user) {
        const rogoPortalUser = await getUser();
        props.setLoggedIn(rogoPortalUser);
        navigate('/jobs/dashboard');
      }
    } catch (error: Error | any) {
      Sentry.withScope(function (scope) {
        if (error.message != "Network Error" || error.message != "Request failed with status code 401") {
          scope.setFingerprint(["custom-" + Date.now().toString()]);// forces Sentry to log each event as a separate issue, rather then grouping them together
        }
        scope.setUser({ email: username });
        var e = new LoginError(error.message, error.stack);
        scope.captureException(e, { mechanism: { handled: true } });
      });
      setError('Error logging in. Please try again or contact software@rogoag.com.');
    } finally {
      setLoading(false);
    }

    // event.preventDefault();

    // // Replace with your remote service URL
    // const url = 'https://your-remote-service.com/authenticate';

    // axios.post(url, {
    //   username: username,
    //   password: password
    // })
    // .then((response: any) => {
    //   // Handle successful response
    //   console.log(response);
    //   props.setLoggedIn(true);
    // })
    // .catch((error: any) => {
    //   // Handle error
    //   console.log(error);
    // });
  };

  return (
    <Box
      maxWidth="md" margin="auto"
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        mt: 1,
      }}
    >
      <TextField
        variant="outlined"
        margin="normal"
        required
        error={!!emailError}
        disabled={loading}
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        value={username}
        onChange={handleEmailChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        error={!!passwordError}
        required
        disabled={loading}
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={handlePasswordChange}
      />
      <Button
        type="submit"
        disabled={loading || !username || !password || !!emailError || !!passwordError}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ color: 'red' }}
      >
        {error}
      </Typography>
      {(loading) && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <LoadingComponent />
        </Box>
      )}
      <Box sx={{ alignContent: 'center', justifyContent: 'center' }} display="flex" >
        <Button
          style={{ margin: 2 }}
          disabled={loading}
          onClick={() => navigate('/register')}
          color="primary"
          variant='contained'
        >
          Register
        </Button>
        <Button
          style={{ margin: 2 }}
          disabled={loading}
          onClick={() => navigate('/forgot-password')}
          color="primary"
          variant='outlined'
        >
          Forgot Password
        </Button>
      </Box>
    </Box>
  );
}

class LoginError extends Error {
  constructor(message: string, stack?: string) {
    super(message);
    this.name = 'LoginError';
    if (stack){
        this.stack = stack;
    }
    
    Object.setPrototypeOf(this, new.target.prototype);
  }
}