import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      localStorage.clear();
      // This seems to logout the user but I'm not sure if it's the best way to do it
      sessionStorage.clear();
      navigate("/login", { replace: true });
    }, []);
  
    return <Typography> Please wait... </Typography>;
  };