import { useEffect, useState } from "react";
import { stringToColor } from "../utils";
import { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from "geojson";
import { Map, MapCameraChangedEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Polygon as MapPolygon } from './map';
import { MarkerWithInfoWindow } from "./map/MarkerWithInfoWindow";
import { Typography } from "@mui/material";

interface SimpleMapProps {
    zoom?: number,
    boundaries?: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>,
    points?: Record<string, FeatureCollection<Point>>
    height?: string,
    showPointsAtZoom?: number,
}

export default function GoogleMapComponent(props: SimpleMapProps) {
    const [mapPoints, setMapPoints] = useState<JSX.Element[]>([]);
    const [mapPolygons, setMapPolygons] = useState<JSX.Element[]>([]);
    const [visibleMapMarkers, setVisibleMapMarkers] = useState<JSX.Element[]>([]);
    const [visibleMapPolygons, setVisibleMapPolygons] = useState<JSX.Element[]>([]);
    const [center, setCenter] = useState<google.maps.LatLngLiteral>({ lat: 40.7671815905857, lng: -87.16533774444255 });
    const [zoom, setZoom] = useState<number>(12);
    const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral | undefined>(undefined);
    const [kmlLayer, setKmlLayer] = useState<google.maps.KmlLayer | null>(null);

    const mapsLibrary = useMapsLibrary('maps');
    if (!mapsLibrary) {
        return <div>Loading...</div>;
    }

    const map = useMap();

    useEffect(() => {
        // const rogoTestMission = new mapsLibrary.KmlLayer({
        //     url: 'https://app-rogoag-com.s3.us-east-2.amazonaws.com/static/standard_mission.kmz',
        //     map: map,
        // });
        // setKmlLayer(rogoTestMission);

        const getShapeFeatureProperty = (feature: Feature<Polygon | Point>, key: string) => {
            return feature.properties && key in feature.properties ? feature.properties[key] : "";
        }
        const boundaries = Object.values(props.boundaries || {});
        const bounds = new google.maps.LatLngBounds();
        const mapPolygons = Object.entries(props.boundaries ?? {}).map(([key, value]) => {
            return value.features?.map((feature: Feature<Polygon | MultiPolygon | Point>, index) => {
                if (feature.geometry.type !== 'Polygon' && feature.geometry.type !== 'MultiPolygon') {
                    return null;
                }
                if (feature.geometry.bbox) {
                    bounds.extend(new google.maps.LatLng(feature.geometry.bbox[1], feature.geometry.bbox[0]));
                    bounds.extend(new google.maps.LatLng(feature.geometry.bbox[3], feature.geometry.bbox[2]));
                }

                const coordinates = feature.geometry.type === 'Polygon' ? [feature.geometry.coordinates] : feature.geometry.coordinates;
                const paths = coordinates.map(coordinate => {
                    return coordinate.map(c => {
                        return c.map(cc => {
                            return { lat: cc[1], lng: cc[0] };
                        }).flat();
                    }).flat();
                });
                
                return <MapPolygon
                    fillOpacity={0.4}
                    fillColor={stringToColor(key)}
                    strokeWeight={2}
                    key={`${key}-${index}`}
                    paths={paths}
                    onClick={() => {
                        // console.log('clicked', key);
                    }}
                />
            });
        }).flat();

        const mapPoints = Object.entries(props.points ?? {}).map(([key, value]) => {
            return value.features?.map((feature: Feature<Polygon | Point>) => {
                if (feature.geometry.type !== 'Point') {
                    return null;
                }
                const sampleId = getShapeFeatureProperty(feature, 'spl id');
                const barcode = getShapeFeatureProperty(feature, 'spl brcd');
                const change = getShapeFeatureProperty(feature, 'change');
                const [changeType, changeReason] = change.includes(':') ? change.split(':') : ['', ''];
                const hasChange = changeType && changeType !== 'None';
                bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
                return <MarkerWithInfoWindow
                    key={`${key}-${feature.geometry.coordinates[1].toString().replace(".", "")}-${feature.geometry.coordinates[0].toString().replace(".", "")}`}
                    iconColor={hasChange ? 'warning' : undefined}
                    position={{ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }}
                    infoWindowText={
                        <Typography variant="body2">
                            Sample ID: {sampleId}<br />
                            Barcode: <b>{barcode}</b><br />
                            Moved? {hasChange ? `Yes (${changeType})` : 'No'}{changeReason ? `: ${changeReason}` : ''}
                        </Typography>
                    }
                    label={sampleId || ""}>
                </MarkerWithInfoWindow>;
            });
        }).flat();

        const validPoints = mapPoints.filter(Boolean) as JSX.Element[];
        const validPolygons = mapPolygons.filter(Boolean) as JSX.Element[];

        setMapPoints(validPoints);
        setMapPolygons(validPolygons);

        if (map && boundaries.length) {
            map.fitBounds(bounds);
        }

        // setVisibleMapMarkers(validPoints);
        // setVisibleMapPolygons(validPolygons);
    }, [props.boundaries, props.points]);

    // update visible based on bounds
    useEffect(() => {
        if (!map) return;

        // console.log('bounds changed');
        const bounds = map.getBounds();
        if (!bounds) return;

        const zoom = map.getZoom();

        // // check all mapPoints to see if they are in bounds
        const visibleMapMarkers = mapPoints.filter((marker) => {
            const position = marker.props.position;
            if (!position) {
                return false;
            }

            if (!zoom || !props.showPointsAtZoom || zoom < props.showPointsAtZoom) {
                return false;
            }
            
            return bounds.contains(new google.maps.LatLng(position.lat, position.lng));
        });

        // console.log('visible markers', visibleMapMarkers.length);

        setVisibleMapMarkers(visibleMapMarkers);

        // check all mapPolygons to see if they are in bounds
        // const visibleMapPolygons = mapPolygons.filter((polygon) => {
        //     return bounds.contains(new google.maps.LatLng(polygon.props.paths[0][0].lat, polygon.props.paths[0][0].lng));
        // });

        setVisibleMapPolygons(mapPolygons);

        // map.addListener('bounds_changed', updateVisibleMarkers);
    }, [zoom, bounds]);

    return (
        <Map
            style={{ width: '100%', height: props.height ? props.height : '97vh' }}
            mapId={"customerMaps"}
            // onMapCapabilitiesChanged={(e) => {
            //     console.log('onMapCapabilitiesChanged', e);
            // }}
            // onRenderingTypeChanged={(e) => {
            //     console.log('onRenderingTypeChanged', e);
            // }}
            // onTilesLoaded={(e) => {
            //     console.log('onTilesLoaded', e);
            // }}
            zoom={zoom}
            // center={center}
            // onCenterChanged={(e: MapCameraChangedEvent) => {
            //     setCenter(e.detail.center);
            // }}
            onBoundsChanged={(e: MapCameraChangedEvent) => {
                // console.log('onBoundsChanged', e);
                setBounds(e.detail.bounds)
            }}
            onZoomChanged={(e: MapCameraChangedEvent) => {
                setZoom(e.detail.zoom);
            }}
            // zoom={zoom}
            defaultCenter={center}
            defaultZoom={10}
        // disableDefaultUI
        >
            {visibleMapMarkers}
            {visibleMapPolygons}
        </Map>
    );
}
