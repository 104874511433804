import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { RogoPortalUser, UserContext } from "../hooks/UserContext";
import {
    boundaryRecordsToGeoJson,
    getAuthUrl,
    getFarm,
    getFarmsForGrower,
    getField,
    getFieldsForGrowerAndFarm,
    getGrower,
    getGrowers,
    getJwtSyncIds,
    getManagementZone,
    getManagementZones,
    getSamplingItems,
    authenticate as proagrica_authenticate,
    refreshProagricaToken
} from '../api/proagrica_ops';
import { Field, Grower, GrowerFarm, GrowerFarmField } from "../api/proagrica_types";
import { ATRecord, Branches, Companies, Contacts, Deals, getField as getRecordField } from "@rogoag/airtable";
import { DownloadOptions, ZipOptions, zip } from "@mapbox/shp-write";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { setProagricaState, getUsersData } from "../api/airtable_ops";
import { WKT } from "../geojson_converters/wkt";
import { JobCreationData, SamplingEvent } from "../types";
import { toSamplingEventDateString } from "../utils";
import { v4 as uuidv4 } from 'uuid';
import JobImportTable from "./JobImportTable";
import ProAgricaImportTable from "./ProAgricaImportTable";
import { FeatureCollection, Geometry } from "geojson";
import { LoadingComponent } from "./LoadingComponent";
import * as Sentry from "@sentry/react";


interface ProagricaImportFormProps {
    currentUser?: RogoPortalUser;
    selectedDeals: ATRecord<Deals>[];
    selectedBranches: ATRecord<Branches>[];
}

const EventImportOptions = [
    "Use Latest Historical Points",
    "Use Latest Open Event",
    "ROGO Creates All Points"
] as const;
type EventImportOption = typeof EventImportOptions[number];


export function ProagricaImportForm(props: ProagricaImportFormProps) {
    const [jobData, setJobData] = useState<JobCreationData[]>([]);
    const [growers, setGrowers] = useState<Grower[]>([]);
    const [selectedGrowers, setSelectedGrowers] = useState<Grower[]>([]);
    const [farms, setFarms] = useState<GrowerFarm[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    const [selectedFields, setSelectedFields] = useState<Field[]>([]);
    const [loading, setLoading] = useState<string | boolean>(false);
    const userContext = React.useContext(UserContext);
    const proagricaToken = userContext?.proagrica_auth?.access_token ?? "";
    const [rogoUserData, setRogoUserData] = useState<ATRecord<Contacts> | undefined>(undefined);
    const [eventImportStategy, setEventImportStrategy] = useState<EventImportOption | null>(null);

    // TODO use proagrica sampling types
    const [samplingItems, setSamplingItems] = useState<any[]>([]);

    return <>
        <Grid
            alignItems="stretch"
            alignContent="stretch"
            container
            maxWidth="md"
            margin="auto"
            spacing={1}
        >
            {/* Step 1: Log in if auth isn't available */}
            {!userContext?.proagrica_auth && <Grid item xs={12}>
                <Button
                    fullWidth
                    disabled={!!loading}
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        const [authUrl, authState, authNonce] = getAuthUrl(window.location.href);
                        console.log(authUrl, authState, authNonce);
                        setLoading(true);
                        console.log(window.location.href);
                        await setProagricaState(authState);
                        // redirect user to authUrl
                        window.location.href = authUrl;
                    }}>
                    ProAgrica Login
                </Button>
            </Grid>
            }

            {userContext?.proagrica_auth && !!props.selectedDeals.length &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        disabled={!!loading}
                        style={{ marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await refreshProagricaToken()
                            setLoading(true);
                            const loadingGrowersToast = toast.loading('Retrieving Growers...');
                            try {
                                setGrowers([]);
                                setSelectedGrowers([]);
                                setFields([]);
                                setSelectedFields([]);
                                const syncIds = await getJwtSyncIds();
                                // TODO sync_id
                                const sync_id = 2025;
                                //const [deals, fields] = await agvanceToJobData(result, userContext);
                                const growers = (await Promise.all(syncIds.map(syncId => getGrowers(syncId)))).flat(1);
                                setGrowers(growers);
                                console.log(growers);
                                toast.success(`Found ${growers.length} growers!`);
                            } catch (error) {
                                toast.error(`Error retrieving growers: ${error}`);
                            } finally {
                                setLoading(false);
                                toast.dismiss(loadingGrowersToast);
                            }

                        }}>
                        Retrieve Growers
                    </Button>
                </Grid>
            }

            {(growers.length > 0) && (
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Autocomplete
                            fullWidth
                            style={{ marginTop: 20, width: '100%' }}
                            multiple
                            disabled={!!loading}
                            options={growers}
                            value={selectedGrowers}
                            getOptionKey={(option) => option.ID}
                            getOptionLabel={(option) => option.Name}
                            onChange={(event, value) => {
                                setFields([]);
                                setSelectedFields([]);
                                setJobData([]);
                                setSamplingItems([]);
                                setSelectedGrowers(value);
                            }}
                            renderInput={(params) =>
                                <TextField {...params} label="Choose your grower(s)" variant="outlined" />}
                        />
                        {/* <Button
                            variant='contained'
                            disabled={!!loading}
                            style={{ marginTop: 20, marginLeft: 10, width: '20%', maxHeight: 60 }}
                            onClick={() => {
                                const demoGrowers = [
                                    'Sarah Krause',
                                    'Zachary Hernandez',
                                    'Shelby Byrd',
                                    'Alison Brown',
                                    'Tracy Lopez',
                                    'Lee Sutton',
                                    'Ricky Moore',
                                    'Kari Ramos',
                                    'Gerald Smith',
                                    'Lauren Reyes',
                                    'Jennifer Silva',
                                    'Victoria Bradford',
                                    'Angela Lopez',
                                    'Natasha Mcdonald',
                                    'Michael Hartman',
                                    'Susan Wise',
                                    'Adrian Reese',
                                    'Ian Gomez',
                                    'Austin King',
                                    'Christian Cox',
                                ];
                                const growersToSelect = demoGrowers.map(growerName => growers.find(grower => grower.Name === growerName));
                                // @ts-ignore
                                setSelectedGrowers(growersToSelect);
                            }}
                        >
                            Select Demo Growers
                        </Button> */}
                    </Box>
                </Grid>

            )}
            {(selectedGrowers.length > 0) &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        style={{ marginTop: 20 }}
                        disabled={!!loading}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const startTime = new Date();
                            let apiCalls = 0;
                            setLoading(true);
                            setFields([]);
                            setSelectedFields([]);
                            await refreshProagricaToken();
                            const loadingFarmsAndFields = toast.loading("Loading Farms and Fields...");
                            try {
                                let finishedGrowers = 0;
                                const retrievedFarms: GrowerFarm[] = []
                                const farmFieldPromises = selectedGrowers.map(async (grower) => {
                                    // First retrieve all farms for the grower
                                    const farms = await getFarmsForGrower(grower.ID, grower.SyncID);
                                    apiCalls++;
                                    retrievedFarms.push(...farms);

                                    // Then retrieve each field by farm
                                    const allGrowerFieldsByFarm = await Promise.all(farms.map(async (farm) => {
                                        if (farm.IsDeleted) {
                                            return [];
                                        }
                                        const farmData = await getFarm(farm.ID, grower.SyncID);
                                        apiCalls++;
                                        console.log(farmData);
                                        console.log('Farms', grower.Name, farms);
                                        const fields = await getFieldsForGrowerAndFarm(grower.ID, farm.ID, grower.SyncID);
                                        console.log('Fields', grower.Name, fields);
                                        apiCalls++;
                                        const fieldsData = await Promise.all(fields.map(async (field) => {
                                            if (field.IsDeleted) {
                                                return undefined;
                                            }
                                            apiCalls++;
                                            const fieldData = await getField(field.ID, grower.SyncID);
                                            // TODO an alternative option is to put the farm name into the field name for later reference
                                            // fieldData.Name = farmData.Name + ' - ' + fieldData.Name;
                                            return fieldData;
                                        }));
                                        return fieldsData;
                                    }));

                                    // Flatten the fields down to not be nested by farm
                                    const allGrowerFields = allGrowerFieldsByFarm.flat(1).filter((field) => field !== undefined) as Field[];
                                    // TODO too many notifications so disabling for now
                                    // if (farms.length === 0) {
                                    //     toast.error(`No farms found for ${grower.Name}`);
                                    // } else if (allGrowerFields.length === 0) {
                                    //     toast.error(`No fields found for ${grower.Name}`);
                                    // } else {
                                    //     toast.success(`Found ${allGrowerFields.length} fields for ${grower.Name}`);
                                    // }
                                    finishedGrowers++;
                                    return allGrowerFields;
                                });
                                setFarms(retrievedFarms);
                                // flatten all fields nested by grower to be a single array
                                const fields = (await Promise.all(farmFieldPromises)).flat(1);
                                console.log('Farms', farms);
                                console.log('Fields', fields);
                                setFields(fields.sort((a, b) => a.Name.localeCompare(b.Name)));
                                toast.success(`Found ${fields.length} total fields!`);
                            } catch (error) {
                                toast.error(`Error retrieving fields: ${error}`);
                            } finally {
                                setLoading(false);
                                toast.dismiss(loadingFarmsAndFields);
                                const endTime = new Date();
                                console.log(`${apiCalls} made in ${endTime.valueOf() - startTime.valueOf()}ms`);
                            }
                        }}>
                        Retrieve Fields
                    </Button>
                </Grid>
            }
            {(fields.length > 0) &&
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Autocomplete
                            style={{ marginTop: 20, width: '80%' }}
                            multiple
                            disabled={!!loading}
                            options={fields}
                            value={selectedFields}
                            getOptionKey={(option) => option.ID}
                            // @ts-ignore
                            getOptionLabel={(option) => `${option.Name}`}
                            onChange={(event, value) => setSelectedFields(value)}
                            renderInput={(params) =>
                                <TextField {...params} label="Choose your field(s)" variant="outlined" />}
                        />
                        <Button
                            disabled={!!loading}
                            variant='contained'
                            style={{ marginTop: 20, marginLeft: 10, width: '20%', maxHeight: 60 }}
                            onClick={() => setSelectedFields(fields)}
                        >
                            Select All
                        </Button>
                    </Box>
                </Grid>
            }
            {/* {(selectedFields.length > 0) &&
                    <Autocomplete
                        style={{ marginTop: 20 }}
                        disabled={loading}
                        options={EventImportOptions}
                        renderInput={(params) => <TextField {...params} label="Event Import Options" variant="outlined" />}
                        value={eventImportStategy}
                        onChange={(event, value) => {
                            setEventImportStrategy(value);
                            console.log(value)
                        }}
                    />
                } */}
            {/* {!!eventImportStategy && */}
            {(selectedFields.length > 0) &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        style={{ marginTop: 20 }}
                        disabled={!!loading}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            setLoading(true);
                            let apiCalls = 0;
                            const startTime = new Date();
                            let startProcessingTime = new Date();
                            const loadingEvents = toast.loading("Loading Sampling Events...")
                            // const wktConverter = new WKT();
                            try {
                                setJobData([]);
                                await refreshProagricaToken();
                                // TODO this is a temporary hack to get the first deal to all growers
                                // TODO this will be replaced by selection up above IF there is more than one selection
                                const _growerDealMap: Record<string, ATRecord<Deals>> = {};
                                const _growerBranchMap: Record<string, ATRecord<Branches>> = {};
                                for (const grower of selectedGrowers) {
                                    _growerDealMap[grower.ID] = props.selectedDeals[0];
                                    if (props.selectedBranches.length > 0) {
                                        _growerBranchMap[grower.ID] = props.selectedBranches[0];
                                    }
                                }
                                const samplingItems = (await Promise.all(selectedFields.map(async (field) => {
                                    apiCalls += 2;
                                    return await getSamplingItems(field.ID, field.SyncID);
                                }))).flat();
                                // toast.warn(`abc`, {
                                //     autoClose: false,
                                // });
                                // const pastEvents = samplingItems.filter((item) => 'ApplicationDate' in item);
                                console.log('Sampling Items', samplingItems);
                                // samplingItems.sort((a, b) => b.ModifiedOn.localeCompare(a.ModifiedOn));
                                // setSamplingItems(samplingItems);
                                const jobs: JobCreationData[] = [];
                                // console.log('Selected Growers:', selectedGrowers);
                                // console.log('Farms', farms);
                                startProcessingTime = new Date();
                                const processGrowerFields = async (grower: Grower) => {
                                    const deal = _growerDealMap[grower.ID];
                                    // const growerDetails = await getGrower(grower.ID, grower.SyncID);
                                    // apiCalls++;
                                    // console.log('Grower Details:', growerDetails);

                                    const growerFarms = farms.filter((farm) => farm.ParentID === grower.ID);
                                    console.log('Grower Farms', growerFarms);
                                    for (const farm of growerFarms) {
                                        //console.log(fields);
                                        const farmDetails = await getFarm(farm.ID, grower.SyncID);
                                        apiCalls++;
                                        console.log(farmDetails);
                                        const growerFields = fields.filter((field) => field.FarmID === farm.ID);
                                        // const growerFieldDetails = await Promise.all(growerFields.map(async (field) => {
                                        //     return await getField(field.ID);
                                        // }))
                                        // console.log(`Grower has ${growerFarms.length} farms and ${growerFields.length} fields`);

                                        for (const field of growerFields) {
                                            if (!selectedFields.includes(field)) {
                                                continue;
                                            }
                                            console.log(field.Name);

                                            // const zones = await getManagementZones(field.ID, grower.SyncID);
                                            // apiCalls++;
                                            // console.log(`Zones for ${field.Name}`, zones);
                                            // // console.log(JSON.stringify(zones));
                                            // const allZoneData: any[] = []
                                            // const zonesShp: File[] = [];
                                            // if (zones.length > 0) {
                                            //     for (const zone of zones) {
                                            //         if (!zone.IsDeleted) {
                                            //             const zoneData = await getManagementZone(zone.ID, grower.SyncID);
                                            //             apiCalls++;
                                            //             allZoneData.push(zoneData);
                                            //         }
                                            //     }

                                            //     if (allZoneData.length > 0) {
                                            //         const mostRecentManagementZone = allZoneData
                                            //             .filter(zone => !zone.Name.includes("SSURGO"))
                                            //             .sort((zoneA, zoneB) => zoneB.ModifiedOn.localeCompare(zoneA.ModifiedOn))[0];
                                            //         console.log(mostRecentManagementZone);
                                            //         const options: DownloadOptions & ZipOptions = {
                                            //             types: {
                                            //                 polygon: `${field.ID}_zones`,
                                            //             },
                                            //             compression: 'DEFLATE',
                                            //             outputType: 'blob'
                                            //         };
                                            //         const wktRecords: string[] = mostRecentManagementZone.Records.map((record: any) => record.WKT);
                                            //         const geoJSONObjects = await Promise.all(wktRecords.map(async (wktText, index) => {
                                            //             const geoJson = await wktConverter.toGeoJSON(wktText, {
                                            //                 name: index + 1,
                                            //             });
                                            //             return geoJson;
                                            //         }));
                                            //         const featureCollection: FeatureCollection<Geometry, any> = {
                                            //             type: 'FeatureCollection',
                                            //             features: geoJSONObjects.map((geojson, index) => {
                                            //                 return geojson.features;
                                            //             }).flat()
                                            //         };
                                            //         console.log('Zones Feature Collection', featureCollection);
                                            //         zonesShp.push(new File([await zip<"blob">(featureCollection, options)], `${field.Name}_${field.ID}_${(new Date()).valueOf()}_zones.zip`));
                                            //     }
                                            // }

                                            // console.log(allZoneData);

                                            // filter zone data by most recent/not deleted?


                                            const options: DownloadOptions & ZipOptions = {
                                                types: {
                                                    polygon: `${field.ID}_bnd`,
                                                    point: `${field.ID}_pts`,
                                                },
                                                compression: 'DEFLATE',
                                                outputType: 'blob'
                                            };
                                            const boundaryGeoJSON = boundaryRecordsToGeoJson(field.CurrentBoundary.Records);
                                            const boundaryShp = await zip<"blob">(boundaryGeoJSON, options)


                                            const fieldSamplingItems = samplingItems.filter((item) => item.FieldID === field.ID).sort((a, b) => {
                                                return (b["ModifiedOn"] as string).localeCompare(a["ModifiedOn"]);
                                            });
                                            console.log('Field Sampling Items', fieldSamplingItems);
                                            // samplingEventOptions.push({
                                            //     id: "rogocreates",
                                            //     label: "ROGO Creates Points",
                                            //     type: null,
                                            // });
                                            const zonesShp: File[] = [];
                                            const ptsShp: File[] = [];
                                            const mostRecentEvent = fieldSamplingItems[0];
                                            const geoJSONObjects = await Promise.all(mostRecentEvent.SoilSamplingRecords.map(async (record, index) => {
                                                // const geoJson = await WKT.toGeoJSON(record.WKT, { id: record.SoilSamplingData?.SampleID.Value || index });
                                                const geoJson = await WKT.toGeoJSON(record.WKT);
                                                return geoJson;
                                            }));
                                            const featureCollection: FeatureCollection<Geometry, any> = {
                                                type: 'FeatureCollection',
                                                features: geoJSONObjects.map((geojson, index) => {
                                                    return geojson.features;
                                                }).flat()
                                            };
                                            console.log(featureCollection);
                                            const file = new File([await zip<"blob">(featureCollection, options)], `${field.Name}_${field.ID}_${(new Date()).valueOf()}_sampling.zip`);
                                            if (mostRecentEvent.SoilSamplingRecords[0].WKT.toLowerCase().includes("polygon")) {
                                                zonesShp.push(file);
                                            } else {
                                                ptsShp.push(file);
                                            }
                                            const eventsToSelect: SamplingEvent[] = await Promise.all(fieldSamplingItems.map(async (item) => {
                                                let label = '';
                                                let startDate: Date | undefined;
                                                let endDate = '';
                                                let type: string | null = null;
                                                let points: File[] = [];
                                                if ('ApplicationDate' in item) {
                                                    const date = new Date(Date.parse(item.ApplicationDate));
                                                    label = `${toSamplingEventDateString(date)}`;
                                                    startDate = date;
                                                    endDate = item.ApplicationDate;
                                                    const geoJSONObjects = await Promise.all(item.SoilSamplingRecords.map(async (record: any) => {
                                                        const geoJson = await WKT.toGeoJSON(record.WKT);
                                                        return geoJson;
                                                    }));
                                                    // combine all of the points into a single feature collection
                                                    // const featureCollection: FeatureCollection<Geometry, any> = {
                                                    //     type: 'FeatureCollection',
                                                    //     features: geoJSONObjects.map((geojson) => {
                                                    //         return {
                                                    //             type: 'Feature',
                                                    //             geometry: geojson,
                                                    //             properties: {}
                                                    //         }
                                                    //     })
                                                    // };
                                                    type = 'Copy Past Events';
                                                    // } else if ('WorkOrderDate' in item) {
                                                    //     const date = new Date(Date.parse(item.CreatedOn));
                                                    //     startDate = date;
                                                    //     label = `${toSamplingEventDateString(date)}`;
                                                    //     type = 'Use Open Events';
                                                } else if ('RecommendationDate' in item) {
                                                    const date = new Date(Date.parse(item.CreatedOn));
                                                    startDate = date;
                                                    label = `${toSamplingEventDateString(date)}`;
                                                    type = 'Use Open Events';
                                                } else {
                                                    label = "Unknown";
                                                }
                                                return {
                                                    id: item.ID,
                                                    label: label,
                                                    points: [],
                                                    startDate,
                                                    type,
                                                }
                                            }));
                                            const timeSortedEvents = eventsToSelect.sort((a, b) => {
                                                if (!a.startDate || !b.startDate) {
                                                    return 0;
                                                }
                                                // if we can't find those sort by date label?
                                                return b.startDate.valueOf() - a.startDate.valueOf();
                                            });
                                            const newestEvent = timeSortedEvents.length > 0 ? timeSortedEvents[0] : null;
                                            eventsToSelect.sort((a, b) => {
                                                // first we'll sort by historical -> open
                                                if (a.type && b.type && a.type !== b.type) {
                                                    return a.type.localeCompare(b.type);
                                                }
                                                return 0;
                                            });
                                            const samplingEventOptions: SamplingEvent[] = [
                                                {
                                                    id: "rogocreates",
                                                    label: "ROGO Creates Points",
                                                    startDate: new Date(),
                                                    type: null,
                                                },
                                                ...eventsToSelect
                                            ];
                                            console.log(`Events to select`, eventsToSelect);
                                            console.log(timeSortedEvents == eventsToSelect);
                                            let selectedEvent = newestEvent || samplingEventOptions[0];
                                            // TODO This could probably be cleaner but it works
                                            // if we don't have any events to select, we'll select the default ROGO event
                                            // If we do have past events to choose from, search for historical events first and search for those
                                            // samplingEventOptions.push({
                                            //     id: "rogocreates",
                                            //     label: "ROGO Creates Points",
                                            //     type: null,
                                            // });
                                            const dealLabAccountNumber = getRecordField(deal, 'Lab Acct #');
                                            let jobAccoutNumber = dealLabAccountNumber;
                                            if (dealLabAccountNumber?.toLowerCase().includes('branch')) {
                                                const branch = _growerBranchMap[grower.ID];
                                                if (branch) {
                                                    const branchLabAccountNumber = getRecordField(branch, 'Lab Account #');
                                                    if (branchLabAccountNumber) {
                                                        console.log('Branch Lab Account Number:', branchLabAccountNumber);
                                                        jobAccoutNumber = branchLabAccountNumber;
                                                    }
                                                }
                                            }
                                            const job: JobCreationData = {
                                                samplingEventOptions,
                                                boundary: [new File([boundaryShp], `${field.Name}_${field.ID}_${(new Date()).valueOf()}_bnd.zip`)],
                                                boundaryGeoJSON: boundaryGeoJSON,
                                                externalId: "",
                                                rogoId: "",
                                                abPlantingLine: 0,
                                                collectBoundary: false,
                                                depth: getRecordField(deal, "Depth (in.) #serviceinfo"),
                                                deal: deal.id,
                                                gridSize: getRecordField(deal, "Density/Grid #serviceinfo"),
                                                growerExternalId: grower.ID,
                                                labAccountNumber: jobAccoutNumber,
                                                labPackage: getRecordField(deal, 'Test Pckg Display') as string,
                                                farmName: farmDetails.Name,
                                                fieldGuid: field.ID,
                                                fieldName: field.Name,
                                                fieldNotes: [],
                                                fieldPriority: "Regular Turn (default)",
                                                frequencyOfMicros: null,
                                                growerCell: "",
                                                growerEmail: "",
                                                growerRecord: null,
                                                growerName: grower.Name,
                                                companyRecordId: '', //company?.id || '',
                                                labPresubmissionCode: "", // "Regular Lab Submission Code"

                                                // create .json file with JSON.stringify(zones)
                                                optionalFieldAttachments: [],
                                                // optionalFieldAttachments: allZoneData.length ? [
                                                //     new File([JSON.stringify(allZoneData)], `${field.ID}_${(new Date()).toISOString()}_zones.json`)
                                                // ] : [],
                                                points: ptsShp,
                                                pointsAttached: false,
                                                readyToSampleNow: false,
                                                recordBoundary: false,
                                                sendGrowerSamplingNotification: false,
                                                specialLabInstructions: "",
                                                submissionNotes: "",
                                                submitterEmail: getRecordField(rogoUserData, 'Email'),
                                                submitterName: getRecordField(rogoUserData, 'Name'),
                                                syncCode: uuidv4(),
                                                syncCodeReadonly: false,
                                                selectedEvent,
                                                zones: zonesShp,
                                                branchId: '',
                                                jobFlags: [],
                                                nextCrop: '',
                                                lastCrop: '',
                                                specsFromForm: '',
                                                temporaryId: Math.random().toString(36).substring(7),
                                            }

                                            jobs.push(job);
                                        }
                                    }
                                }
                                await Promise.all(selectedGrowers.map(async (grower) => {
                                    await processGrowerFields(grower);
                                }));
                                // for (const grower of selectedGrowers) {
                                //     await processGrowerFields(grower);
                                // }


                                if (userContext == undefined){
                                    Sentry.captureMessage("userContext is undefined: ProagricalImportForm.tsx", "info");
                                }
                                const userId = userContext?.id ?? "";
                                const deals = (await getUsersData(userId))[2];
                                // setDeals(deals);
                                setJobData(jobs);
                                toast.success(`Found ${samplingItems.length} sampling events!`);
                            } catch (error) {
                                toast.error(`Error retrieving sampling items: ${error}`);
                                console.log(error);
                            } finally {
                                setLoading(false);
                                toast.dismiss(loadingEvents);
                                const endTime = new Date();
                                console.log(`${apiCalls} made in ${endTime.valueOf() - startTime.valueOf()}ms`);
                            }
                        }}>
                        Get past & open sampling events
                    </Button>
                </Grid>
            }
            {(loading === 'submit') && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box>
            )}
        </Grid>
        {(jobData.length > 0) &&
            <ProAgricaImportTable
                jobs={jobData}
                //deals={deals}
                setDrawerState={() => { }}
                setLoading={setLoading}
                resetForm={() => {
                    setJobData([]);
                    setFields([]);
                    setSelectedFields([]);
                    setFarms([]);
                    setSelectedGrowers([]);
                    // setSelectedDeals([]);
                    setGrowers([]);
                }}
                updateJobs={(jobs) => { }}
                // allOptions={[]}
                deals={[]}
            />
        }
    </>
}