import { ATRecord, Growers } from "@rogoag/airtable";
import { JOB_TYPES } from "@rogoag/kml-error-checker";
import { FieldSet } from "airtable";
import { Feature, MultiPolygon, Point, Polygon } from "geojson";

export const JobsPriorityOvrd = [
    'Urgent',
    'Regular Turn (default)',
    'No Rush (turn+1wk)',
    'Flexible (before freezing)',
    'Winter Sample Acceptable',
    'Flexible (before growth stage)',
    'Flexible (before planting)',
    'In-crop Acceptable ',
    'Flexible (within 2 weeks)',
] as const;

export type JobsPriority = typeof JobsPriorityOvrd[number];

export const CustomerReadyPriorityOptions = [
    "Urgent",
    "In-Crop Acceptable",
    "Winter Sample Acceptable",
    "Regular Turn"
] as const;
export type CustomerReadyPriority = typeof CustomerReadyPriorityOptions[number];

export interface IGrower {
    id: string;
    name: string;
    notificationsEnabled: boolean;
    cellPhoneNumber: string;
    farms: IFarm[];
}

export interface IDeal {
    jobType: keyof typeof JOB_TYPES;
    density: number;
    samplingConfig: ISamplingConfig
}

export interface IFarm {
    id: string;
    name: string;
    fields: IField[];
}

export interface IBoundary {
    id: string;
    name: string;
}

export interface IField {
    id: string;
    name: string;
    farm: IFarm;
    boundaries: IBoundary[];
}

export interface ISamplingConfig {
    id: string;
    name: string;
    depth: number;
}

export interface IClient {
    id: string;
    name: string;
    jobs: IJob[];
    growers: IGrower[];
}

export interface IUser {
    id: string;
    name: string;
    email: string;
    client: IClient;
}

export interface IJob {
    id: string;
    name: string;
    field: IField;
    grower: IGrower;
    farm: IFarm;
    deal: IDeal;
    submitter: IUser;
    specialLabInstructions: string;
    fieldPriority: JobsPriority;
}

export type SamplingEvent = {
    id: string;
    label: string;
    points?: File[];
    type: string | null;
    boundary?: File[];
    zones?: File[];
    optionalFieldAttachments?: File[];
    startDate: Date | undefined;
    endDate?: Date;

}

export type JobCreationData = {
    temporaryId: string;
    externalId: string;
    rogoId?: string;
    abPlantingLine?: number;
    boundary: File[];
    boundaryGeoJSON?: GeoJSON.FeatureCollection;
    collectBoundary?: boolean;
    branchId: string;
    depth: number;
    deal?: string;
    gridSize?: number;
    labPackage?: string;
    labFromForm?: string;
    labAccountNumber?: string;
    farmName?: string;
    fieldGuid?: string;
    fieldName: string;
    fieldNotes: File[];
    fieldPriority: JobsPriority;
    frequencyOfMicros?: number | null;
    growerRecordId?: string,
    growerRecord: ATRecord<Growers> | null;
    growerCell?: string;
    growerEmail?: string;
    growerName: string,
    growerExternalId?: string;
    companyRecordId: string;
    labPresubmissionCode?: string;
    optionalFieldAttachments: File[];
    points: File[];
    pointsAttached: boolean;
    readyToSampleNow: boolean;
    recordBoundary: boolean;
    selectedEvent: SamplingEvent | null; // 
    samplingEventOptions?: SamplingEvent[];
    sendGrowerSamplingNotification: boolean;
    specialLabInstructions: string;
    submissionNotes?: string;
    submitterEmail?: string;
    submitterName?: string;
    syncCode: string; // this is the the Event ID or Layer ID
    syncCodeReadonly: boolean;
    zones: File[];
    jobFlags: ("Bulk Upload" | "Bulk Upload - Completed")[];
    nextCrop: string;
    lastCrop: string;
    specsFromForm: string;
    testing?: boolean;
};

export enum ViewGroup {
    Default = "Default",
    Company = "Company Views",
    User = "User Views"
}

export interface ViewDefinition {
    name: string;
    group: ViewGroup;
    url: string;
    default?: boolean;
    lastUpdated?: string;
}

export interface UserSettings {
    views: ViewCollection;
}

export type ViewCollection = ViewDefinition[];

export const GeoFileTypes = ["Boundary", "Points", "Zones", "Unknown"] as const;

export type GeoFileType = typeof GeoFileTypes[number];

export type Accessor<T, U extends ATRecord<V>, V extends FieldSet> = string | ((originalRow: U) => T);

interface RogoGeoFile extends File {
    shapeCounts?: Record<string, number>;
}

export type RogoFeatures = Feature<Polygon> | Feature<MultiPolygon> | Feature<Point>;


export interface GeoMetadata {
    groupKey: string;
    dbfKeys: string[];
    dbfValues: string[];
    geoType: GeoFileType;
    path: string;
    geoJSON: GeoJSON.FeatureCollection;
    shapeCounts: Record<string, number>;
}

export type KeyedShapefile = File & GeoMetadata;

export type KeyedShapefileMap = Map<string, KeyedShapefile[]>;

export interface CSVImportRows {
    [name: string]: unknown;
    growerName: string;
    growerEmail?: string;
    growerCell?: string;
    fieldName: string;
    fieldGuid?: string;
    farmName?: string;
    depth?: string;
    labFromForm?: string;
    locationFromForm: string;
    gridSize?: string;
    testPackage?: string;
    ready?: string;
    fieldPriority?: string;
    syncCode: string;
    submissionNotes?: string;
    labAccountNumber?: string;
    freqOfMicros?: string;
    submitterName?: string;
    submitterEmail?: string;
    pointsAttached?: string;
    jobFlags: "Bulk Upload" | "Bulk Upload - Completed",
    boundaryChange: "Major" | "Minor" | "None";
    specialLabInstructions: string;
    lastCrop: string;
    nextCrop: string;
    specsFromForm: string;
    regularLabSubmissionCode: string;
}

export const PortalConfigs = [
    "Single Job Upload",
    "CSV + SHP Upload",
    "AgVance",
    "ProAgrica/Agx Import",
    "Submission: No Files Needed",
] as const;

export type PortalConfig = typeof PortalConfigs[number];

export type Coordinate = [lat: number, lng: number];

export type DisplayFileWithMetadata = readonly [File, Record<string, number>];

export type RogoFile = File & {
    shapeCounts?: Record<string, number>;
}

export type CategorizedFiles = Record<GeoFileType, RogoFile[]>;