import { Autocomplete, Button, MenuItem, Paper, Select, TextField } from "@mui/material";
import { ATRecord, getField, Jobs } from "@rogoag/airtable";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFAutocompleteField } from "./RHFAutocompleteField";
import { CustomerReadyPriority, CustomerReadyPriorityOptions, JobsPriority, JobsPriorityOvrd } from "../types";

export interface Props {
    jobs: ATRecord<Jobs>[];
    intialSelectedJobs: ATRecord<Jobs>[];
    onSubmit: (data: Inputs) => void;
    onCancel: () => void;
    showCancel?: boolean;
}

interface Inputs {
    selectedJobs: ATRecord<Jobs>[];
    submissionNotes: string;
    fieldPriority: CustomerReadyPriority;
}

export function MarkJobReadyForm(props: Props) {
    const [jobs, setJobs] = useState(props.jobs);

    useEffect(() => {
        setJobs(props.jobs);
    }, [props.jobs]);

    const {
        register,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            selectedJobs: props.intialSelectedJobs,
            submissionNotes: "",
            fieldPriority: "Regular Turn"
        }
    })

    const jobsSelected = watch("selectedJobs")?.length || 0;
    return (
        <Paper style={{ padding: 10, marginTop: 10 }}>
            <form>
                <RHFAutocompleteField
                    name="selectedJobs"
                    multiple
                    options={jobs.sort((a, b) => {
                        const growerA = getField(a, "Grower");
                        const growerB = getField(b, "Grower");
                        const aGrower = growerA?.length > 0 ? growerA[0] : "";
                        const bGrower = growerB?.length > 0 ? growerB[0] : "";
                        return aGrower.localeCompare(bGrower);
                    })}
                    groupBy={(option) => {
                        const grower = getField(option, "Grower");
                        return grower?.length > 0 ? grower[0] : "No Grower";
                    }}
                    placeholder="Select Jobs"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getId={(job) => job.id}
                    control={control}
                    getLabel={(job) => {
                        const growerRaw = getField(job, "Grower");
                        const grower = growerRaw?.length > 0 ? growerRaw[0] : "No Grower";
                        let label = grower;
                        const farm = getField(job, "Farm Name Clean") || "";
                        if (farm) {
                            label += ` - ${farm}`;
                        }
                        const field = getField(job, "Field Name Clean");
                        if (field) {
                            label += ` - ${field}`;
                        }

                        return label;
                    }}
                />
                {errors.selectedJobs && <span>This field is required</span>}
                <br />
                <TextField
                    multiline
                    title={"Submission Notes"}
                    label="Submission Notes"
                    {...register("submissionNotes")}
                />
                <br />
                <Select
                    fullWidth
                    value={watch("fieldPriority")}
                    {
                    ...register("fieldPriority", {
                        required: "Configuration is required",
                    })
                    }>
                    {(CustomerReadyPriorityOptions).map((priority, index) => {
                        return (<MenuItem key={index} value={priority}>{priority}</MenuItem>);
                    })}
                </Select>
                <br />


                {props.showCancel && (
                    <>
                        <Button onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <br />
                    </>
                )}
                <Button
                    variant='contained'
                    onClick={handleSubmit((data) => {
                        reset();
                        props.onSubmit(data);
                    })}
                >
                    {`Mark ${jobsSelected} jobs as ready`}
                </Button>
            </form>
        </Paper>
    );
}