export const ContactsFieldIdMapping = {
  'Name': 'fldILdQXpcUeXKwT5',
  'First Name': 'fldGmD6imIF6DXO6I',
  'Last Name': 'fldzDPm0Tfe9piGCd',
  'Mobile Phone': 'fldIyfXhjwuGTgptL',
  'Office Phone': 'fldC7TawsghKRc6bW',
  'Email': 'fldq3rweOrEJeKIOi',
  'Company': 'fldZ9oNuYBgcsiK9c',
  'DM for': 'fldO0E5DDlEzMOQNj',
  'Labs': 'fldj8CKRVoDZI1l5q',
  'Labs (name lookup)': 'fldr9FzAU6IyyO2Y9',
  'Title': 'fldiXEo728SlvtSFw',
  'Source - Detail & Group': 'fldtlLIOoA9FqOKlT',
  'Influencer for': 'fld5jaFHKPJ7b2nsv',
  'Billing for': 'fldEQJiMHILyHGsjk',
  'Biz Phone': 'fld0pQ6hoh8LrOcLH',
  'Location': 'flddfu3TOtTJjoQ9P',
  'Location copy': 'fldUSHTpvvafW2wpj',
  'Meeting Notes': 'fldW8C3aEdevjbMtu',
  'Biz Website': 'fldDOMyb2783huS8J',
  'Sales Rep': 'fldL79aGToVRKu9xA',
  'Source Date': 'fldPgui3yBiRSPlqb',
  'OVRD Source Time': 'fldIcvYvgV4dEWnba',
  'Create Date': 'fldrxOEmukdbxien8',
  'Biz Address': 'fldlr2WoPLoidT1hq',
  'Num of Companies': 'fldtjMKDEWd36dPtu',
  'OLD - Pipeline': 'fldykX99WTxNDaJNn',
  'Maps Source Contact for': 'fldR0TqvdEy8LfocL',
  'Tasks': 'fld18byNAdL5pLzeC',
  'Event Registrants': 'fldxUzIW8KanuCQzp',
  'Marketing Events': 'fldCYzbrUmaquUuwh',
  'Root Cause': 'fld62Uo3rD9Ofn2ad',
  'Branches': 'fld9pBKMDLUBTA8o2',
  'Inventory': 'fldvLis7CU0vFuxoR',
  'Company Type (Contact Link)': 'fldorApOEPLb7Vr31',
  'Immediate Job Creation Confirmation Email Opt Out?': 'fldqrd0ZdhhWlYPio',
  'Master Contact For Deal': 'fldAjXloKp79vjOHy',
  'Backup Contact For Deal': 'fldkgeyX4v2BfD3FE',
  'Company Type (DM Link)': 'fldeIo7S3AXlDeUXE',
  'Merged Company Type': 'fldBm5IccQg8SkvlW',
  'Jobs': 'fldszbYnOPp0corCj',
  'Growers': 'fldL67Igov7WPMgFo',
  'Texting Settings': 'fldhtCyS95fFeBwQb',
  'Password': 'fldP9jFIWggFBuI9t',
  'Portal App Version': 'fld75gvKPpwKmG58B',
  'Portal App Version - Last Updated': 'fldcy10WVNSVmb64q',
  'Grower?': 'fldk0GLEexcmtKIPn',
  'OAuth State': 'fldt8ZpyIbdmPu5IZ',
  'PAuth': 'fldoxGrtMl2lplw8l',
  'Customer Status Formula from Companies': 'fldQ2rBwadvVdVuxx',
  'Status from Company': 'fldLDHbEDArHTPuR8',
  'Pipeline from Company': 'fldRg5ZkdN6yuQsdZ',
  'Portal Import Options': 'fldnd2n3BjfpNJRzz',
  'Current Season Customer?': 'fldaH5JJtMuKC0pwH',
  'All contacts merged formula': 'fldnzrtIrIXOvxdlR',
  'Personal Email': 'fldHs5Z3eehnY4xkk',
  'Portal Settings': 'fldmABpYVEsOlSghz',
  'Companies (Combined)': 'fldhWDcsgKnhdqU6P',
  'Customer?': 'fldnfu3ipBaGOwL9o',
  'Password Backup': 'fldrEdQBTxp1Y8kKL',
};

export const CompaniesFieldIdMapping = {
  'Name': 'fldKPQwG9aaDXl2SQ',
  'Sampling Seasons': 'fldbyMCiahrGOjQvu',
  'Account Owner': 'fldchaLJZz3WazDCd',
  '#Next Action Date (Touchpoint/Dial)': 'fldgIIUFdJVlclKQt',
  '#Next Steps for Sales Team': 'fldTIxRYDu9WGSUN9',
  'Meetings': 'fld5SWVZNshmqQnek',
  'DM Contact': 'fldLg46KjsqAZgaZI',
  'Acres ROGO Estimate - Current Sales Season': 'fldIojbnKvOo4zvoU',
  'Likelihood (%) - Current Sales Season': 'fldJYQlHKKbhsID3E',
  '#Meeting Notes': 'fld37n4Qi8hbidMCu',
  'Billing/Contract Notes': 'fldKCLCIMjmFZd41E',
  'Contacts General': 'fldwbTPfmEp9gAae0',
  'Location HQ': 'fldvYGwZBESyjyp4g',
  'Comm Status': 'fldGRlgpm7w0Ee7as',
  'Type': 'fldfeDqNFhye6ho3h',
  '#Status': 'fldYCIKUhtV6ZzX1t',
  'Num of contacts': 'fldjCloMkvHIfWIq5',
  'Pipeline': 'fldp29lTfHiF1AcHs',
  'Priority': 'fld6bI66jq6Blgdua',
  '% Spring': 'fldjd2tXiXHAL5mGa',
  'SDR (OLD)': 'fldElfq6Lx8oiGXAd',
  '#Last Connect Date (w/ DM)': 'fldKRcy6IYHtG6iPO',
  'Last Action Date (Touchpoint/Dial)': 'fldhGbrQP4YJm8Xjq',
  'Non-Action/Personal Thought Notes': 'fld54qWwIRn1yLkuX',
  'Rules/Process': 'fldh1FAmjd4hmqUnJ',
  'Biz Phone': 'fldtg7owdFlzsMQnC',
  'Influencer(s)': 'fldiTzo4vgezJAVEY',
  'Website': 'fldEZand08KWqulYo',
  'Billing Entity Name': 'fldrPRW6qLcCN24NM',
  'Phys Address for Billing': 'fldcY1xIEh3gmHAtW',
  'Billing Contact(s)': 'fldLCOwjkQ3a1bJVW',
  'Client dashboards & custom info': 'fldSA4FMlWdphu9ET',
  'Ops Owner': 'flduNNj2TRs5F60Sg',
  '#Deals': 'fld49cCXCMU8LUMvU',
  'Lab (deal lookup)': 'fld8avLCNrtLbi7SR',
  'Creation Date': 'fld0CB3KYFMY6bcPa',
  'Season': 'fldQlMGWlazq3QK61',
  'Deal Status?': 'fldH3GlNK1iHwFGIs',
  'DM Source': 'fldb694ovKSQBtpho',
  'Client Clean': 'fldj8j5gU8bsnLKFx',
  'Acres Commit - Signed & Active': 'fldOKXNJ8p8sNmceW',
  'Job Acres - Active & Signed & Uncancelled': 'fldOpuQ8TkLkU0wFj',
  'Invoices': 'fldt99R2xWEXtVuuf',
  'Locations/Branches': 'fldkiNxhdf4UsSw94',
  'Billing Email(s)': 'fldxZhFYjIIT7LQmV',
  'Tasks': 'fld2vfMswYrbNyCBl',
  'All Deal Seasons': 'fldmGSVByx2IuSXWZ',
  'Deal Seasons with Completed Work': 'flddfGHN4ToYguiGj',
  'Deal Seasons In Sales Pipeline': 'fldMiQeY5Dt0n8P2G',
  'Last Deal Lost Date': 'fldhCEaXOaHJvKGIj',
  'MapCode for Location Final': 'fld4lxico1vM0JhjD',
  'Lead Source': 'fldBOnBmupQHh3jMo',
  'Source Group (from Sources)': 'fldbwqEHgHvkwIfKp',
  'Customer Status': 'fld6OE8I2IlRsebDW',
  'Corporate Branch Type': 'fld6m4yqD5LkKjuIH',
  'Events': 'fldhaMHUrbibwSa2W',
  'Name (from Team)': 'fldKyxRqi9iMzXGWc',
  'Recruiting Demographic': 'fldgsVsqf8KQy3nr2',
  '# Hours from Wolcott': 'fldyVUvmbvZ64VJtu',
  'Annual SS Acres': 'fldhj47fcAadgdADP',
  'Mapcode for Location HQ': 'fldpJPQm1y9eS1fhq',
  'Fall SS Acreage': 'fld42jsAhfpf8E2sH',
  'Days Since Connect': 'fldxFwCX5veeHyORa',
  'Days until next action': 'fldV62kQ1foWEmWEY',
  'Onboarding Spec': 'fld2EPWwGxV3O5uAH',
  'Deal Status Active': 'fldRP4oCCJhHFP0AC',
  'DM email': 'fldVxzQYNKDKA9SMB',
  'Last Modified Time': 'fld1dnXXn7p4pzLUn',
  'Dropoffs': 'fld2HqMWUaqJWrR3n',
  'Customer 3rd Party ID - OLD': 'fldRhXcnoy3PG0mi7',
  'DP Credits - Signed & Active': 'fld2f5myIcHe67FsR',
  'Job Costs - Active & Signed': 'fldgkqGXKkKggXP1b',
  'Credits Left': 'fldK3mT15Iq25srUo',
  'Acres Short of Commit': 'fldwJ06PD3ySMf3LI',
  'Avg Job Acre Cost': 'fldsgw7sX1Eedimx0',
  'Credit Acres Estimate': 'fldbOX9m6cqJsRiFF',
  'Avg Deal per Acre Cost': 'fldzBeDVJqyaIBcwl',
  'Location Lab Customer ID': 'fldfDTQJwrKsBi0h9',
  'Marketing Qualified': 'fldKgSrL1Zcaq2Uae',
  'MQL Date': 'fld4YWUpcwQJ7oN15',
  '% Submitted': 'fldWavrEkunDpUPj4',
  'Jobs Acres - Ready & Unsampled': 'fld4gTw8aNgL7Gqtl',
  'Job - Acres Completed': 'fldPTGFAAzvzIdrHx',
  'Total Ready (Unsampled) & Sampled Acres': 'fldAzNr9QCYJz2fJ2',
  'Biz Email': 'fldqrscAC8o8FJAaq',
  'SQL Date': 'fldLCymOt70SBkVZz',
  'SQL': 'fldYOSx8UM4M6tf5U',
  'OLD - Fall Sampling (in Acres)': 'fldKJxdNL6aHvb7Wl',
  'OLD - Spring Sampling (in Acres)': 'fld7bhP1OC7GnCo30',
  'OLD - #Winter Sampling (in Acres)': 'fldlU53dK9WRzpWUV',
  'Harvest Rate': 'fldTPpPHEYoXVx2zB',
  'Field 86': 'fld23Wn2BSFo1wVDq',
  'State': 'fldCT26ebn2fxcsnh',
  'In Network - Spring/Fall': 'fldybcI0hrV77b692',
  'DM Email Lookup': 'fld4Ajg59tBfSJKAB',
  'Last Stage Change': 'fldunt8SnWlxESVIu',
  'Field 91': 'fldMsPR4wjCmMI5On',
  'Corporate': 'fldVYSAMxC0FL5v19',
  'Retail/Farm Relationship': 'fldNrAnyNk2wn2laJ',
  '2021 Target': 'fldwEsfO9H3iQV7ub',
  'DP Missed/Crediting Plan': 'fldZgBwW8b5Xg99W0',
  '% Fall Won': 'fldzNyCbZRCAxcC2i',
  '% won totall': 'fldRQwWJ9TOPafPV0',
  'Geocode Cache': 'fld9KawEEKQJc6NJ1',
  'All Reps': 'fld2wpMvknDLTCfZY',
  'Source Combo': 'flduera435Pj0ifQ3',
  'Transferred from Jay Miller': 'fld9rKte6LoanaubV',
  'Transfer Recommendation': 'fldMPJI7a4QQaLILY',
  'Leads from Nelson': 'fldFMoKyuB70Q1PPc',
  'Time (Days) Since Last Submittal #broken': 'fld4YIjQ0tAdeTZnA',
  'Rainy Day Convo (Spring 21)': 'fldQCAGCdajixWrby',
  'Previous Account Owner Flag': 'fldrU1YlKYZezwsNp',
  'Account Transfer Type?': 'fldzwwZbUxlmqbioF',
  'Acc Owner Last Mod': 'fldmouUJeOJyTPf7p',
  '% Sampled of Acres Committed': 'flds6nrVgsCJY6ZGK',
  'Acres Completed - Lifetime': 'fldNarpI4zO3HeIKc',
  'Acres Committed - Lifetime': 'fldxzSx9qikqVN7sh',
  'Acres Completed vs. Acres Committed Difference - By Season': 'fldhkdbiFogAsbwSM',
  'Seasons w/ Unused Acres': 'fldJAkgLlK8uYvvB8',
  'Latest Deal Create Date': 'fldJx0jrVcE64RxPc',
  'Location Final': 'fldGnYKxUK94g9VO9',
  'Fa-20 Contracts': 'fldcVCOztZ979GhwB',
  'DM Contact Immediate Job Confirmation Emails': 'fldVzBshYzWgzQlAN',
  'Email (from Contacts General)': 'fldugoHphxJOVawAU',
  'Next Step Type': 'fldtpzGteJXSX0YYU',
  'Job Acres Movable to Spring': 'fldUP7k9O54ZQj9hV',
  'Contract Acres Prediction Current Season': 'fld4EBiq98QbbrIJn',
  'Current Season Deal Acres 24C-Fa #manual': 'fldNQk61hIZEoJINP',
  'File Attachments': 'fldRoXxhFpUEZUxgI',
  'Last modified priority': 'fldce3j2hgEr1tzXp',
  'Last Modified - Badger Syncable': 'fldHdJ2pkMva8DH5C',
  'DM email copy': 'fldk7m1o7CskX8tj5',
  'Record URL': 'fldRPHcX0bqAM1i2i',
  'RecID': 'fld4Nh7C4Ls8Bn7yv',
  'Lat': 'fldGQiyxEInCjG7tX',
  'Lon': 'fldy7aV9G9M3X3Bbh',
  'Exe Acres Prediction Current Season': 'fld4x4WRxb1skXzTw',
  'Exe Pred w/ Job Acres Completed': 'fldleOZD79dKtMPqS',
  'Current Season Rev Contracted $ #manual season': 'fldjw6eo2Mfr6fdUH',
  'Current Season $/ac': 'fldQ6s2TtVisTDJyg',
  'Current Season- $5/Ac Eq Acres - OLD': 'fld4LbD89snk4RjBS',
  'Current Season Deal $5.3 Revenue Acres #manual season': 'fldEkkLJL3sCiy6AM',
  'Mapmaking Rules': 'fldkNxcqBZpbML0Np',
  'Renumber Points?': 'fldMkYtUCDbCS1FDB',
  'Create Points?': 'fldT1PGATD6IKVX7S',
  'Move Points?': 'fldC1dCOJfQOM53Sm',
  'Zone Core Rules': 'fldhYx3cJCRbtls00',
  'Mapmaking Notes': 'fldInVYegnC6o02M5',
  'Mapmaker Contact': 'fldsIgxpZlzSDz150',
  'Softwares Active': 'fldUsnWhBY5D9Psw2',
  'Labs Active': 'fldvs1MWXOS8bmOe3',
  'Sync Methods Active': 'fldkxZRkqbFrZFfjR',
  'Pts Sync Auto Active': 'fldZ8dPVieEevP0Nx',
  'Lab Sync Auto Active': 'fldfVFg3LoPWWT1GC',
  'Bdy Sync Auto Active': 'fldaFFvBpQCDU1Jnf',
  'Lab Accs Active': 'fldLMLCGqkCb6PoiU',
  'Farm Name Use in Job Field Name': 'fldKWBQwIoilpM79Y',
  'Name length': 'fldAwOtFay5AnUl8z',
  'Client Simple Name': 'fldT7iMq4FFkNCvUT',
  'Name last mod time': 'fldKYYJlmkdbqwDbT',
  'corr name upload': 'fld41jte7qqlhppE1',
  'Client Simple Name Clean': 'fldehYZRDLBJ8DfZL',
  'Submission Processes Active': 'fldFksN31xikLLElj',
  'Deal Status # Active': 'fldjCpdD01D3nAV8T',
  'Sites Type Active': 'fldQpt3LbqNSE6FfY',
  'Acres Short of Commit with <0 #s': 'flds4lEMQ1IhTYRX1',
  'Acres Left In-Season Check-In': 'fldb3i3vtfQIrqXXS',
  'Acres Left to Submit (Guess Check-In)': 'fldMzsimxPjjdkQq6',
  'Enable SMS Sampling Notifications': 'fldE2QqunBrVqFtu7',
  '% Short': 'fldn7CghdTalFLacP',
  'Avg Discount Avg based on Submit': 'fldx9zpHVATH1tody',
  'Avg Net Discount % Calc': 'fldxn1LoPBjxDoVNV',
  'Discount $ Estimation': 'fldqS7nzB4rXC965L',
  'DM Mobile Phone': 'fld5rPl0bHR0d8CSd',
  'Next steps Last Mod': 'fldui4QPeOWxOr7MP',
  'Last Inactive Deal Status': 'flddUMM1OtEqyriCs',
  'Deals Active (Rollup from Deals)': 'fldr6pPGmAU35JCKv',
  'Spring Start Date': 'fldANItjKTzr5zQXE',
  'Spring Sample Type - deprecate': 'fldp9y3eiSxaSTcv6',
  'Contract Company Name': 'fld2glSdXgMFtO3Ah',
  'Portal Configuration Options': 'fldCOsMegZA4iR0gH',
  'Job Acres - Active & Signed & Uncancelled >0': 'fldgsSRN0vDk6iszR',
  'Current Active Season Customer with Active Acres': 'fldqmi1U1NuHWyrwh',
  'Unready and Uncancelled Acres': 'fldwEkg26MwX6gXS7',
  'Acres Left to Do': 'fldsinxoCwByWwgqd',
  'Fall 23 Completed Acres': 'fld3FX8f4QdrA9za0',
  'Fall 22 Acres': 'flduGUyzYp6wfMB56',
  'Spring 24 Acres Completed': 'fldy5Nu2ipgMabOKr',
  'Service Level': 'fldzBH066yxzdh6Ov',
  'Sampling Types': 'fldrvh96eopXDxXRT',
  '#Marketing Next Steps': 'fld8U5NyoavPK4dCs',
  'acres last edit': 'fldqfiVHGJnxtKBNa',
  'Growers': 'fldKu5KyNwBkbMkQn',
  'Portal Views': 'fldVXOJZSNLY0nYl4',
  'Meetings Dates': 'fld9DOggUFcJ46O8B',
  'DM Contact copy': 'fldzXD78q6YaEy0Le',
  'Season Rollup (from #Deals)': 'fldztaKmCYnFft2pe',
  'SMS Contact Preference': 'flds3FWQqMkeux2qQ',
  'Restricted Barcode Types (Company)': 'fldTxc3x8N89oSbIC',
};

export const MeetingsFieldIdMapping = {
  'Name': 'fldS8u184jo7E4n3f',
  'Type': 'fldynHlAvhESlNJER',
  'Date': 'fldD8pgdploSkhpcr',
  'Company': 'fld1DI5REfcJKFJnD',
  'Owner': 'fldy9pe1MIRzhBNRn',
  'Owner OVRD': 'fldbbiPzXSakP7Zf1',
  'Created By': 'fldgULlpgfafEdhRK',
  'Week Of': 'fldDtqN4CHTrl7bH9',
  'View only Meeting Notes from Company Record': 'fldzXG5VHu6O655sv',
  'Field 10': 'flda9vMlYPh0gCi3f',
};

export const EventsFieldIdMapping = {
  'Event Name': 'fldVIf5FjeHD5UUsM',
  'Event Time': 'fldNm1bs4WHiBp9iY',
  'Event Location': 'fldXhwDhyEiV4bBv4',
  'Event Address': 'fldpifvJFP7ROM3ti',
  'Event Host': 'fldDk9n016TSG3TTa',
  'Companies': 'fldcCyIxnHdHYyjGt',
  'Notes': 'fldbrFsuVa8h14uRw',
  'Attachments': 'fldLXdsEkTxs2nrtL',
  'Event Registrants': 'fldRpTepD2uV309as',
  'Private?': 'fldKSqdRKAiZq5wkH',
  'Geocode Cache': 'fldSkZqVn3CoXNlCe',
  'Event Registrants 2': 'fldwOEiLwoQ0H8ih6',
};

export const BranchesFieldIdMapping = {
  'Rec Name': 'fldYOWEPbns1hGwt5',
  '3rd Party ID': 'fldEpslLSNvwx5rzr',
  'Company': 'fldPsDZpHbxMG2u18',
  'Deals': 'fldjO9Nghblp2KrOV',
  'Lab Account #': 'fldXB86JOIFbmPc7N',
  'Submitter ID #': 'fldqpFHRJ8RyuEaOB',
  'Jobs': 'fldAe6PsG3wgwHaMq',
  'Branch Contact': 'fld2ujTCBAXaY37ym',
  'Mobile Phone (from Branch Contact)': 'fldDaEoJk4djPzogH',
  'Branch Contact Immediate Job Creation Confirmation Emails': 'fldX2el0jHsSmNUNP',
  'Branch Address': 'fldo46lSxrUx9zGdQ',
  'State': 'fldZABrMCtP3qJVFO',
  'Labs (reference only)': 'fldza7MKFDUUSon6B',
  'Rec ID': 'fld0gKkKU5GYNe8El',
  'Branch Contact Names': 'fldhqnf19kISYwCeH',
  'Branch Name': 'fldBkxZuK24q8c2OM',
  'Notes': 'fld02UAEbynGafuUW',
  'Boundary Acres Rollup (from Jobs)': 'fld9RjNZ0YSi6Opno',
};

export const DealsFieldIdMapping = {
  'Name': 'fldof6ltgM8tkLvGY',
  'Clients': 'fldXileeMsVzJDt6W',
  'Account Owner': 'fldYsZCBpOddp0CaU',
  'Ops Owner': 'fldJlaRxwVp5RJT9Q',
  'Season': 'fld0WJvpJEfr0y6K0',
  '#Deal Status': 'fldH99wn74AwEzq2w',
  'Active': 'fldUTHVhpg9r3joRf',
  '#Acres Commit': 'fld3MlLD79T3WN0gh',
  'Critical Credit/Financial Commitment Notes': 'fldmTgYTCZV3KKI7B',
  'Quote Detail Notes': 'fldyVRzs268sJSJ8m',
  'Onboarding Notes': 'fldqm5SiQ9aSPrK3O',
  'Flag': 'fldF6YdnZzMuTKG6n',
  '#Next Step': 'fldCX4yWrH2dzZTwn',
  'Software/Data Notes': 'fldr3aBEyUdhxOXgj',
  'TurnTime (days) #serviceinfo': 'fldoC2vKsCmsJXhWd',
  '#Close Date': 'fldgkzmuHnzgLi6mF',
  'Config/Deal Type Customizer': 'fldGF03QJBdAZyCDq',
  'Rogo Standard PreSeason $/ac': 'fldJEsPpWFz1uK9Xb',
  'Season Start Date': 'fldyhQUThBodgGyGd',
  'Sales Onboarding Checklist': 'fldQl9T8VmtJ2SDW6',
  'Deal Owner Final': 'fldgLe5rCxeBmOJ1g',
  'Deal Owner OVRD': 'fldGBhw9X91UwfWiz',
  '#Steps for Customer': 'fldxqWaKe2qjE2NLQ',
  'Last connect (days)': 'fldeirhuN6Pad3nnJ',
  'Next Connect (days)': 'fldHuXvgRBcAzkfri',
  'Winter/Spring Completion Date': 'fldm3VjbstsQlenaP',
  'Hours from Wolcott': 'fldIlJLStFCAv9Bcd',
  'Location of Company': 'fldI2TIs8rdKG5qLw',
  'Location Deal OVRD': 'fldtqvUqhj0T3tGae',
  'Primary': 'fldknvGbQAuH60oid',
  'Deal Data Status (Old)': 'fldr7xuwsGhSEYUEQ',
  '% data received': 'fldhgrCxFvovOxYut',
  'Sampling Configuration': 'fldjOzhB9xTOHF9OI',
  'Likely Acres': 'fldg7wb5qLBUxhtcy',
  'Deal Likelihood Calc': 'fldXlrBmZekmUpCKK',
  'Ac Estimated': 'fld2yTAPQuJnFwFLD',
  'Ac 5.3 Revenue Estimated (Manual)': 'fldn5BGzA4fyiNbCk',
  'Job Boundary Totals (uncancelled)': 'fldV2DIOTEoXpeP7O',
  'Job Acres Completed': 'fld6SQ2PvOrZpRr95',
  'Ac Left Estimated': 'fld3BYX3YJkb5sUy8',
  'Price Type #serviceinfo #bill': 'fldaEaNcEthbwWniG',
  'Rogo Adjusted PreSeason $/ac #bill (for job)': 'fldISRp6jOEe42MvX',
  'DM Contact': 'fld5wZxzX5o0gTW1F',
  'Map & Data Source Contact': 'fldXCZsbs7Bv1SyqG',
  'Final Source': 'fldT9HiEVFPTQ6eBx',
  'DP % #bill': 'fldKad0RN6HWlNeSl',
  'Credit App System #optional #bill': 'flda30XDM2t8fdtuD',
  'Credit Transfer System #optional #bill': 'fldhQOgXrUKts0Brn',
  'DP $ - Primary': 'fldkaxEtFX2lO2Ygv',
  'Secondary Billed Entity': 'fldwfcBUpLFkxIqba',
  '2nd Entity $/ac #bill (for job)': 'fldhvhYPjahbql98C',
  'Deal Status #': 'fld1juQam8L8JDyaV',
  'DP $ (secondary)': 'fld3YxzRDj7ZeOIlj',
  'Service Level #serviceinfo': 'fldpTgITwwsOP1zFw',
  'Lab Name #lab': 'fldX1OkqLfZyd0Dtr',
  'Sites Type #serviceinfo': 'fldxvkhR25SWdcHMx',
  'Density/Grid #serviceinfo': 'fldwoifqvwCpii0B6',
  'Cores #serviceinfo': 'fldJSMYgZM8l4EpCa',
  'Depth (in.) #serviceinfo': 'fldYWiwYZOret7e87',
  'Pattern #serviceinfo': 'fld0TM9IS7XgMSqbq',
  'Pattern Size (ft) #serviceinfo': 'fldCMffFrzGkxP8ZL',
  'Pattern Angle (deg from East) #serviceinfo': 'fld1h4oqYPuO8OLJ2',
  'Jobs Submission Process': 'fldcc2o2oePHIsUfS',
  'Lab Package #lab': 'fld3HFq2kQpKztThv',
  'Freq of Add-Ons #Every Nth Sample  #bill': 'fldwIu1Ys012M6e3s',
  'Lab Acct #': 'fldD5CaYGRWCweYet',
  'Lab Results Email': 'fldex6klOlMuyvGLy',
  'Customer Software': 'fldzBug6FlxmaQeHN',
  'Auto-Sync Pt Edits?': 'fldTQvaaHe5vdsyny',
  'Payment Timing (days)': 'fldgjcPejFpCtFTK8',
  'Price /S': 'fldNMh3WuRgXIUPpd',
  'Source Group': 'fldLWD8kbBITNtxIz',
  'Test Pckg': 'fldDXE7gNonlgWQcI',
  'Test Pckg Display': 'fldzVXPZaEmHmiB05',
  'Job': 'fldJEHO2Ms9ubg9ZT',
  '2.5G Ac Eq': 'fldwb9jdJsi6QSSqL',
  '2.5G $ Eq': 'fldaW6eTYn9XdV1uW',
  'DP Ready Check?': 'fldYsDGRkBIexMiYg',
  'Job Ready Check?': 'fldFEuOHWFJz3D687',
  'Contacts': 'fldhB85vCGnjqz2Tq',
  'Test Package Descr': 'fldKGIFwu8afwIiMK',
  'Sync Method': 'fldhsxupWE1yYBDnw',
  'Auto-Sync Lab Results?': 'fldzSetk86BjdJZkt',
  'Billing Check?': 'fldENFGEUL2NLgNBO',
  'Lab Check?': 'fldcnOL3cTQc3dY7n',
  'SS Specs Check?': 'fldqFHoQtQfLkG2rF',
  'Service Check?': 'fldoGdiKY7ZgAuBcf',
  'Data Sync Check?': 'fld1XeCVFcrllRWaP',
  'Client Clean': 'fldtry3pmbva3tjkT',
  'Lab Submission Details': 'fldp2FbL37ZCwU7s0',
  'Trip Charge #bill': 'fld3mSefpOp0Fed0d',
  'Tilled Charge #bill': 'fldOLNdau9SY1NeWB',
  'Major Boundary Collect #bill': 'fldKttpoebUEKI3v2',
  'Minor Boundary Collect #bill': 'fldyy9VVBTOQVhTyF',
  'InSeason Upcharge Potential % #bill': 'fld1mLVJvzPPU1qQ8',
  'Price/Field #bill (for job)': 'fld0DXHdSpw6DYSX4',
  'Sampling Type Special': 'fldkNAfZ06NgJbwrH',
  'test name': 'fldKFlH7AXVh0tjwb',
  'Deal Record ID': 'fldb5MiD2M9E0xYAA',
  'AB Line East Definition': 'fld2EYfQRiqH8IaE9',
  'Test Package Presubmit Type': 'fld5DvQAculY7Dno8',
  'Auto-Sync Bnd Edits?': 'fldUbMHzUc37Dpel9',
  'Lab Shortname': 'fldWuPC1LYJBJskYJ',
  'Created Date': 'fldiF7P9ChMLa29kv',
  'Lab Add On $/S #bill': 'fldh5ffYvlpqo7nJ6',
  'Specialty Add On $ #bill': 'fldZFH6xvzFpMIuPo',
  'Base Sampling $/ac #bill': 'fldU6PsRv43cY0jA0',
  'Shipping/ ac $ #bill': 'fldY4T5MjlVC5L7GL',
  'Lab Base $/S #bill': 'fldLWEASBiOjgwF8f',
  'Turn Upcharge #samplingcharge #bill': 'fldF0xBVGhTpzROoD',
  'Cores Upcharge #samplingcharge #bill': 'fldoYiKN4NK3kMptt',
  'Depth Upcharge #samplingcharge #bill': 'fldkMiMKOjXhhQSRy',
  'Pattern Upcharge #samplingcharge #bill': 'fldkr20C3Hl6pc4Cl',
  'Lab $/ac #lab #ref': 'fldNBNxSBTXjbscEV',
  'Final Sampling Only $/ac #samplingcharge': 'fldUCWMIKbiTxENYA',
  'Lab $/sample #lab #ref': 'fldeqmnPY5DJlfoly',
  'Rogo Adjusted PreSeason Price Grand Total $': 'fld2hWBIhQn6Txkf6',
  'OVRD for Rogo Adjusted Price #bill': 'fldrFXxzf7dx0xeGL',
  'Customer (1st Entity) PreSeason $/ac #bill': 'fld7tkKcCFaHyVx5H',
  'Rogo Adjusted $/sample #ref': 'fldvByGCijKLcfvkM',
  'Rogo Special Discounts $/ac #bill': 'fld6LODw5RwGaFFkT',
  'InSeason $/ac #ref': 'fldv3daGGZchPGq6y',
  'InSeason Grand Total $ #ref': 'fldGh4zbmFHFi5sdM',
  'Pre-Season Discount Amount /ac #ref': 'fldOydM2OHf9l0bdV',
  'For Quote Template Pre-Season Sampling $/ac - Spec Addons $/ac #quote': 'fldUfeUMPVej6IQgB',
  'Total $/ac No Addons Pre-Season #quote': 'fldwsEaM0RmuQZSPK',
  'Informal Quote Approved': 'fld7eZz3h9S9QDkJm',
  'Service/Spec Description for on Quote': 'fldahuKujsXF94SRz',
  'Formal Quote & DP Invoice': 'fldD7aGFbhV7i9LpI',
  'Location Final': 'fldi4agHeaOR7EBiH',
  'Mapcode for Location Final': 'fld7ome4wj5t4ugyk',
  'Formal Quote Approved': 'fldRBXxUj2OG6yJgQ',
  'Customer Status from Company': 'fldM6bvPjJXIXRu3m',
  'Pipeline from Company': 'fldZSqPfnktGEQzkR',
  'Rogo Special Discounts Grand Total': 'fldkgrMVjVuQltNdV',
  '2nd Entity Discounts Grand Total': 'fldwDeQA5LMyC2Px8',
  'Post 2nd Entity $/ac Grand Total': 'fldfoDpaPXGRRgT1u',
  'Rogo Standard PreSeason Total Price #ref #quote': 'fldCkiiyzIsg8I5WQ',
  'Rogo Standard PreSeason $ Total': 'fld37bzrxNDNu34PT',
  'Customer (1st Entity) PreSeason Grand Total $': 'fldeHcEnlemcsq5ig',
  'Avg Field Size (acs)': 'fldRnaNJyj7oyh3lO',
  'Field Size Upcharge #samplingcharge #bill': 'fldXWyI528I8SYk3m',
  'Cores Per Stop#serviceinfo': 'fldAflziD8WNDME34',
  'Supp Rep': 'fldDNwCpF58zRww90',
  'DP Status': 'fld1MK3lLCLs5iJJB',
  'Customer 3rd Party ID from Company - OLD': 'fld5QPmBF25W8XHJ7',
  'Lab Pts Edit Sync Method': 'flddHNTEREMIohmpt',
  'Job Cost Totals': 'fldKgZDJW0tp4LDTe',
  'Company Credits Left': 'fldtrl3GrhwxMkvvr',
  'Company Acres Short of Commit': 'fldqGFbuVjuIBovxs',
  'Company Location Customer Lab Account Codes': 'fldWSbVam80PVlTZR',
  'Ops Onboarding Tasks Complete?': 'fldpyuPyAlCNCCbJ3',
  'Lab Email for CSV Send Lookup': 'fldeAU7HLAwFZnhCv',
  'Client Contact Email Lookup for Addon Charges Email Send': 'fldlO7p1JrEAjiOx3',
  '# Stops': 'fld31HZAGYLA6kbFo',
  'State': 'fldFcQHYBfzvEVA3N',
  'Ops Dataflow Approved?': 'fldkEqu9si1UN48Mu',
  'Paid Bndry Collect?': 'fldWmcFUo7HgrCHHP',
  'Field Discretion?': 'fldyJNRWSrJrnS6rK',
  'Minor Bdy Awareness?': 'fld10MLouyEiisaMk',
  'Configuration Price Adjusted': 'fldMbVdH5CUYoIB6p',
  'Nelson Prediction (Under Commit)': 'fldxfzcSOsRvKLhf8',
  'Frozen Ground OK?': 'fldkcozJWCYrBwo0S',
  'Review Status': 'fldmE9xUJ3bwg8fxv',
  'Dashboards': 'fldIJqllEQ7GP3cDh',
  'Job Acres Ready': 'fldgJcBFRQomcNsme',
  'Client Job Acres Ready': 'fldLXKMEqRhiBqvm6',
  'appointment set': 'fldQ8d8IgOEgSMKFx',
  'Meeting Date': 'fldTfyyvL9D9IwhHN',
  'MQL Date from Company': 'fldqT3qXxe6j4aaIh',
  'MQL from Company': 'fldQLKNA5Fw7JSAKX',
  'Deal Attachments': 'fldjGrrXPw9ghGPtX',
  'Type from Company': 'fldV2hzrk0eu2Kyd7',
  'Deal Change Date': 'fldWG6X12iHNS2hEc',
  'Customer Step Change': 'fldDTKnF5656EvBlW',
  'Deal Type': 'fldSyiNG3KuXKNPtw',
  'Samples Comp.': 'fldun6QBq0Cy0lakY',
  'Quote/Invoice Stage (Lookup)': 'fldas4JU1SevrJRkt',
  'Corp Branch (Company)': 'fldTmde1I694Vumki',
  'In Crop Sampling?': 'flddLNsItr83hRc72',
  '% of Acres In Crop': 'fldCteH3fjxwZvDht',
  'SDR Final': 'fldBI033PJvCmdgB9',
  'Lab Primary Delivery Method': 'fldvQRBJ5bI00QtWK',
  'Lab Address': 'fldhA8i3nzI3Xp57r',
  'Meeting Set Date': 'fldAbnjbntbO5ELuw',
  'Non-Lab Cost $/AC': 'fldfWFOQmeUddAL3L',
  'Time to Close': 'fldoA3FZnMnSe4zZV',
  'Closed Status': 'fldHWbVSLaLdVlG4S',
  'Acres from Prior Commit': 'fldhv0CJFvC3HBYcy',
  'mapcode2': 'fldetlbPQZoNTefGJ',
  'Master Contact': 'fldRNsAeLqaJoLKQw',
  'Backup Contact': 'fld6ZCA68OOv1xDw9',
  'DM Contact Immediate Job Creation Confirmation Emails': 'fldOo512KGGi7Fyw2',
  'Master Contact Immediate Job Creation Confirmation Emails': 'fldhNmhV8Comh51W3',
  'Backup Contact Immediate Job Creation Confirmation Emails': 'fldXA3eB7U8p4jXIA',
  'Job Acres Completed Historical': 'fldHHgC24A8Wf4lGo',
  'Job Acres Completed with History': 'fldnwcKUGPsFKeUf9',
  'Job Boundary Totals (uncancelled) Historical': 'fldl34RdWbOgwhbee',
  'Job Boundary Totals (uncancelled) with History': 'fldCMG1D1XfCDjFlD',
  'Job Cost Totals Historical': 'fldYYDypVgklLdNrr',
  'Job Cost Totals with History': 'fldtIk2FN6fzFSKkX',
  'Job Acres Ready Historical': 'fldVWokfAJlTk2MN3',
  'Job Acres Ready with History': 'fldEzqDpC5Of51RK4',
  '# Deals linked to quote': 'fld4Aw66HhGP2sew5',
  'Quote Season(s)': 'fldu4WqBVtbrg7aBx',
  'Customer 1st Entity Effective Discount': 'fldCiEv0suvN745HD',
  'Priority (from Companies)': 'fldXokqAjSyGDxaVe',
  'Customer does not care if Sample IDs change?': 'fldNGPJruInptpDY7',
  'Type (from Clients)': 'fldVEi0y7HIZRTZJp',
  'Lab Code': 'fldLOl3jWQrAli3Qw',
  'Job Prim-RecID Lookup': 'fld954MLmvy4qdjIq',
  'OVRD Acres to Zero? (Virtual Deal)': 'fldCjBq3r2pLYJECG',
  'OVRD $ to Zero? (Virtual Deal)': 'fldKsOam3U0PxfuBH',
  'Unresolved Critical $ Notes': 'fldYrJcLfAT4hGFbm',
  'Avg $/Ac with Historicals': 'fldgYqRfWjGjpoHMD',
  'PDF Order Type': 'fld9mRcPMvzMrZHB6',
  'Season End Date': 'fldL50gCMesKnsLlg',
  'Ops Mod Acs Est': 'fldmrYz2CAAKGrUUl',
  'Grid Adj Factor': 'fldrEtBWyiwaaE7Un',
  'Zone Adj Factor': 'fldagKL0CmhJz5SBX',
  'HQ Location (from Company)': 'fld1NDsD5HXryzWwS',
  '2.5 ac samples estimate': 'fldDwlq3O1ag6Spr7',
  '2.5 ac box estimate': 'fld2Wnax9ItzwBJwx',
  'Temp issue notes': 'fldrNCZmuMjRP8ltM',
  'Branches Linked to Company': 'fldNDoS11kpeS58fd',
  'Pts Required?': 'fldPQfgOPiORYGAqJ',
  '2nd Sync Method': 'fldE5azDPY5Av42Lp',
  'Required Job Fields String': 'fldswcVxEl3rND18u',
  'Farm Field on Job Activate (from Co)?': 'fld833AZNvjfI2PTf',
  'Client Simple Clean': 'fld3VbqwajuhrXVCt',
  'Zone Interleaving Allowed?': 'fld0202kpE4lVKL91',
  'Unbilled Jobs': 'fldX3jgtQzcOITo5S',
  'Price Reviewed Latest': 'fldIJeJyw2PJiwBtn',
  'Price Reviewed Last Mod': 'fldLPBtA0lKq0akZI',
  'Created Time': 'fldyBjj4mah6UIQns',
  'Price Review Issue? (minutes)': 'fldO5apGVnnSNR1G9',
  'Lab API Sync ID': 'fld3CIQEcrsE0L20N',
  'Core Diameter': 'fld48tW3cGpNsNoxo',
  'Field Parameters': 'fldRyBDNz2oZe3aTO',
  'Job Cost Totals active and inactive': 'fldqxQ90eMEnEGc7e',
  'Branches': 'fldj9sN2NCOE3vT4U',
  '3rd Party ID Rollup (from Branches)': 'fld7xPujLDYAPHYkp',
  'Sampling Tolerance (ft)': 'fldVC11bnAdmE7pTK',
  'All Branch IDs Rollup': 'fldqLa33iaiYiOA2N',
  'Customer Data Email': 'fld8d22Pqo0IQPVRl',
  'Restricted Barcode Types (Deal)': 'fldV3ukOrCSxFyMdg',
  'Restricted Barcode Types (Labs)': 'fldNdEUR8ud5lTDvg',
  'Restricted Barcode Types (Company)': 'fldC3LgIXcuEjazNL',
  'Restricted Barcode Type Final': 'fldzSFpvLfzYnhIo4',
  'Price Review Valid?': 'fldJjq0ec1QAhOZQx',
  'Barcode regex description': 'flda3X2w2qx0EAFU1',
};

export const QuoteInvoiceFieldIdMapping = {
  'Invoice/Quote Title': 'fld7gQhJuN3nygeQD',
  'Seasons': 'fldgGoppt5wA5ZY8w',
  'Final Quote Additional Pricing Notes': 'fldyEeU2Y2OvmB4Jn',
  'Total Invoice Amount': 'fld3cvOW2NfhSa72F',
  'TRUE Std Quote': 'fldXDMZxwpl28lUSK',
  'Guar Pmt Quote': 'fld0fQv8XOVUxI1DR',
  '1st Yr <5k': 'fld3jGSWN7Gxz7HuI',
  'Client': 'fldeFGrvCWsVpxUPT',
  'Count of Linked Deals': 'fld2ZoKw6N3ar55MI',
  'Use this Quote': 'fldvUSI4hvns495rc',
  'Deal DP': 'fldpUgZp8MgR383bI',
  'Quote/Invoice Stage': 'fldyR7zfZpHMMPdiv',
  'Signed Quote': 'fldMRo151cgBh9ARC',
  'Invoicing Notes': 'fldbx61GdIqs5STZi',
  'Jobs': 'fldwLZnKgcAHdfw3C',
  'Entity Name - Billing': 'fld5t08sCp9digPfw',
  'Contract Name - Lookup': 'fldjo2egzs90hTOxW',
  'Phys Address - Billing': 'fldSevCwYOqeY5naj',
  'Contact(s) - Billing': 'fldko9PXWI7cjgnRD',
  'Email(s) - Billing': 'fldd5PWN7KU1f4j6N',
  'Jobs Total (1st entity) $': 'fldyFgL4vKVX79pp4',
  'DP Total $': 'fldM0AXLSsIFbUwqK',
  'DP Acres': 'fldrHiJwB3N2ilG0T',
  'DP %': 'fldCQbXEihiP4GNhw',
  'Adjustments': 'fldb8mw0vHSP3ke1h',
  'Sent in QBO': 'fldcPs6aGE9XCjYsG',
  'Final Invoice Amount': 'fld6Nahw1NpIRzetX',
  'OLD - Bill Sent to secondary': 'fldhK2oUNNM6WW0Jv',
  'DP Total $ (secondary)': 'fldSR88Yep5ilGdLu',
  'Jobs Total $ (2nd entity)': 'fldik5NDhstmjp9TE',
  'Created Date': 'fldWekWFAl7i8Yz8A',
  'Cred App Sys (jobs only)': 'fldtRrlKRya9xt9Yu',
  'Credit Transf Sys (jobs only)': 'fldPMays3EZdEAdaD',
  'Active?': 'fld72T9NGwWAUlkDk',
  'Primary?': 'fldcBTGCQEJG6gfnF',
  'Approve Final Quote': 'fldjYAPHjTVI5Khg6',
  'Acres Commited': 'fldMQ8Eh9JWn90z89',
  'Total Acres Committed #sum': 'fldIRE66OYOsde5Hs',
  'InSeason Grand Total for Primary Deals': 'fldF3AdAeLl1l1ThE',
  'In-Season Grand Total for All Primary Deals #sum': 'fldJ89UMpkgkiiufQ',
  'Rogo Adjusted Price Grand Total for Primary Deals': 'fldzbkWC3peKg22ub',
  'Rogo Adjusted Price Grand Total for All Primaries #sum': 'fld8D65TqP2ZhGzzq',
  'Total of 2nd Entity Discount Lookup': 'fldxBjvHXlHkGMpB2',
  'Total Add\'l Discounts for ALL Primary Deals': 'fldOmQUEQG8HH23Pi',
  'Post 2nd Entity Price Total Lookup': 'fldxvtMiLJIDHHMOM',
  'Post 2nd Entity Price Total for ALL Primary Deals': 'fldWufIjpcSeI6Ljg',
  'Rogo Standard PreSeason $ Totals Lookup': 'fldZbDY6vwIORIVJv',
  'Rogo Standard PreSeason Total $ for ALL Primaries': 'fldlrGAlF4Etzz8zF',
  'Rogo Special Discounts Total Lookup': 'fldX3r6Mp6ObQdFfW',
  'Rogo Special Discounts Total for ALL Primaries #quote': 'flddR9dD9hdqeEmMs',
  'Max Payment Term': 'fldhgtmWUHQdNNRTs',
  'Max DP terms': 'fldwqg2KbUXdV5IQR',
  'Discount Eligibility': 'fldD3F23G6190xCnU',
  'Season Unique': 'fldnySf3GNGz5FRne',
  'OLD - SPRING SALES QUOTE': 'fldbr6f0OGfyDqgXG',
  'OLD - Std. Spring Growers': 'fldgrrM4VwV52TGku',
  'OLD - Std. Spring Quote >5k': 'fldhFJ6hU7U0gCLyp',
  'OLD - Std. Spring Quote <5k': 'flddjxZzg6R9WdZn1',
  'OLD - Std. Fall Quote Growers': 'fldcJwvpJm9g5iNtH',
  'OLD - Std. Fall Quote <5k': 'fldkVlaGM2zepP0wn',
  '?? - Fall Quote with Discount': 'fldWnqPJvWxewmw9N',
  '?? - Fall Discount + Subsidy Quote (WHIN)': 'fldmsE9UM23syCdkN',
  '2nd Entity $ on Deal? - Max': 'flda76UiuCkKcYATv',
  'Rogo Special Discounts Max': 'fld0Jew9M4BPo8Rp6',
  'Deal Status (Lookup from Deals)': 'fldk7dUuzSOpB510R',
  'Rogo Adjusted $/ac': 'fldqxCML1ZAwuiuJh',
  'Job Acres Completed': 'fld091F2wEDen3gpr',
  'Standard Quote Winter': 'fld1vascWjMEsuAZ4',
  'Date Sent': 'fldWM6NItgUBWAoCS',
  'Due Date': 'fld5qus5lpUAdLcL8',
  'Credit $$ Applied': 'fldwEW32ZxllgepDa',
  'Amount Due': 'fldwWSbpgoXNzHZGM',
  'Jobs on Invoice': 'fldFA27zEdzi45vJy',
  'Boundary Acres (from Jobs)': 'fld1fCTqUmuwoxNKq',
  'Acres on Invoice': 'fldKWggcvlLai7h9D',
  'Acres on Invoice OVRD': 'fld1R2lnlVDUi6DpC',
  'Latest Job Date': 'fldYZpkbLnSIRkYdn',
  'Deal Status #': 'fldyGi1fkeIIWJ9ru',
  'Jobs Total (1st entity) $ Historical': 'fldcvWFX3WOiD144F',
  'Jobs Total (1st entity) $ with History': 'fld67gphNhBemLdch',
  'Jobs Total $ (2nd entity) Historical': 'fldNxI0yuQ8xbV5yz',
  'Jobs Total $ (2nd entity) with History': 'fldJv5VVbUJn9bFbi',
};

export const LabsFieldIdMapping = {
  'Name': 'fldfG48Ye1WJRiUQK',
  'Default Lab Owner': 'fld5unP3SIsIgXknc',
  'Primary Name Enter': 'fldXcVWI11L7ZL3oN',
  'Lab Shortcut Name': 'fld5m2D3C6BJFnupD',
  'Address': 'fldeVq4Yugm8cZZEW',
  'EasyPost ID': 'fld1mMc1o877nzXqi',
  'Primary Delivery Method': 'fldYnrKjaX5xbiwsO',
  'Origination Address': 'fldZZG7T8ZaYHr6wi',
  'Phone Number': 'fld9QwpwAwdjMjHCs',
  'Auto-Upload?': 'fldF852ENjSvS7hVK',
  'Pts Edit Sync Method': 'fld33J5CiseI5Zd7W',
  'PDF Order Type': 'fldydf7dwSJETHeug',
  'Restricted Barcode Types (Lab)': 'fldoxSnEKtvOzYWma',
  'Lab Options': 'fldhcdtujoCkY0Q88',
  'Presubmission Code?': 'fldmRaFeGQY2JNr12',
  'Lab Notes': 'fldPtykDwywITg7NP',
  'Lab Checkin Upload': 'fldqOn6mWB5R0tcOK',
  'Box Dropoff Notification Emails': 'fldZZArndX5sbOb3t',
  'Lab Submission Data': 'fld0JdAKzf2obWt5t',
  'Lab Tests': 'fldZ4OcpRdpmVNUcl',
  'Dropoffs/Shipping': 'fldReSTsMEfiEuruT',
  'Support Contact': 'fldpVMWjCkm6wMagc',
  'Drop Off Coordinator': 'fldN3QFX5rMuJgFdx',
  'App priority order #code': 'fldbNgVKj0W9HCoco',
  'Box/Drop Dashboard': 'fld5hdHz3qDgo9TkO',
  'Lab Issue Dashboard URL': 'fldLWLhz61G3atqQM',
  'Origination EasyPost ID': 'fld1qJmAyZO5hyY03',
  'Min Robot Label Count': 'flddaIyFKGPJVf5Cc',
  'Rogo Submitter ID': 'fld0IEGwpbDC7o3Iy',
  'Label Size LxWxHxWeight': 'fldCU2FmoWXC80GmK',
  'Address Last Mod Time': 'fldso9zRPlBhv6jA4',
  'Lab Code': 'fldh3UP1YUfOqbuEH',
  'Lab Code Gen': 'fldXdtRFlydRMB89N',
  'Automation Test': 'fldQ9CVFZCuOIRf6O',
  'Lab Submission Data Comma Separated': 'fldQNwjLrsUqWRRgT',
  'Dropoffs/Shipping (Active)': 'fldmYetd7LC9xC20H',
  'Job Prim-RecID Lookup': 'fldpPexESBy4lfQB6',
  'Box': 'fldlwX9Xd8sGyemob',
  'OLD Issues': 'fldUFS9BCc7TEKGWu',
  'Support Emails': 'fldbyufPEsy1OGUIx',
  'Box Count': 'fldXbEwAWxmcb0Nve',
  'Branches': 'fld69TMGLh1b0jkHt',
  'Courier Pickups': 'fldwLyRcKNAdqJAu3',
  'Courier Pickup Form': 'fldoNNsMj8WiNZLE5',
  'Lab Specific Courier Form Link': 'fldjVSYkKkX56a2yf',
  'Lab Record ID': 'fldpqbTUo2nLLHm0r',
  'Dropoff Dashboard': 'fldJtj10LEMJeUgZh',
  'Final Courier Pickup Link': 'fldABUoKqtDMUMt2z',
  'Lab Courier Friendly URL': 'fldNeKBKyFTMtiDBu',
};

export const JobsFieldIdMapping = {
  'Name': 'fldiHbrFaHnu94rGU',
  'Invalid/Missing Req. Fields': 'fldlr71qIRa9A7xWJ',
  'Testing?': 'fldQZvmOuqDHovHGJ',
  'Free/DoNotBill?': 'fld14Vj0KXqCuWEeW',
  'Job Flags': 'fldtTtxsWgfZHmgY9',
  'Field Ready at Submission?': 'fldUY5CbOYruJQEDf',
  'Field Ready Man OVRD Date': 'fldZfRyyUdselV6mj',
  'Priority OVRD': 'fld20v8o1rbEs3TMF',
  'Field from Form': 'fldxz0jU8sFAukKbL',
  'Farm from Form': 'fld2kczBE4hfLhoYb',
  'Grower from Form': 'fldFnp8F8BERTvpDF',
  'Grower Cell Phone Number': 'fldjVoHMWAlvKHYVU',
  'Grower Link': 'fldwp9owcJUMOcmSx',
  'Grid from Form': 'fldbyPc7Iyxej3PD3',
  'Depth from Form': 'fldBKTM3Iqndu9kGC',
  'Lab from Form': 'fldgFfZjBFOWZSj2K',
  'Specs from Form': 'fldKTYtGEOBh2ikc9',
  'Deal': 'flddwGrTeKCvUfIDH',
  '3rd Party Customer ID for Deal Search': 'fldZiA15Ea6L5zBsN',
  'Location from Form': 'fldHb3HUTCTm00NTg',
  'Branch / Location #form': 'fldhOLQxAKFMko4iG',
  'Event ID': 'fldPK4LeNFEt8i3M8',
  'Regular Lab Submission Code': 'fldxP14GUHDSdiZVo',
  'Bnd Shp': 'fldZw6OMj8tj8ZVBD',
  'Retest? (Pts Submitted by Customer)': 'fld7Vskvv2QQYtztg',
  'Pts Shp': 'fldvHyk1IyCDzEN1L',
  'Sample Zones Shp': 'fldfmhnFGaZ3PttYD',
  'Files-Valid-OVRD': 'fldIuoqW0chbFFyZd',
  'Int Mission': 'fldfEdxykIlszCY4i',
  'Boundary Change Type': 'fldSVMPvYx45LvMFs',
  'Submitter': 'fldaK6fU7oefjj3MI',
  'Submitter Email': 'fld8aqd96qhxYQE77',
  'Submission Notes': 'fldexuIefygTjrl1w',
  'Lab Instructions': 'fldF90aOUaKijXQbC',
  'Warnings/Instructions for Field (custom) #ops': 'fldCWrC7BftftXajx',
  'Last Crop': 'fldf8cNkk5cvc8UEp',
  'Next Crop': 'fldnTWP9WC6dvujdU',
  'Farm Name Clean': 'fldjwsU4XW5rAu1yi',
  'Field ID': 'fldpZ7IgSVuwyIBNJ',
  'Submitted Boundary Acres': 'fldV2khnT6dS5ZIfG',
  'Reported Sample Qty #ref': 'fldzmNsR1DrN12pfc',
  'AB Planting Line': 'fldYk3yGZJa4PYYKN',
  'AB Line East Definition From Form': 'fldbUYQhBpSiu9IZD',
  'Cyst/Nem Lab Submission Code': 'fldtIPxW1oj5CEDws',
  'Farm Name OVRD': 'fld66Q6GqYCNIUR1O',
  'Deal Test Pckg': 'fldkLDQbdwNSLa9jE',
  'Creation Date': 'fldpO5l2NC0G4v1ya',
  'Final Lab Presubmission Code': 'fldRiuCtTahchPzlB',
  'AB Line East Definition': 'fldXhbZNRuCq8p1WH',
  '3rd Party Lab Test Package ID': 'fld6yjUt78xLDSKdb',
  'Selected Sample Column': 'fldqpklstrUIzdN0N',
  'Billable Percent %': 'fldezoJ3jUACaZB2k',
  'Use Farm Name from Company': 'fldiVLAZGYMa0ToS4',
  'Bad Job Name': 'fldTa5SwhcO4hHXip',
  'Job Name Mismatch': 'fld67wqcQuB5caRzT',
  'Unresolved Critical $ Notes (from deal)': 'fldOkSYRfuSC8ogft',
  'Required Fields Deal': 'fldi0JnXafL7xFdYo',
  'Mapmaker': 'fld7iTUrAvCXLv2bb',
  'Client from Form': 'fldEcwBuglkoCqMzW',
  'Deal from Form': 'fldmnkh8wNeoHhK4Z',
  'Customer Reported Acres #ref': 'fld1nJkMCcBQZhLOO',
  'Shipping Linked Job(s)': 'fldhvETWsVpSJfQzg',
  'Same-Field Job #': 'fldAVSo1cSvAELyff',
  'OVRD LabAcc #': 'fldvKc6lyuMRkw95T',
  'Field Name Clean': 'fldtcNGJOSPLowjus',
  'Job Status - Simple': 'fld2IFv3WYL7Jj8BQ',
  'Job Status #': 'fldOSkUp3iAYaafCb',
  'J Status OVRD': 'fldLSecU5TE3KNHHG',
  'Mission Status': 'fldzCcrXdx9RaL7hL',
  'App KML auto gen?': 'fldIO5mSrvkFIkpGg',
  'Error Notes (auto)': 'fldood7f8FyzDvKaw',
  'Int Miss Edit?': 'fldQfiXrqBHSeNrLh',
  'Ops Mgmt Reviewed?': 'fldU6D0PdFOWc1q9Q',
  'Field Ready Date': 'fld40kY7aD6PY2tPS',
  'Job Ready Form': 'fld9qqCoUzsGKLuuM',
  'Drop/Ship Date': 'fldknx5JpBah8HWDG',
  'Sample Date': 'fldx3075RiIgxlC20',
  'Job Status - Internal': 'fldM8FrSyhBOj6HKA',
  'Complete Date': 'fld29KebocMxg6uP8',
  'CSV sent time': 'fldSW2BcN41ZUxrbe',
  'Client': 'fldKLv7VnIeQF02vq',
  'Turn Time': 'fldkuHoEcofmFPqcP',
  'Due Date': 'fld31aodejjxAK5BR',
  'Weather Days (Customer Approved)': 'fldAWS9RujyQ84Twt',
  'Due Date OVRD': 'fldve8kNvKxsoUZSZ',
  '# Samples': 'fldVV7joew2MQMok4',
  'Location': 'fld6Gc2UaiYHEmX90',
  'Shift': 'fldgXxYCQ7Zfz7Kkb',
  'Robot': 'fldzU6tk8J54WoriY',
  'Order': 'fldVIiwNdMIiMi3B3',
  'Dropoffs/Shipping': 'fldcdeFtQr7yJZ8oL',
  'Sched Date': 'fldqhkpU7bFTUGpUi',
  'Operator': 'fldqllLGhaHUEpG0x',
  'Reported Sample Density #ref': 'fldkIQCH9Ei8n4614',
  'Field Name OVRD': 'fldWBHHKShL458iY0',
  'Partial Mission': 'fldrsm5ezWIwC32i8',
  'Sampling Config': 'fldj3UBqiV5WGuQuX',
  'S Level': 'fldTio9mMrzo6UBOk',
  'Rogo Adjusted $/ac (from deal)': 'fldAQdGCTTgTpTq2Q',
  'Price type': 'fld7uhb2LErOL3Qpd',
  'Total Price - 1st Entity #$': 'fldl6FbslHEC7IBxW',
  '$/Ac Cost - 1st Entity': 'fldon5iBdW97CsyiC',
  '% Down payment': 'fldKUaNkMHEDLbnWr',
  'Addons Text (Adjustments)': 'fldRSEliV4NxsWUu8',
  'Grid': 'fldF016Z1DLbiJlCA',
  'Depth': 'flds5Pe3BKobyXoVi',
  'Cores': 'fldkdXFB8liu7GiA1',
  'Season': 'fldX3KjZuf5DAfR1r',
  'Mission Name': 'flde8wO9SDK3Aa9v1',
  'Lab Submission Details Lookup': 'fld73tR2RHkZPBS1m',
  'Email for Results': 'fldeFdGJDwrvgnLvp',
  'Rogo Job ID': 'fldGRYiXg7f6qHf6I',
  'Deal Lab': 'fldcrhjffidLLF71t',
  'Deal Lab-Acc#': 'fld5NFc0ppdrPgoKw',
  'Lab - Final': 'fld4QmPAQfCfwqVAN',
  'Test Pckg - Final': 'fld4djSPk7dNHm7Zm',
  'Final Lab Acc #': 'fldVFifXMBrwdgCU3',
  'Deal Job Ready Check?': 'flddpNsDMJk0vDKvI',
  'Ready-2-Make Check?': 'fldciENbbm2WONM1B',
  'Mission Finalized Check?': 'fldQvANxq9GjCuHvS',
  'Last Inv Mod Time': 'fldDlqKn1LD89r1vZ',
  'Mins since Inv Mod': 'fld2eVATX2fW7Pxgq',
  'Pts Correct Need?': 'fld22vuAS6S2EFZo2',
  'Pt Edits Auto Sync?': 'fldXHUwJMlppfzeFT',
  'Most Recent Job Cr Date': 'fldCe48awVOSyTqm7',
  'Most Recent Job p Field': 'fldyvwoJEHpm3rSip',
  'Most Rec Job Status': 'fld1urUHmfGFaCCtU',
  'Field Ready Date - Post Submit': 'fldAuDB3AA5j92Yf9',
  'Submitted via Form': 'fldp4Sqw5PpNDiHku',
  'Freq of Add-On Test Pckg': 'fldcS0KrXBp7cbbWB',
  'Final Freq of Add-On Test Pckg': 'fldgdcvAlcCXL13Pe',
  'Data Sync Check?': 'fld89TVB493e5EQRU',
  'Sync Method - Deals': 'fldJrTNu2Zwdm8b2I',
  'Late (days)': 'fldCAn7y76Nb1r8Ze',
  'Deal Service Check?': 'fldgkgIUJc1sb5aGl',
  'Deal SS Specs Check?': 'fldvaxQaWQ624YLkL',
  'Deal Sites Type': 'fld3TbYajQeArkjfZ',
  'Mgmt Ready?': 'flddqpzmrMFH9bkTz',
  'App Show Operator Trigger?': 'fldcZdE5qJrHAHTMj',
  'App Show Mission-Review Trigger?': 'fldR2yd5c44qhLiit',
  'Test Pckg - Display': 'fldd4cBYSP59iNAKR',
  'Field Ready Date (Y/N)?': 'fldbfgriytQnyVkxZ',
  'Scheduled?': 'fldiJSZa6UaKhhvbj',
  'Software - Deal': 'fldSMzHoGJ9VsK8x1',
  'Lab Data Auto Sync?': 'fldhZMmU2ise8Bfzs',
  'Billing Approval Needed?': 'fld06MvlWiJrqJmw4',
  'Manual Field Creation Submitted': 'fldsYstcO7AGGGdxh',
  'In-Field Notes #ops': 'fldakjmUJcKIouM9S',
  'Boundary Change Notes': 'fldMDDfuFUkaXUBBM',
  'Unready field upon arrival?': 'fldB09jpjtOsNLCxj',
  'Sample IDs Pulled': 'fldt1Ppg4RHmqk86j',
  'Sample Date Man OVRD Date': 'fldPf2WLmFcUdjHPl',
  'Drop/Ship Date Man OVRD Date': 'fldNiSOdJXUabYiPc',
  'Auto-Field Link?': 'fldUro3z1fKkfhclU',
  'Map Files Source': 'fldmi5z51MuUMplKi',
  'Source of Deal': 'fld4vAQKfNFSq1hwp',
  'Exe Mission': 'fldPlrn5kHyCnHGlw',
  'CSV checkin - lab': 'fldCB4kTQbHrfNaa0',
  'PDF checkin - lab': 'fldyqCslmI7EzRxi1',
  'Lab Results': 'fldRVTdjfsXnaIJY9',
  'Exe Pts Shp': 'fldVYAD0dP7084fdv',
  'Exe Bnd Shp': 'fldVrzNeggL5Xr7QU',
  'Exe Pts + Bnd Shps': 'fldWSRqptgz9IEvdq',
  'CSV required?': 'fldeFXXmr5OHd0qpL',
  'Int Boundary Acres': 'fldNCDvkolro6Igg5',
  'Exe Boundary Acres': 'fld1NNdbXwEFRU5Sv',
  'Boundary Acres Man Ovrd': 'fldfwWYco1Vc1mEcp',
  'Boundary Acres': 'fldqfKcd3xNDkpSpg',
  'Final Corr Acres - rare #$': 'fldwVo1mUN8Fr0KQr',
  'Acres Eq.': 'fldbiIYMMvNl8VW0t',
  'CSV Ok?': 'fld50Vt2LxCJCWrLZ',
  'CSV Sent to Lab?': 'fldihrB5CnU0bKPkC',
  'Pts Corr Date': 'fldvukdrbwv35V1vH',
  'Lab Results Sent Date': 'fld79McfSQFI5AGcs',
  'Bdy Corr Date': 'fldRjeS2gs4KMXL2z',
  'Acres (2.5 Eq)': 'fldA0EDxveargbjK5',
  'SS Pts Corr Ok?': 'fld9oZuOTYV1X9K0V',
  'Results Send Required': 'fldZJsrogSUmikkRY',
  'Results Ok?': 'fldv1XIjPhH8JLsDd',
  'Send Files OK?': 'fldcQdfMgCU4Dkyjh',
  'Send Files List (internal)': 'fldnzuvqHpzfAlsTS',
  'Sple Date Fmtd': 'fldveFGDG17cuSsik',
  'Ops View Job Show?': 'fldBvsVdBqAORZECH',
  'Shipping Turn': 'fldqRjtZx1xB8YopA',
  'App Shp Calcs': 'fldGIN9Yxl5U3zg2I',
  'Shipping Post Season Exclusion?': 'fld8q8ohTK9TxaMfU',
  'Results Turn': 'fldDJ0FP6JsL3vXRQ',
  'Pts Corr Turn': 'fldx2RbrOkgBKIvGy',
  'CSV turn': 'fldggbg1dPoDaLE5M',
  'Des # Samples': 'fld83DLBafw6hjX6M',
  'Tilled Field at Sampling?': 'fldHwRRbpi4fk3bWz',
  'Trip Charge Apply?': 'fldeFJ8FptFoJfdSb',
  'In Season Up Charge Apply?': 'fldumK8F8N4UGKFSr',
  'Misc Per Acre Adj': 'fldoOr6uANOseTn2v',
  'Misc Per Field Adj': 'fld2Grl8AXT4pEr43',
  'Misc Adj Notes': 'fldhJXRiWW2bCpXou',
  '$ Approved Billing?': 'fldnPQBd4qRQ2v7OM',
  'Invoice #': 'fldbKL9ztAl9FD0JO',
  'Line Size ft': 'fldNVHIlMKXx52eA4',
  'S2S Distance ft': 'fldHj2WdFBGuJcQhL',
  'Sample Date with Time': 'fldF8J2SyZHuiBpDy',
  'Path - Actual Straight Line (ft)': 'fld88iGqlDv6w2f7p',
  'Int Mission Modify Time': 'fldv6JxWO1vnvwvZ6',
  'Lat': 'fldZKoVodQFMDP7KH',
  'Lon': 'fldDjNnLFbDyTG0dH',
  'Base $/S': 'fldYmS5uMmL1k3LK7',
  'Sample Num to Acre Abs Error %': 'fld2B1OS8E8drFzOr',
  'Des # Samples Unrded': 'fldbliZcMBRvlIwuJ',
  'Sample Num to Acre Dir Error %': 'fld20X4pEiyyucQGN',
  '$/ac 2nd Entity (from deal)': 'fldr4oD8YgqSCHpRo',
  'Total Price - 2nd Entity #$ Prim': 'fldSpFlWA5JKFdl3j',
  'Client (secondary)': 'fldRqshvJ1zRYyj5D',
  'Billable Clients': 'fldWnUM6avEaTX0gr',
  'Invoiced Clients': 'fldIUdVl1CllEqv8Q',
  'Qty QBO Sent Invoices': 'fldaRsjap7AXWxNqe',
  'Angle from deal': 'fld9ZkEnh2JcrpCXu',
  'Final Angle (CCW from E)': 'fldOzlSL7ILMxOCou',
  'Acres error': 'fldZyltnJH75rmIGU',
  'Trip Charge Potential #$': 'fldZBvq7trTn9Ix3E',
  'Major Boundary Collect Potential #$': 'fldvN8jFKV8pSrr49',
  'Tilled Charge Potential #$': 'fldZqHqelBuo4EzJq',
  'Minor Boundary Collect Potential #$': 'fld48j4CBm5eFEX3x',
  'Trip Charge $/field - #$': 'fldriiBbBaf5HXXUi',
  'Major Boundary Charge $/ac- #$': 'fldSNRxeQlNm4Ws3b',
  'Tilled Charge $/ac- #$': 'fldWKqvlsWtuWAC11',
  'Bdy Change?': 'fldV9cbofVt6AWPrP',
  'Minor Boundary Charge $/ac- #$': 'fld5bEsJm0zj6ks4h',
  'In Season Upcharge Potential %   #$': 'flddVfn9wLsWvIkeu',
  'In Season Upcharge $/field   #$': 'fldJTuuKZYK05YxCR',
  'Base $/Field (from deal)': 'fldxHLnNiUUCnACqU',
  'Cancelled?': 'fldCV5Jxbl0jRWlwI',
  'Addons Per Field Subtotal #$ Prim': 'fld6wpDReBIzaFTcJ',
  'Addons Per Acre Subtotal #$ Prim': 'fldnD7XcvHhDNlUK1',
  'Total Addons Subtotal #$ Calc': 'fld2DP53v3vxM2qow',
  'Base Price Subtotal - 1st Entity #$ Prim': 'fldqpW4C4dwLQyiQ4',
  'Base $/A - 1st Entity #$': 'fldrcrpYJKxpQ04IW',
  'Deal Sampling Type Special': 'fldYlB2zb3n283VqE',
  'Instructions for Field #ops': 'fldDPVATSUBg1kiX3',
  'Sampling Type Final': 'fldJve5JX31SBygk6',
  'Comp Acs Bdy': 'fldzvc3ACwiLyWTcs',
  'Comp Acs Equiv': 'fldkVEpWqRPGEDIvE',
  'Ac Completed for Deal Rollup': 'flddG5ioPKAFZVWUG',
  'Branch/Location Lab Acc #': 'fldXkIU7jdqcWvrt0',
  'Submitter ID # link': 'fld7IxKpSxy4IMYzw',
  'Submitter ID Final': 'fldybMGzS7WPibcav',
  'Grand Total Price per Job - #$ Calc': 'fldbcIr0pMMSc9O7l',
  'Invoice Needed for job?': 'fldmnColkOrcT772y',
  'AB Line East:Zero Format': 'fld35D7kP1rAfkow4',
  'Test Package Presubmit Type': 'fldTGK0HLBF6J0qnk',
  'Bdy Correct Need?': 'fldoeqxkbEu0o6q0m',
  'Bdy Auto Sync?': 'fldQYumK39vanMMvF',
  'Bdy Corr Ok?': 'fldE95NLYd328WR0L',
  'Bdy Corr Turn': 'flduVOpV5v9mBqYZ9',
  'Perp AB Line East:Zero Format': 'fldEstF9pgSnp2Rmk',
  'Lab Shortname': 'fldy8cOLj4nF8Tv0r',
  'Pre-Formatting Instructions for Field': 'fld6oQZXtdOGdCt1H',
  'Pts Created/Edited?': 'fldu1tsf1Qx54sRIZ',
  'Cred App Sys': 'fld6vTVis2GuKIqbD',
  'Cred Transf Sys': 'fldY8XsS1w43KRsnz',
  'linked shipping jobs lookup': 'fldZkVLwVZGDQS7aZ',
  'Boundary Acres Match Submitted?': 'fldgOaxh90sgbPSir',
  'Deal Pattern': 'fldDfWH6Zw3t7A8Ki',
  'Deal Cores': 'fldtdECFHraM60Iux',
  'Deal Pattern size': 'fldyDUtQDfLWVmaP5',
  'mapcode (auto)': 'fldtya1EnjzUSQW4L',
  'Mapcode (2)': 'fldBqKzW4gBJt3zmj',
  'Client original name': 'fldtBeXQdkOnCVgu0',
  'field name - last modified time': 'fldt93SzZQYmEwQkK',
  'Deal Submission Process': 'fldrNJ6gjYXB81XbZ',
  'Nematode Requested from Form?': 'fld2fOtyfeU5BkwVs',
  '3rd Party Season Description': 'fld4kkP5MlTxELRYV',
  '3rd Party Freq of Micros': 'fldbZ3yNjZDH8gXcB',
  '3rd Party Crop Description': 'fldwQC0IeVmjccRLH',
  'Bnd Text Formatted': 'fldSsCP2pniSKe9aK',
  'Last API scan timestamp': 'fldsgUZT9f58ACr3z',
  'Submit Notes + Job Ready Notes Combined': 'flde2IEoiQen6h6dU',
  'Lab Pts Edit Sync Method (from Deal)': 'fldRxc5ZhQcmybDSb',
  'Bdy Approved for Send?': 'fldly7IddjwscrknZ',
  'Bdy Edit OK (Lab Auto-Send)? (approved or no change)': 'fldjdSfhKzI4yn5NH',
  'Edits by Customer': 'fldXgQRLsKw7S9l2u',
  'Handsoff Pts Corrected?': 'fldVznxoAbKQFAW9v',
  'Handsoff Bdy Corrected?': 'fldcGbETKoUI1sedO',
  'Pts Corrected Fully?': 'fldeWaV1ZiPlCxEW8',
  'Bdy Corrected Fully?': 'fldGma0yzKmNPXcbx',
  'Pts Corr Date OVRD': 'fldp5jVFDd3gR7GJk',
  'Bdy Corr Date OVRD': 'fldVokssVWfJV6BV4',
  'Handsoff Job?': 'fldFR5pKnasyl4iz5',
  'All Shp Files Corrected?': 'fldwnYxcvzuMt7UDz',
  'Edit Files Complete Date (latest)': 'fldRHJW5tLZxl0JuZ',
  'Acres Short of Commit for Company': 'fld71bJm2I4DZUHED',
  'Boundary Acres Error %': 'fld7o17fo8yZA2ICs',
  'Credit Left $ for Company': 'fld6am54SHCFnOzRg',
  'Job Submission Process Lookup': 'fld9rOJFvTOPNoT1M',
  'Active? Lookup': 'fldnOgIbCDettbuev',
  'Lab Email for CSV Send Lookup': 'fld3hzJtDpn4M9I6z',
  'Email': 'fldRBjnDbKV9oeA6e',
  'DM Email Lookup for Addon Charge Reminder Send': 'fldINrMp9DMNvRdu7',
  'DM Email': 'fldBBqw1nF4lIhmx6',
  'Bdy & Samples Approved?': 'fldXYitL6cPSDoj5h',
  'API Submission Required Fields Met': 'fldh5iK7YhkJvdaOa',
  '# of Stops': 'fldVw88IKj7oZLQJY',
  'Rig #': 'fldjDPnX9emtPIeo2',
  'Drop/Ship Date with Time': 'fldl2HPG0FohnkLr5',
  '# of Deals': 'fld5sEAWAxVIh0ILR',
  'Flagged for API Reimport': 'fldLvmbmgjdJoMMPi',
  'Drop Location': 'fldcnd02ixQLbBFx6',
  'Drop Hours': 'fld1EgkXLzQKCZ4eb',
  'Creation Date with Time': 'fld9oN9Mp7xXGuf0n',
  'Paid Bdy Collect? Lookup (Deal Level)': 'fldeqNw9zi3851tpV',
  'Field Discretion? Lookup': 'fldy1O2SBrto6h3HX',
  '3rd Party Map ID': 'flddma1RKRtAKMKHV',
  '3rd Party Legacy Lab Submission ID': 'fldqdyD1a26YqwpPE',
  'Customer Priority Lookup': 'fldb45XdoedPax6Jb',
  'Frozen Ground OK? Lookup': 'fld2rnI87v0YBEyHY',
  'Shift Last Mod Date': 'fldBNm3hIzQMs9TMe',
  'Field Ready Date Error Checker': 'flduZshwSwVsgP8Mc',
  'Ready Before Creation': 'fldsHBY6ePJ0Kwc9B',
  'Crucial Dates Hours Dif': 'fldajXev8lXoLG2VJ',
  'Major Hours Dif?': 'fldt84BfxbdXq3BUL',
  'Job Ready Before Creation? (Flag)': 'fldzVHhRIrWVPGyDL',
  'Sampling Turn': 'fldj2k0Trb0zBreMJ',
  'Sched or Sampled?': 'fldfhaI1oHTpv9wjL',
  'Last Mod Field Ready Man OVRD': 'fldH8pT7y1U7wLZhD',
  'Ready Before Last API timestamp': 'fldbiI7hH43jXcJJb',
  'All Exe Attachments?': 'flddNLaY5y2b3ir6l',
  'Exe Mission?': 'fld9kaXkqPTGgn89Q',
  'Exe Miss Last Edit By': 'fldyWlC5Nd2TC0qtY',
  'Sample Date Edit By': 'fld68NLxZmEcT77pK',
  'Late Acres Weighted': 'fldxNlWBw9izCLhRn',
  'Client Job Acres Ready & Unsampled': 'fldXDegv0dmjPnzl0',
  'Sample Date Last Mod': 'fldYBsq1JThJ1VSnV',
  'Hrs Past Start of Shift (0-24 target)': 'fldE6dcbPm1qRSgXY',
  'Sched Date with Time': 'fldmBX9GD4WnzGQqu',
  'Sample Date MAN OVRD Submit vs Value Difference': 'fldxo4mpg0GcsVg5X',
  'Clockify Shift in Progress?': 'fldeT0O6qFIZ7ruKj',
  'Operator Email': 'fldwb2oq0PhFskVkh',
  'PDF order type': 'fldU1PgfkzYVyH0Gw',
  'Deal Density': 'fldgkIxofZHPmc7Ck',
  'Barcodes Pulled': 'fldL1pPqo5repDQbf',
  'Mapmaker Reviewed?': 'fldpNcIe3OubC6cFg',
  'CSV Sent Man OVRD': 'fldEf5C4RizyJJcRE',
  'Bnd GeoJSON': 'fldoKhuY3TTvXwb0X',
  'Operator Name': 'fldWtPVUx66NQhoWj',
  'Grower': 'fldg7bu9UFRTq9n61',
  'Grower Duplicate Warning?': 'fldTGoqGtoZftA2l1',
  'Exe Grid Polys Shp': 'fldwJzCEFPQlQ1u39',
  'Job Notes Reviewed': 'fld69BAM2TKr2BVa4',
  'Scheduled Time Check': 'fldiwqVAAQ1BiEu2K',
  'Lab Primary Delivery Method': 'fldY5H9knCRiItofS',
  'Lab Address': 'fldXMWwjomUMBmx3F',
  'Box': 'flde8C9GxH70S7R1A',
  'Dropoff Name': 'fld7Bgth0plrlIiG8',
  'Dropoff Lat': 'fldxGLJoUlG62xSee',
  'Dropoff Lon': 'fld25DPsVD5TvnqXn',
  'Major Boundary Collection?': 'fldO0ufjOxgG7y0GF',
  'Sample Date (eastern time)': 'fldGFh3JsQxKFasOI',
  'Samples Omitted? #code': 'fldf6eruH0QC2QdIX',
  'Exe # Samples': 'fldowJKj3uOJELAqp',
  'Priority Final': 'fldcvh8JbL8E1FAAD',
  'Process Exit Interview Files #code': 'fldZ2Cdm92VL5UDzQ',
  'Skipped by Operator Time': 'fldtHf5F6ldBZm9Jj',
  'Map/Data Contact': 'fldH6GR59StK5PhXm',
  'Review Mission? #code': 'fldAXrN8o3XNe9mbc',
  '# Samples Missed - Exe vs Int': 'fldlffGHaOPEjUSuS',
  'Box Ship Date': 'fldXfNXyq6f6YbsDN',
  'CSV file last mod time': 'fld4Vv1Miga9chW0M',
  'CSV time since attach in mins': 'fldFajD9Nzi5IGJIa',
  'Field Ready Date #code': 'fldDrW2307SEJN8NO',
  'Billable = Invoiced Clients (Length)? #STATUS': 'fldS3OSA2aX8wUGgG',
  'All Invoices Sent and Linked? #STATUS': 'fldfI1e9PLMsqLkBY',
  'Immediate Job Creation Notification Email Sent On #code': 'fldl1gwY3cjuNpRcg',
  'Deal Backup Emails for Immediate Job Creation Confirmation': 'flduPqcEWX4PXk5Yz',
  'Deal Master Emails for Immediate Job Creation Confirmation': 'fldH3SiZQrBqABZrf',
  'Deal DM Emails for Immediate Job Creation Confirmation': 'fld78Ik8PW939tIvu',
  'Branch Emails for Immediate Job Creation Confirmation': 'fldpkbrXAvhxO2iOi',
  'Immediate Job Creation Notification Email Recipients #code': 'fldvLD97n0XTFY7gL',
  'Has Immediate Job Creation Notification Recipients #code': 'fldXX2AhyobxHcG97',
  'Immediate Job Creation Notification Recipients Actual #code': 'fldTA1v0W6F7rBeqA',
  'Box Linked?': 'fldUU9UiGfvJB8Dob',
  'Drop from shifts': 'fldA1vWKw8seKma6V',
  'Dropoff Link (from Box)': 'fldyrgkGeOhuBmUqC',
  'Merged Input Pts Shp & Bnd Shp': 'fld5Sc2vB84tnVvsD',
  'Due Date with Time': 'fldFMaE619QS4VCKz',
  'Sampled Acres Per Week': 'fldsUIrzGbYQfXmQL',
  'Immediate Sample Job Complete Notification Email Sent On #code': 'fldciVr1OpOfaXeb5',
  'Immediate Sample Job Complete Notification Recipients Actual #code': 'fldbWn7v9FOc5RmSa',
  'Int Miss Edit (Man Corr)': 'fldBFpRAPf7XeKZ0n',
  'Pts Edit (Man Corr)': 'fldpuXjWzRIRKXWFx',
  'Pts Edit (Auto Corr)': 'fld1PV9vcmNssZmm8',
  'Int Miss Edit (Auto Corr)': 'fldNTPm7T855ZC2pZ',
  'Pts Edit Type': 'fldns1jlkjFwuOXRT',
  'Immediate Customer Files Changed Notification Email Sent On #code': 'fldT5PFYFLj0pVeFO',
  'Immediate Customer Files Changed Notification Recipients Actual #code': 'fld4SHYZPVvNNLX0i',
  'Customer cares if Sample IDs change?': 'fldJRrVRaMoYaQrUm',
  'CSV corrected checkin - lab': 'fld3pmUCziggHliHe',
  'map of submit vs exe': 'fldESk4Gn88lO56mN',
  'Pts Shp vs. Exe Miss Edit (Auto Corr)': 'fld9v3AoRzuTM7SlI',
  'Pts Edit Type (Int > Exe) (Field)': 'fld6zl3hugcQbZQdY',
  'Pts Edit Type (Shp > Int) (Mapmaking)': 'fldjVRcjr7WO5rV2P',
  'Map of submit vs exe file': 'fldhOWliNzCExufLP',
  'Customer Files Change Approve Send': 'fldOcrTX5N4oopkwN',
  'Pts Created (Auto Corr)?': 'fldsgRrhoD2En3mNH',
  'Exit Interview Success #code': 'fld6t0ESxrD2qMR7m',
  'Exit Interview Success Last Modified': 'fldYgSYA6s4oIIzMZ',
  'Mapping Needs Review #code': 'fldXZXq2qVEOLu6cO',
  'Image map of submit vs exe': 'fldGzX15ZD2iN2S5z',
  'Deal Status': 'fld2O3D0PtEGrvsp3',
  'Exit Interview Last Ran On #code': 'fld7kAWklmz6Yzgqm',
  'Needs Confirmation': 'fldkBCOrMTZ8Et4IK',
  'Immediate Customer Submission Errors Notification Email Sent On #code': 'fld6659CLidutV9JE',
  'Immediate Customer Submission Errors Notification Recipients Actual #code': 'fldCSEsW96Q8HLfLQ',
  'Customer Submission Errors Notification Email Approve Send #code': 'fld5WRVxq3LDpCVTu',
  'Re-run Job Checker for Corrected Files #code': 'fldnJLPUL8gSZpHq2',
  'Labor': 'fldfGdXBMrvyQXwql',
  'Exit Interview Bdy Ok? #code': 'fldMCLSpubtSDr95d',
  'acres mod ': 'fldpIXYsnpL7xOe6Z',
  'Season Readiness': 'fldb7rNglb0uInIfW',
  '3rd Party Reference ID': 'fldfWep7PrgQMeLxy',
  '3rd Party Metadata': 'fld8qPLNnnEthBvvS',
  'EFC Boundary ID': 'fld5kUWNDfW2SWJH4',
  'EFC Start Date': 'fld7tkB02U5ci1hjv',
  'EFC End Date': 'fld0z3fdd5xJyHzFW',
  'Dealer from Form': 'fldUnO7wWf52sU4eU',
  '3rd Party Lab ID': 'fldbYKbtG5OW4vaea',
  '3rd Party Farm ID': 'fldMBM2HhF1XaYIrG',
  '3rd Party Software Work Order ID': 'fldu60bPSQWRypJAM',
  'Grower from Form Clean': 'fldtBvNB8v6PnE0om',
  'Grower Count': 'fldqlWK8VuHk0BnE1',
  'Bdy API OK?': 'fldg4dWGvb1DGX6ix',
  'Pts API OK?': 'fldu8wuKKrKSzr9k1',
  'API OK?': 'fldncGWcPrxzF4ROo',
  'Road Location': 'fldJfbd9O8SFnTUOy',
  'Pullin Nav Distance': 'fldDEUdFMGjhZrIq4',
  'Percent Boxes Shipped': 'fldENafL7Snd6OBMz',
  'ALL Box Ship Date': 'fldYyUVaPyXuANBfj',
  'ALL BOXES Drop/Ship Date': 'fldtY21Eqp0RnJ5uA',
  'Ops Acre Eq.': 'fldnp2Rs2FxduOpor',
  'Lat Lon Source': 'fldsbHgFUMi0vn02P',
  'Exe Sample Zones Shp': 'fld261jjkiECaoYVS',
  'Box Airtable Rec ID (from Box)': 'fldxkLaNODkkyl0rC',
  'Lab Code': 'fldDxWwfJsCCFE2s7',
  'Offline Synced': 'fldY69Zhw7RM4G0ZJ',
  'Primary-RecID': 'fldmMSj8YQhUu8eIu',
  'Ops Owner (from Company)': 'fld5H66bFdPkoLU5M',
  'Offline cleared for exit interview': 'fldS08FGBOeFuQd3H',
  'Qty Invoices': 'fld9mXfT0b2hOFv5H',
  'OVRD Acres to Zero (from Deal)': 'fldt4z0ibkeEwP093',
  'OVRD $ to Zero (from Deal)': 'fldY7zNs0D2497HC7',
  'Job Customer Corrected Form?': 'fldwo1Ekyk51HgVTd',
  'Account Owner (from Company)': 'fldyokQiz8RaDXERd',
  'PDF Order Type #code': 'fldIxo3JhF2KJT6jB',
  'Exe Mission S3 URL': 'fld7h9dBAzQHJxlE1',
  'Edit Job Button': 'fldTNJn01so1MVDaC',
  'Ops Acre Eq. Bndry Adj.': 'fldWm0yfdQuqBBuCI',
  'Core Stats': 'fldM1CLkE43WkrgqK',
  'Settings at start': 'fldDD61B7gLXuXrf5',
  'Settings changes': 'fldxI1lr4te4pgUTe',
  'Rogo App Version': 'fldo3bFElMNPU0Oc8',
  'Settings at end': 'fld6uXaPaI7xjqTjz',
  'Core Auto %': 'fld9tF8qdHyRdQloO',
  'Total Cores': 'fldZqCqC4EFfMpFMK',
  'Ready vs Submit': 'fldf0VfwIaDKmdFwB',
  'Exe # Samples Skipped': 'flduhyuJ1eHwAzviY',
  'DO NOT DELETE flag': 'fldxtTk88oXoCbKJN',
  'State from Company HQ': 'fld5PegU2Vnir8l9H',
  'Archive Job Link Skip Mgmt': 'fldHy3IJU0oac8SAN',
  'Map Made Date': 'fldsXFQHrZXLB9gZu',
  'Sample Changes': 'fldapc59ctA7YIl8B',
  'Files-Valid-BPZ': 'fldBbDP3AxlbfsiGP',
  'Field Notes': 'fldhutLa47j7DGwMg',
  'Job Review Notes': 'fldUlFqiTz2wYco2n',
  'Received by Lab?': 'fldEEVMq9gXq84Qav',
  'Map Making Review Image': 'fldQ0g51PZxWDil4d',
  'Job Ready Submission Notes': 'fldvla4NHKagg8KmA',
  'Parking / Field Access Notes': 'fldgIAvfSxbHorJ5J',
  'Sampling Restriction Notes': 'fldqROJ18nVm4xz9R',
  'Mgr Notes #ops': 'fldHRctz4A9aHxV1P',
  'Files-Present-BPZ': 'flddmR7uZ2inaquIP',
  'Mapmaker Last Modified': 'fldRAPmlZzXEpDHKK',
  'Deleted Sples in Exe? (Skip Mgmt)': 'fld59P5f0RmMG9yan',
  'Skips in Exe? (Skip Mgmt)': 'fldZawces2gyhYEw4',
  'Previously Sampled Exe # - Skip Mgmt': 'fldMztfLmViy36kZ8',
  'Pulled Percent % - Skip Mgmt': 'fldemi4rnif0aPTYR',
  'Client Simple Name for Job Name': 'fldSQvL5e0EHGhcUB',
  'Required Fields Job + Deal': 'fld7hGzghZMN2HFog',
  'Job Flag Notes': 'fldl44honSadPBIvU',
  'Maps Buffer from Shift': 'fldkPmlT9EAozB8y6',
  'Cont Job Link Skip Mgmt': 'fldDiQcdyCt35xCCU',
  'Data Sent': 'fldDXF8W5mckm9cRj',
  'Sample Date vs Exe Change Date': 'fld7neGaP3T2OQ3s1',
  'Exe Mission Last Modified': 'fldTzpTpgPPapXl9y',
  'Salesperson Contacts': 'fldCYIyOcoC58FqN5',
  'Operator En Route': 'fldL1s3snwTCxEPzI',
  'Grower SMS Notification Timestamp': 'fldcj3jTp6tbCN3RD',
  'Ready Date (date)': 'fld06L4upGMgq3Gzc',
  'Probe Type': 'fldZXuV02ex6lKyrH',
  'Samples Pulled': 'fldbGs34DrgZVUwqO',
  '3rd Party Location ID': 'fldUyRnpotptsyQwH',
  'Exe vs Des Samples Billing': 'fld7N4i4Ke62SwZp0',
  'Barcode regex description': 'fldQfuNZYXdK0ILJD',
};

export const JobReadyFieldIdMapping = {
  'Name': 'fldq45dPN08Fm3x0o',
  'Jobs': 'fldWZh4gCCttcDRAb',
  'Submitter': 'fldF3eaI37caeNe67',
  'Creation Date': 'fldM2AkZY10h2ykpg',
  'Field Ready Submission Notes': 'fldq6DPlollBJO7zA',
  'Customer Priority': 'fldJfP3Ydz73y41X9',
  'job latest create date': 'fldfk0jSExXM337jF',
  'Number of Jobs': 'fldWTLK4zdEg5n2BF',
};

export const ShiftsFieldIdMapping = {
  'Name': 'fldXEUrv15pCOMwFO',
  'Sched Date': 'fld5catBIpgwi4tY0',
  'WeekDay': 'flds03wJ61EsrcOcx',
  'Robot': 'fldPSUX6a3k6Lraju',
  'Scheduled Robot': 'fldNMomMA9ghEi5HX',
  'Rig #': 'fld1rt6PJ0gw9ra0N',
  'Trailer': 'fldY4beC5MOYjNBGq',
  'Vehicle Drop Point': 'fld9COtk7yZo3G5Xb',
  'Operator': 'fldcaR4aAmVIQb61P',
  'Week Num': 'fldvjfl5wAxWB34Wh',
  'LABOR TOTAL (hrs)': 'fldlqHGxrIDDsCdEh',
  'Jobs link': 'fldzN5NCIVgyN9ox8',
  'Bdy Acs Sampled': 'fldhkQKuDv5PC4YKG',
  '# Samples Taken - Jobs': 'fldTp0oNyMQB2qjgO',
  'Field Thr (hrs) Time': 'fldwy7RNk5lggu2ZI',
  'Operator - jobs': 'fldh5KRWh91kfkkgV',
  'Curr Week or beyond?': 'fld2EQ7XuQ8IiAOPJ',
  'Sple Dates': 'fldfYTDp5nMpdLJUh',
  'Approved Ops (Acres & Hours?': 'fldhkoLzxboLr7eK0',
  'Total Shift Pot (hrs) Time': 'fldHHJsJeKegi4Dw7',
  'Lost Opp (hrs) Time': 'fldSOqemMNfszePCG',
  'Misc (hrs) #log': 'fldubxdBYT7CeZMuz',
  'Transport (hrs) #log': 'fldoGpygH4SN3nK9P',
  'SWITCHING (hrs) #log': 'fldHbRmnnsfpmJQNQ',
  'RUNNING (hrs)  #log': 'fld8gjwjqm1F4xPuN',
  'Lost Opp (hrs) - linked': 'fldtVMIAyuBMFDd5X',
  'Total Time to Allocate (hrs)': 'fldk3IreoSbX68Fog',
  'Down #Log': 'fldvhMkMIljZIojLJ',
  'Down Addon': 'fldDG1f0TBJEvJ1yw',
  'Down Transport (-)': 'fldmFNu9jMB8TuWyK',
  'Down Misc (-)': 'fld2nrmBelDOjLaxA',
  'DOWN TOTAL': 'fldS6gVy0joahbHIb',
  'TRANSPORT FINAL': 'fldNtfm43AfzFi2hp',
  'MISC FINAL (hrs)': 'fldAgScn7eLKEYriR',
  'ADD 2 WORK': 'fldxleZ9Yy4vSxtfS',
  'TOTAL WORK (hrs)': 'fldTEwAVLqNv1ALAJ',
  'ADD 2 LOST OPP': 'fldgO1DG3QZoYemeF',
  'TOTAL ALLOCATED (hrs)': 'fldmNHZdXsSoK2KZt',
  'ADD LOST (mins) OPP LINKED': 'fld1X6wIGFNLPJl7Z',
  'SPACER (dummy)>>>>>>>>>>>>>>>>>>>>>>>>': 'fldPPqn2m9iHxQbSf',
  'Running Ac/Hr': 'fldyXTvYG3Pufrm58',
  'Bdy Ac/Hr Labor': 'fld3pefj19lRgWjAO',
  'Labor (-) Down Ac/Hr': 'fldP30mgvBKx5szYS',
  'Grid Avg': 'fld1HF2qpFM8Vw7B2',
  '# Jobs': 'fldBW7QEq13R7fY9v',
  'Switching Avg (mins) /Field': 'fldFCB7SaG7kGNtuE',
  'All Transport Avg (mins) /Field': 'fldCkXlVry0syUdRI',
  'BIG TRIP OF Transport (hrs) - if >2 hr trip': 'fld3pFyvl0BYo6uDL',
  'F2F Transport Avg (mins) /Field copy': 'fldO2uIFIaL18plWQ',
  'Shift Times': 'fldZ8qPLGtGt5gs4G',
  'Active Jobs?': 'fldsBxnLZdjnEp4Pm',
  'Switching Down (hrs) #log': 'fldvO8cQKaiUx9XHG',
  'Travel Stop Time (hrs) #log': 'fld0yYR2905W8AOLh',
  'Error Notes (auto)': 'fldepS1xninCOh0Ax',
  'Total Labor Time': 'fldZo0wjdTBPYejVW',
  'Valid Acres on Shift (boundary)': 'fldnl1xQ5VqGhHqv4',
  'Uncompleted Acres on Shift': 'fld1WjtvR5RIwMKfO',
  'Operator Sing Select for Color AUTO': 'fldOerRJNHwSiNptP',
  'Operator Compare': 'fldiiipHhExdxiYE9',
  'Earliest Start Time': 'fldMlqKnr0QFte2Ka',
  'Latest End Time': 'fldYJAk5Ys6CUAGkl',
  'In Progress?': 'fldx5m38IK6fBTQ9J',
  'Approved Eng (Csv & Downtime)?': 'fldc1cs6bTSySEfp3',
  '# Time Records On Shift': 'fldSo7bRHqPphN36N',
  'Total Working Window': 'fld7TkJ8BHStP8eiK',
  'Breaks Total Time (min)': 'fldP6IdzRFrkv8qG3',
  'Operator Email': 'fldwOGBMEalMGW2E5',
  'Robot Log Window Start': 'fldaDoyu8fy5Qe6yf',
  'Robot Log Window End': 'fldQwf6n6duFvKbWq',
  'Start Time OVRD for Shift': 'fldb01SfuczcJegLl',
  'End Time OVRD for Shift': 'fldNp7dGNdvQgLyDO',
  'Shift Start Final': 'fldVDf6n0KBb3TVgm',
  'Shift End Final': 'flduY2gxaPWkQ4CvO',
  'Shift Notes': 'fld5YJpYe8PCxIoMR',
  'Total of Robot Logged Time': 'fld9qxuzFJhV0JYtl',
  'Avg Field Size ac': 'fldGhBZrK1aUSMFV8',
  'Shift Dropoffs': 'fldM8LqDUArOAOITo',
  'Operator Name': 'fldt3JeNLUnVoSak8',
  'Role': 'fldpPldgZM8AIOF4u',
  'Robot rollup from jobs': 'fldRlMUtvLCAU5X8j',
  'Is Clocked In? #code': 'fldQ5gb6bCdV6PGFY',
  'Pay Type Lookup': 'fldwcYe6eYaNXlggj',
  'Bonuses': 'fld1RnSF3eUnKsF6C',
  'Approved For Bonus?': 'fldd7Efi5y3bIzcZg',
  'Operator Bonus $/hr': 'fldo3R6qLjQ2bScYB',
  'Operator Bonus $/spl': 'fldlpixzbznL3rK2b',
  '# Boundaries Collected - Jobs': 'fldOy4rie21SziUaC',
  'Pre-shift Inspection Submit Time': 'fldezI7zFuUYAAvPV',
  'Pre-shift Inspection Submit Time (pretty)': 'fldPTafu2DnYlsAZa',
  'Labor Spls/Hr': 'fldpSVxtZEw0dnWqq',
  'Deal Sites Types': 'fldzEhwbqCqlogi7X',
  'Switching Misc. (hrs) #log': 'fldcfYu2W155xL2cR',
  'Is Today? #code': 'fldIyMC6g4HpedTK2',
  '# Samples Missed': 'fldLMNejHJbPXWhFF',
  'Inspections': 'fldv2uFFrhPfbc4VC',
  'Issues': 'fldxamtLOihBXqvgm',
  'job - lat avg': 'fldY49qMqGKxZJcTD',
  'job - lon avg': 'fldrX9foqnuLEFqy3',
  'levi test': 'fldFwWyNELejfOOSq',
  'Operator Phone': 'fld2i6nYI1eyBJOqm',
  '# of breaks': 'fldCeDihejALpfocd',
  'At Shop #code': 'fldfglLn8UxFCNtam',
  'Transport Stop #code': 'flddO8HvYTZETrGeY',
  'Running #code': 'fld0IVWrmmAwnxmTD',
  'In Field Slow #code': 'fldjNA00nyJNWzMqo',
  'In Field Stop #code': 'fldk8NrDdut4v3pfN',
  'Near Field Slow #code': 'fldFs8VCojVeMBxR6',
  'Near Field Stop #code': 'fldNna3bHZvnO4Z5V',
  'Misc. #code': 'fldeZfnRaA0OFmSFN',
  'Invalid Data #code': 'fldbmM8OHN755MCxK',
  'Robot Window Buffer Minutes': 'fldMXotYUPjFdNmnh',
  'Robot Log Window Start Buffered': 'fldgkB7Aoct2lmBR8',
  'Robot Log Window End Buffered': 'fldPrkEkAgm5DktSk',
  'No Timeline Available #code': 'flddjwGAMK5ttpn5H',
  'Running Total': 'fldDnv5FQUxaxoRHY',
  'Transport Stop Total': 'fldXxVulZTE1RG4o0',
  'In Field Slow Total': 'fldMHx1hzegZ5FCW8',
  'In Field Stop Total': 'fldws8KciElYilcDM',
  'Near Field Slow Total': 'fldmz7I0tqOlMlta4',
  'Near Field Stop Total': 'fldERBMKLugXhV6Lt',
  'Misc. Total': 'fldkDwXJweDmfgOwV',
  'No Timeline Available Total': 'fld0QNIQnVFuEpL7h',
  'Invalid Data Total': 'fldzw8Al2pGZlIZrJ',
  'At Shop Total': 'fldrKMXKk7Ekbatwz',
  'At Shop (hrs)': 'fldUBpBThpyumAjR1',
  'Running (hrs)': 'fldJwZXQcFKGBAr3B',
  'Transport Stop (hrs)': 'fldQnG7iXSlaBB1EI',
  'In Field Slow (hrs)': 'fld8ijDpDPJvtb1SB',
  'In Field Stop (hrs)': 'fldbC1e0yOECdziqF',
  'Near Field Slow (hrs)': 'fldQwbSVh9fGrH7sV',
  'Near Field Stop (hrs)': 'fldwlK3Fr6Qixu0ov',
  'Misc. (hrs)': 'fldtXwkFZDichG5Bz',
  'Invalid Data (hrs)': 'fld1cMegOSFAoqNaz',
  'No Timeline Available (hrs)': 'fldiAzczixHIHVKvt',
  'Detrailering Stop #code': 'fldCgXQA1dRP7zFLr',
  'Detrailering Stop Total': 'fldhExbKLsVBrUHNA',
  'Retrailering Stop #code': 'fldcHYkDZCvqFC4zt',
  'Retrailering Stop Total': 'fldCRe5PQX8IExDNu',
  'Transport Unknown #code': 'fldETLCckuBofGy5m',
  'Transport Unknown Total': 'fldiATXDIwBhwhsJM',
  'Transport (F2F) #code': 'fldECQk0zHQasSuGV',
  'Transport (Macro) #code': 'fldIygqLjO0IMvFdX',
  'Transport (F2F) Total': 'fldRh9bXl1RadP3Om',
  'Transport (Macro) Total': 'fldsiIeyXBn6eCoZ4',
  'No2Low Acres Reason': 'fldHCo8hTdBIXnMlK',
  'Labor Calc From Shift Times Changed Recently? #code': 'fldOyDHgEM3PYCIst',
  'Robot Window Calc Last Modified': 'fldQoDKNwjzYxrZwK',
  'Robot Window Calc Modified Recently?': 'fldGqo6ElJzBFzvEI',
  'Timeline Airtable Sync?': 'fld9t2VfOj52lhzkf',
  'operator hire date': 'fld9AuNcYxx81wQee',
  'Operator Is Active? #code': 'fldTTD71USww4B8Ic',
  'Transport Macro (hrs)': 'fldKQSmJMjGUjeOY4',
  'Transport F2F (hrs)': 'fldZMwnxhoNxmhGcl',
  'Transport Unknown (hrs)': 'fldaql4tpKlM1LgTq',
  'Detrailering Stop (hrs)': 'fld9nsBt83F3ltcyI',
  'Retrailering Stop (hrs)': 'fldWt18GRhDaNjtWr',
  'At Field Downtime %': 'fldK8EXNNMg6GQ9GV',
  'Acres/Run hr': 'fldR77k8rYjal3iVk',
  'Acres/Run hr (adj)': 'fldkiq8Z7CRKFV1eA',
  'At Field Downtime (hrs)': 'fldAObmWZ4GwEUl5z',
  'Transport Downtime %': 'fldY2EM8FCGZSLqtT',
  'Transport Downtime (hrs)': 'fld0X1E09w16phQp9',
  'Shift Is Active? #code': 'fldEuqAwvIM8XXMXv',
  'Other (hrs)': 'fldsXDYR12iIetk6t',
  'OVRD Trans. Dt.': 'fld1zEGMjJsDmFlyn',
  'OVRD At Field Dt.': 'fldurJP8CVmAvpPLL',
  'FINAL Trans. Dt.': 'fldw2D9qvFGsw7kjP',
  'FINAL At Field Dt.': 'fldrhpUraHgz2DAB7',
  'Total Day Down %': 'fld2BVikcGyHjCvJT',
  'Poor No. Shift': 'fldTPWnX3uImtEoRR',
  'Allocator Transport Macro': 'fldbJ2jkpGaB80ysH',
  'Allocator Transport F2F': 'fldYVpX1rwGkLmLBV',
  'Allocator In Field Stop': 'fldhTRvJhnmTyOHnE',
  'Allocator In Field Slow': 'fldbt0WDODW6WdWhY',
  'Allocator Shop': 'fld65mn8xfWh31gJ3',
  'Transport Uptime (hrs)': 'fldqlsewxRrLwW9Ry',
  'At Field Uptime (hrs)': 'fldTe04eUqQwLKEAi',
  'Shop Downtime (hrs)': 'fldVaPRcbV475VnW6',
  'Allocator Transport Stop': 'fld8cjopRSR9qdU3L',
  'Allocator Near Field Stop': 'fldRZ29T48HjDQdcN',
  'Allocator Near Field Slow': 'fldPp4PCzE0l9eTiS',
  'Under-Clock Transport Stop': 'fldMNW8wLtBavt92d',
  'Under-Clock Shop': 'fldQY16t4c3VueFnP',
  'Under-Clock In Field Slow': 'fldFu9qgqxXdlbDlx',
  'Under-Clock In Field Stop': 'fldn6pt2MOw5DcXIe',
  'Under-Clock Near Field Slow': 'fldFpmgw3sw5nXYin',
  'Under-Clock Near Field Stop': 'fldx4u0MHM869Xq8y',
  'Under-Clock Transport Macro': 'fldIiB2qyqklyU63f',
  'Under-Clock Transport F2F': 'fldKAx1x05aKGg4sy',
  'Under-Clock Shop DEDUCT': 'fldFLFXPIvrq1NTJW',
  'Under-Clock Transport Stop DEDUCT': 'fld59qtXNMymGtHfG',
  'Under-Clock In Field Slow DEDUCT': 'fld1K4FogorLVY34Y',
  'Under-Clock In Field Stop DEDUCT': 'fldznAlpPSrNMvTF0',
  'Under-Clock Near Field Slow DEDUCT': 'fldC4yjEepA6G6ZXd',
  'Under-Clock Near Field Stop DEDUCT': 'fldGkLYTcUaasL8XL',
  'Under-Clock Transport Macro DEDUCT': 'fldNcTpjbxR6g2eYQ',
  'Under-Clock Transport Unknown DEDUCT': 'fldxO8f2ERRKP8e0Y',
  'Under-Clock Transport Unknown': 'fldSwgjnntmTGo1lT',
  'Under-Clock Transport F2F DEDUCT': 'fld9xgKMI7pyfM4GI',
  'Under-Clock Other': 'fld3mjSykwITPl3UN',
  'Under-Clock Other DEDUCT': 'fldHakRlh37DGRIOH',
  'Allocator Running': 'fldO0Gb3caPT1PgPG',
  'FINAL Running': 'fldnyyXIqDLP0rThb',
  'Shift Is Active Scheduling? #code': 'fldy8oIZ4eUUEtQxE',
  'Create Date': 'fldJknxvAwfzbf3sB',
  'Ops Acs Sampled': 'fld1kok5FALbYjsgo',
  'FINAL Ops Acres Eq.': 'fldvYZDTwzZnnVs8P',
  'True Ops Acres OVRD': 'fldYODJUu0irdagF9',
  'Zero Acres #DO NOT DELETE': 'fldPjhynLm0FNMRYd',
  'Primary-RecID': 'fldQ0h4juPEr6F2u3',
  'Job Prim-RecID Lookup': 'fldghetNDhH1xJ53Z',
  'Bonus Labor OVRD': 'fldY975et27HVMQbv',
  'Bonus Labor FINAL': 'fld8DHupOo0tYtfOj',
  'Ops Acres Eq. Bndry Adj.': 'fldrZX9fpXdegV7tp',
  'Final Adj. Acres Eq.': 'fldsZmeg3J5JIVJ2y',
  'Total Acre $': 'fldbhTbc4B8Bs2Lg8',
  'Clockify Projects': 'fldELeyfdlFi5xTQX',
  'Sample Density': 'fld8g9PaSHHa6PmTn',
  'Avg Cores/Sample': 'fldVu9rPMEkrDScqc',
  'Cores Completed': 'fldSHljAHLk5G1P2P',
  '# Exp Territory Jobs #manual': 'fld0aebVw5KwBUnjP',
  'Exp Territory Job %': 'fldrfKcY4BKLevlmQ',
  'Ops Ac/Hr Labor': 'fldbthRLbvMdmbKWz',
  'Region (from Operator)': 'fldErmfp8LQUAbmCF',
  'Project OVRD for Shift Times': 'fldB0auwbCQ4aufZy',
  'Pre-Shift Inspection Check?': 'fldNtsJ0sS9yQHpFr',
  'Equipment Text': 'fldZAbFdeQQTYvsOV',
  'Maps Made & Not Sampled': 'fldIDgubBed58G3b3',
  'Unsampled Min Job Order #': 'fldDVYDMe6UwYNFaR',
  'Unsampled/Unmapped Min Job Order #': 'fldLt5eSqZr6yquvc',
  '# Jobs Sampled': 'fldhsmlLREk6MC3YV',
  '# Jobs Unsampled': 'fldfr35Yjxf4ZTwzv',
  'Unmade Maps (and Unsampled)': 'fldbSMLtYkY9RA8pA',
  'Unmapped Min ID Final': 'fldrbgMyLqxyjhl0D',
  'Unsampled Min ID Final': 'flda3y2gi5aqoPQv6',
  'Bad Start/No Start': 'fldWfSJp4M4jbFOMR',
  'Order IDs': 'fldZ5KbZZ7EdojCFP',
  'Maps Made In Order (for Buffer)': 'fldhUevoF0j1P41UD',
  'Final Maps Buffer': 'fldhPYBlBrclz7UQg',
  'Maps Buffer % of Unsampled': 'fldvhDCWEcHb0g6mZ',
  'Labor Clocked?': 'fldzKE7S8JY0OaohU',
  'All Maps Made?': 'fldFv3nx1uITe3fgV',
  'Sched Date Formatted': 'fldS9LhBcxo9tfhWF',
  'Sple/Sched Mismatch': 'fldJLPBPYd1TxhGLx',
  'Total Spling Shift time': 'fldMNl7SVpRpvENuz',
  'Shift Flag': 'fldUhlsmlbXSSRE1u',
  'Shift Flag Notes': 'fldioiflLbGD5lcoZ',
  'Revenue / Bdy Ac': 'fldkT2aCspfYL4MQn',
  'Acs Billed': 'fldsXBpog6HVCR8YX',
  'Client(s)': 'fldt8pgxxYsPCXwgP',
  'Final Projects': 'fldjejuV8Nex2Uw1u',
  'Active Team': 'fldFry6MRvQYDvo4v',
};

export const ShiftTimesFieldIdMapping = {
  'Name': 'fld1aJslCDSUma7jG',
  'LABOR': 'fldcpUzU4gVsJdrDD',
  'Duration': 'fldtuyIq77YKncxBt',
  'Description': 'fldpBJ3pjWPxiCA1d',
  'Status OVRD': 'fldVbjozXEeqJcNNE',
  'Is Deleted?': 'fldWYdq8kR96aJ1Ex',
  'Clockify Project': 'flds4lliJJ0hXn5OW',
  'Start Time': 'fldYh5S8T4bgZfqMn',
  'End Time': 'fldaEyHQSFOgFUdGg',
  'Clockify Entry Was Split? #code': 'fldyuufO2rD7wCtgl',
  'Project OVRD': 'fldG2m96vT7H95trz',
  'Final Project': 'flddGV2Cz1ptkf3O8',
  'Start Time Final #code': 'fld5x3JboMU1W9Gyt',
  'Start Time OVRD': 'fldKdzO2ni2040fS8',
  'Paid?': 'fldFpT6AeXJSXR88X',
  'Status Final': 'fldZXmskImGEnDUer',
  'Team Link': 'fldPvnJ6apArGXgFM',
  'Team Link OVRD': 'fldhoLAHjlhIIO9Nj',
  'Final Team': 'fldDk8VSiM9FbBdEp',
  'End Time Final #code': 'fldrBy2VaXMxM2ANb',
  'End Time OVRD': 'flduRh011hRDtpvwC',
  'In Progress?': 'fldWw0DjOntQXI2Y1',
  'Shift Sched Date': 'fldB3OGTEe1ooBKC1',
  'Operator (from Shifts)': 'fldakI4bS4hoHbFWj',
  'Clockify UID': 'fldO2GxBkoTsVLfDq',
  'Link to Shift': 'fld575xXejY63qfK4',
  'Rig (from Shifts)': 'fldW53VNUzNpMghVA',
  'Rig # (from Shifts)': 'fldbKQROL2oypf0cU',
  'Cancel Updates?': 'fldKwjKmLWO56WPts',
  'Ops Hrs Approved?': 'fldiVG67SEnDBxbQX',
  'Sampled Acres - Job': 'fldwX21qztchfhWNE',
  'Samples Taken - Jobs': 'fldG1MEfNhIqABnUO',
  'Robot': 'fldwUSUI08eVRjvh7',
  'Week': 'fldD4DSpo4JjmfLNS',
  'Date': 'fldObagGgULDjxdDz',
  'Valid Acres': 'fldX1Dc4qNexgSKPF',
  'Uncomplete Acres': 'fldbcSuyiRKo3YPk6',
  'Role': 'fld7a5j1IdtVniBBO',
  'Team Member OVRD Link': 'fldBP9VVp7mT5x1v5',
  'Team Pay Type OVRD Lookup': 'fldfHxpDmZalc4wyC',
  'Week/Date': 'fldaoMM2oU1qjNaST',
  'Clockify Pull Update?': 'fldN6orzmSqlJ11tx',
  'Final Team Member': 'flda8B37U5Rp8aXXD',
  'Team Pay Type Shifts': 'fldqz34I6bYXdSFTT',
  'Team Pay Type Final': 'fld1OffbCt1sCIgyU',
  'Shift Notes': 'fldKZL4429vp5MkcZ',
  'Rate Type': 'fldhIPOxpf245eqXv',
  'Rate beg.': 'fldcMWhrVpTfHlqxZ',
  'Raw $': 'fldZyMwnpPg0xwFPu',
  'Bonus OVRD': 'fldb4stZ78Utprihf',
  'Raw $ Final': 'fldT2I4DOY1U43Cv6',
  'Rate Type OVRD': 'fldFYjzHWGCMT1aRg',
  'Rate Type Final': 'fld6Y6Ue3HiXmaMYQ',
  'Regular Rate': 'fld6IZBD9Fh0Jqv0k',
  'Sampling Rate': 'fldPVPplhTdTxz81K',
  'R&D?': 'fldn9gZ7CrdOb30yr',
  'G&A?': 'fldmPX93nMgIvhcei',
  'Ops?': 'fldbtRe9XAjMemhNn',
  'Trucks': 'fld0qMmToHXHo88pL',
  'Rate OVRD': 'fldFMs0JH78zvHthe',
  'Rate': 'fldiw9mk57bKDCGfM',
  'Created On': 'fldRXiWSUxFASnUe8',
  'End Time Last Modified': 'fld9YfFCJlsUAp0G8',
  'Labor Calc Last Modified #code': 'flddsMhhchl2xUcIl',
  'Labor Calc Changed Recently? #code': 'fldMwMGAdmQTlTEpF',
  'Labor OVRD': 'flduoouYjGfR1v6bu',
  'Grid Avg': 'fldGZjz7zWUbOWavh',
  'Last Mod of Times': 'fldrArnO3fCTWhvYS',
  'Last Mod Approved': 'fldSB0b6MaVr3qaFT',
  'Calculation': 'fld2J071AXBPLThQF',
  'Last Mod of Times BY': 'fldnSyqxQEqcx5tSs',
  'Project OVRD from Shift': 'fld9MwJOHs2nwlgnD',
  'Shift Time Total': 'fldAe0qLTDN6rvfSN',
  '% of Total Shift Time': 'fldohYTLkPlc6y8lU',
  'Month': 'fldlwcTkARdPeQ7Y3',
  'Year': 'fldv8h9Y1nMy9xavX',
};

export const RobotFieldIdMapping = {
  'Name': 'fldDAtaL8x9UcuX2I',
  'VPN IP Address': 'fld67NkqRRoNpsbGM',
  'Current Sample': 'fldRLEkhiNBvksYeS',
  'Robot State': 'fldiEqgyh1eF2SG2k',
  'Lat': 'fld6SJO1at3ZJhf4v',
  'Lon': 'fldgvfxNQOpmUx0BB',
  'Location': 'fldcYJ6TY6ZrHmhDV',
  'Hrs Since Last Update': 'fldv2p2Gagk5CjZgX',
  'geocode cache': 'fld2W97qOvpJCHC0X',
  'Current Job': 'fld6vuLuIHFTMWEyY',
  'Last Update from Robot': 'fldGOBPUPBphv3Dxe',
  'Previous Job': 'fld6U54DYlZU7QMfn',
  'Location Tolerance (Miles)': 'fldfzHVIOiSdQpr5U',
  'Polling Interval (sec)': 'fldqPPrzIuUO248gE',
  'Shifts': 'fld7jYN8iSyWyeFgH',
  'Rosbag Size (MB)': 'fldmRBjEcrKcQvcyC',
  'Heading': 'fldiBizyZ2Jaer85b',
  'Active': 'fldGBbdkQZBoJwpjA',
  'ID': 'fldYfetOwWZYWufoZ',
  'Anydesk ID': 'fld3LUvTqlw6WAmbn',
  'No Late Alert': 'fldeZO35YnoZHy8dO',
  'Machine Type': 'fld9iq3RwMvUrC4nN',
  'ESP Installed In': 'fldljmrwDid5fW5Uv',
  'ESP/Robot Mismatch?': 'fldASwsRTCOEI9oEh',
  'Position Last Update': 'fldvu4XlRqXjxBab5',
  'Operator Today': 'fld0vKo65WI3rccg3',
  'Rig Today': 'fldtukb6W9ntKHSVe',
  'Shift Prim-RecID Lookup': 'fldjm8OoBFCzShvqw',
  'Settings Password': 'fldFuWWyWd2Atfa8S',
};

export const DropoffsFieldIdMapping = {
  'Name': 'fldQKP4DwxOdCj0jX',
  'Labs Used for': 'flddPBq2ctBLa4bZ9',
  'Labs': 'fld0E7BGv42A3XHhC',
  'Type': 'fldRBsBT4cKZqLdV0',
  'Dropoff Lat': 'fldsmZeNWdSYY6TPI',
  'Dropoff Lon': 'fld7lC5QhVajKuxj7',
  'Schedule': 'fldA95MduuXQJmSeb',
  'Display Sub-Name (Location)': 'fldCNgvwTZyjhLiJg',
  'Location': 'fld1agOkawNpHUz7H',
  'Nickname': 'fldm0tGXaDPV1RadM',
  'Client': 'fldaVZBq9RAWeJPXX',
  'Phone Number': 'fldn2F8Gv5dERzVdq',
  'Drop Off Notes - Ops Mgr': 'fldA4Af41V9L73aYl',
  'Active ': 'fldYCFlg3QQdoL3d2',
  'Drop Off Box Locations': 'fldDnekWIYGhDAXTd',
  'Box': 'fldnyKZ38zRPCq7DO',
  'UPS vs Other': 'fldwsiT6oK5rDV47h',
  'Pickup Scheduling NOT Required?': 'fldv8aGaDmlvv4gST',
  'Courier Pickup Link': 'fldFp9CtSt8CB39G8',
  'Courier Pickup Form': 'fld5DR5NxfzLuP7E4',
  'Last Box Pickup Time': 'flddvy6rabKQIrnh0',
  'Lab Specific Pickup Form Link': 'fldENCxjH6SH11ocf',
  'OLD - Contact Person': 'fldJbZ3qDeywh0Oqh',
  'OLD -  NAME': 'fldVaVcESuHKFA1Nj',
  'OLD - Jobs using this dropoff': 'fld9sGYbIvkKvXWF2',
  'Shift Dropoffs': 'fldanQ9E2KwFEKite',
  'Last Box Ship Time': 'fldiYUgAKin47swtx',
  'Geocache': 'fldDZOT1M1Y3yL2Yj',
  'ID For Labs': 'fldbJ7Cufr6eaiG40',
  'Last Modified': 'fldIN12KX1kRKSS8Z',
  'Name for app #code': 'fld00XfuMXLkJUDJB',
  'Lab Shortcut Name (from Labs Used for)': 'fldi7FC7EwaeM2y0y',
  'Client Simple Name Clean (from Client)': 'fldGYbqijfzSNI2L6',
  'Courier Pickups': 'fldm9P0Kh4nu8K97v',
  'Lab Record ID': 'fld6tbxltwyexgaTK',
  'Ship Date Rollup (from Box)': 'fldAVttxs81asjaeS',
  'Boxes Waiting?': 'fld0olbZ4I4hOpM2Y',
  '# Boxes Waiting': 'fldL6qSqU0JDVQkwQ',
  'Box IDs Waiting': 'flduJpOOQpntaWtoP',
};

export const ShiftDropoffsFieldIdMapping = {
  'Name': 'fldYUTLl16Wb85A2t',
  'Shift': 'fldBARVqNA27onChh',
  'Dropoff': 'fldgrPisRUwtzf3de',
  'Dropoff Location': 'fldLenJDQmcTbgr4P',
  'Dropoff Lat': 'fldaNpXyaGNHAMeMV',
  'Dropoff Lon': 'fldE7qPoG6QQdWB7c',
  'Jobs': 'flduR5YuIdJXM95Mr',
  'Order': 'fldG0tzSXCBJLLFbC',
  'Operator Name': 'fldMRThvDQHUEKfGO',
  'Robot': 'fldp05vBfnJjuOdm2',
  'Dropoff Lab': 'fldAVz4G06RGml3UO',
  'Created': 'fldS8uC4FdajpFdFr',
  'Dropoff Notes': 'fld7vIrjyFeqE4LI2',
  'Sched Date': 'fldxMCkD368HbxFt7',
  'Operator Email': 'fldahfjdlEDBeRuCB',
  'Dropoff Name': 'fldeJSGuT4bZsER4Q',
  'Dropoff Hours': 'fldRK9289aE3fJTr3',
  'Dropoff Complete': 'fldTHYCI06UsXIROd',
  'Active Shifts Rolllup': 'fld7n1EpZd3uQg72D',
};

export const LabTestsFieldIdMapping = {
  'Name': 'fldfASh7QRfffxulr',
  'Lab': 'fldCAVwl90Csvd6dK',
  'Restricted Barcode Types (Lab) (from Lab)': 'fldC7fHxEv7qGDlaY',
  'Test Package Details': 'fldNfW9z57uiGlP2S',
  'Notes': 'fld7CYcEWVYQLGily',
  'Type': 'fldzgsxgc9Boa9j0D',
  'Code': 'fldGHV8rULQljZunB',
  'Lab Email for CSV Send Lookup': 'fldgoTEq4Mr5z4Ifa',
  'Code_Mod': 'fld3H3Xl4UYCssJS6',
  'Pre-Submit Type Special': 'fldbr4XA46B5zC4Go',
  'Lab Presubmit Code?': 'fldn9CBfOUQyQMFym',
  'Submit Type': 'fldBc2mEM4q3JxrCS',
  'Lab Submission Data': 'flddkMlISxtRwE4Ty',
  'Clients Using': 'fldrkcvklogg6SP4a',
  'Lab Shortname': 'fldy4hSjmzJXOJwrj',
  'Lab Pts Edit Method': 'fldIvAILWlIpc1PbX',
  'Field 20': 'fld2xRMGPwvt9ZSCN',
  'Lab Code': 'fldbZrh4XM8txXI1s',
  'Job Prim-RecID Rollup': 'fldgCqfIy0iT4aPbZ',
  'PDF Order Type': 'fldsLxzJShpXRmbp8',
  'Deals': 'fldqLrcMwCUEEikBk',
  'Lab Primary Delivery Method': 'fld8Pq3WnLCREr4PJ',
  'Lab Address': 'fldC7COVEr2Ap9eEI',
  'EFC Code': 'fldJkuyxban5EUFD3',
};

export const TeamFieldIdMapping = {
  'Name': 'flddlHwEkZPUc18zB',
  'Role': 'flduwJkwwqtTLVs8i',
  'App Login Approved?': 'fldeIEc7uCQviC0aE',
  'App password (hashed)': 'fldkril56JF0tF2i5',
  'App role ID': 'fldglj5FW4DqjIYCX',
  'App Username': 'fldoubXHptqnJEGQA',
  'App User ID': 'fld14iB0Xy3AwFeTR',
  'Email': 'fld0FDjwBjaSaFAn5',
  'Job': 'fld5EYpzDPT3VqHfc',
  'Salesperson for': 'fldLQEuiJqIgGDqo1',
  'Client Mgr for': 'fld2rBy284A0NRPG2',
  'Job Edit Forms': 'fldjBm6b4SguzAQy1',
  'Shifts': 'fld55ZiXnJHQ3PEv0',
  'Status': 'fldEBpwEflVYG0ky1',
  'Jobs': 'fldGFJXdejfTkraEI',
  'Companies': 'flder3LFejCpaHfAZ',
  'Jobs 2': 'fld1WVHWBSooUUwW6',
  'Deals': 'fldQjwiDkP8yldkNE',
  'Root Cause': 'fldzFufehD9FViM2m',
  'Salesperson for copy': 'fldoYbVEiB8dcKleq',
  'Phone Number': 'fldBqH4rvaFa2jqtZ',
  'Pay Type': 'fldOCHqS5ipKMNJyk',
  'Shift Times Link': 'fldMyFpz8UYNifzRF',
  'Team': 'fld3sR7aYbzkiucHy',
  'Bonuses/Fines': 'fldqSD4p0k1xN5eBr',
  'Bonus $/hr': 'fld492Uc0t4DDGMxS',
  'Bonus $/spl': 'fldQYrra8ElBGdP3v',
  'Bonus Balance': 'fldwuhAUkWONGi3Ly',
  'Bonus (current period)': 'fldjdlBdQyRSpRipb',
  'Bonus (last period)': 'fldvyhASarZEirWSK',
  'Adjusted Bonus (last period)': 'fldNx0Rbyzafk0vqJ',
  '# Spls - 2wk': 'fldiCVpPHUwt1irEB',
  '# Bnds': 'fldMMRxK2BtjqbNwo',
  'Splng Hrs - 2wk': 'fldwt6sY2hTL3kkwz',
  'Spls/Hr': 'fldctRMd6gVDp8VUb',
  'Tot Incident # - 2wk': 'fldgE8MZvMyKXBFAq',
  'Integrity Incidents - 2wk': 'fldlaUupJjdEF8GTK',
  'Quality Incidents - 2wk': 'fld44gqr9yGZM4QnO',
  'Equipment Health Incidents - 2wk': 'fldzRjgARY5CJNjvQ',
  'Misc Incidents - 2wk': 'fldfL7pNtZqwlrvxC',
  'Bonuses/Fines 2': 'fldgy3i2M8UgYNySF',
  'Inspections': 'flde258HjEXgpKbCE',
  'Issues': 'fldNUYleqG5RDae4J',
  'Issues 2': 'fldgaXWNGFpCbYCXo',
  'Issues 3': 'fldZeyH5fCE2WWYcQ',
  'Tot Incident $ - 2wk': 'fldXWkjdjd0K09lLB',
  'Positive $ - 2wk': 'fld1YqiuceNdo5dnN',
  'tot inc # check': 'flduKX95FIdqCSdUs',
  'Bon/Hr Actual': 'fldyzGkMp4DQsoilX',
  'Bon/Hr Pot': 'fldhriR9lbygWuZcT',
  'Bon % Achieved': 'fldRVDwlO6eIZUu1I',
  'Splng Hrs - All': 'fldERr8fvGvvo91W0',
  'Tot Net Fines $ - 2wk': 'fldjCyqetRzuqWJ4J',
  'Created': 'fldCFmL5ZsnsT0NW7',
  'New Hire (season)': 'fldcbM2xbTJwBnARy',
  'Location': 'fldAlU8PyeTelTUMH',
  'Geocache Code Field': 'fld2f0MuVcq6Gh0dz',
  'Webhook Support Chat': 'fldTQshfNzSS6d28Y',
  'Link Support Chat': 'fldS8thfOKr6dVz6E',
  'Chat User ID': 'fldl5YtXrurdr4BZr',
  'Shift Prim-RecID Lookup': 'fld9xyPSepHyReh54',
  'Ops Manager Region': 'fldksYNT3y1vSv2bX',
  'Labs': 'fldWKeIq8rfCbx6PW',
  'Other Chat Info': 'fldmYys8KcfLFfRl6',
  'Phone Model': 'fldi27TiaGTcLZFeD',
  'iPhone?': 'fldFaqXLaSVecGQSj',
  'Region': 'fldM6vO75oMbvv7av',
  'Clocked In?': 'fldJv9nNFgQ9g9m5w',
  'Characters Up to Space in Name': 'flde9osnOGzrR7bkg',
  'Ops Mgr Changed': 'fldeXsaaOobx9XN9b',
  'Ops Manager Region copy': 'fldw5iJGF1UmeU8DL',
  'Status Change Date': 'fldmYRtbKh7kOx9i6',
  'Approved Labor in last 4 months?': 'fldhD4FXAVq2IjsMV',
  'User Flags': 'fld0C7VPDIhwVhMHO',
};

export const GrowersFieldIdMapping = {
  'Grower Name': 'fldBOs2iYpdnhmUvl',
  'Grower Name + Phone': 'fldXs9KP6fbSBu1k0',
  'Duplicate Warning?': 'fldqte4kpAdSoWP1m',
  'Manual Grower Name Replace Gen': 'fldtkccWTQtPwPsnC',
  'Grower Name Clean': 'fldjcbIcVEGL32usg',
  'Jobs': 'fldIrYz3AXjywsYbk',
  'Created': 'fldjRGQVWcgonoXoa',
  'job season unique': 'fldM7jPwTe8FnhadH',
  'Clients Unique': 'fldfiEHavnsKCkqnK',
  'job latest create date': 'fldae00Xi435EiOaR',
  'Jobs last edit date': 'fldtU5mTQMrOxR05o',
  'Sampling Notification Phone Number': 'fldZG40MsGJ3cEbTe',
  'Contacts': 'fld5O6FrOJfjzJB5A',
  '3rd Party Software ID': 'fldfCiASzA0Kf7gDw',
  'Company': 'fld7sX7Ug8nO67vwA',
  'Last SMS Notified': 'fldu8FXzl01ySj52K',
};

export const BoxFieldIdMapping = {
  'Box ID': 'fldiMLtOTLg50mz1t',
  'Tracking Number': 'fld8wsgP0OTTbeLbt',
  'Jobs': 'fldkUhD8sXEGd39sC',
  'Samples': 'fld228ecgY2Aje4FX',
  'User ID': 'fldkkrTLWRstsWDeD',
  'Ship Date': 'fldByac0mTabZwDf3',
  'Lab Name': 'flduil44GmFwmDIAn',
  'Image Link': 'fld1jrtVmTx8E4IPr',
  'Drop/Ship Date (ovrd from jobs)': 'fldQsZdlrRob2MEfJ',
  'Sample Date': 'fldfxaxzo9vZvGnTn',
  'Drop/Ship Date (from Jobs)': 'fldBHIZtLe8lMtEEu',
  'Exact Dropoff Lat': 'fldQRWavSh9bbWEMr',
  'Exact Dropoff Lon': 'fldPom551jylI6sHT',
  'Dropoff Link': 'fldR2VtkkMoOgdnOe',
  'Name (from Dropoff Link)': 'fldpAMZed5FPbrhta',
  'Issues': 'fldHdl3Piaa7Ryo26',
  'Drop Date (no time)': 'fldAeUCYwOEZ1OQnb',
  'Created Time': 'fldOX0GCF8mmxv7BT',
  'Box Images': 'fld72T75Ndn6sbbQL',
  'Location': 'fldmmYNqga2H9nKXF',
  'Lab Email from Jobs': 'fldyNtl9PaGNWR5E8',
  'Dropoff Type (from Dropoff Link)': 'flde9DCm5mWgKp085',
  'Carrier Type (From Dropoffs)': 'fldPqufip4PrZHFvo',
  'Box Airtable Rec ID': 'fldIDuJEY2JBWrGwf',
  'Job Prim-RecID Lookup': 'fldGwNmzwtCNmtpu7',
  'Lab LINK (auto test)': 'fldZkY5zUYdvRpu9q',
  'Has Image Link': 'fld1BEGmjrvCt8z8i',
  'Ship Date Last Modified By': 'fldG2urkH4krEO2tq',
  'Tracking Status': 'fldqkxdhAuzGIEkTq',
  'App Created Time': 'fldJgqWZZdPIWm3wD',
  'Daily Box Index': 'fldvLD6lXGkZ8tLTx',
  'Job Box Index': 'fldWgCGk5JifRoZhk',
  'Closed': 'fldbtIUIoyaLDG12c',
  'Job Count': 'fldl8v7SXwFbNUKba',
  'Created Month': 'fldNYnR1ZIwvIdMMo',
  'Created Year': 'fldhgzQtqkaa81aPn',
  'Lab - Final (from Jobs)': 'fldDSKP4q37HosSoo',
  'Friendly ID': 'fldMpLzlMuHjFh80Z',
  'Closed Timestamp': 'fldOg3HbfxMjR28Kp',
  'Tracking Number Timestamp': 'fldPFltBaAxPVM7IG',
  'Sample Count': 'fldsIY7AlpBJf3du9',
  'Lab/UPS Notified of Dropoff': 'flduNe084oanKJS8N',
  'Dropoff Address': 'fldfGWCfysSAxVodC',
  'Pickup Scheduling NOT Required? (from Dropoff Link)': 'fldGllMns7xtGew7m',
  'Latest Sample Date': 'fldCc2fXo350zAr8U',
  'UPS tracking lookup': 'fldkNMtvFEzGhnMmw',
  'Image': 'fldCtVLeolzio9m7L',
  'Last Box Pickup Time': 'fldFl1UjErrSEPVD4',
  'Waiting?': 'fldAmNK3yPHG2sjwj',
  'Rogo Label': 'fldmSgSDPqJeV9Qwo',
  'EasyPost Tracker ID': 'fldmYQOl0aFSDiwV8',
  'Lat (From Dropoff Link)': 'fld4J3TSvP09WX6sc',
  'Lon (From Dropoff Link)': 'fld17xH7fLbZpR5nW',
  'Box Lat Lon Matches Dropoff?': 'fldk8VQy9vrOrz3Ly',
  'Box Dropoff Lat Lon Error': 'fldxSOVW88criELXN',
  'Box State': 'fldaD6Bytxs7imTqs',
  'Signed By': 'fldhzQLn3L0PoKjoc',
  'Flags': 'fldKyIwMeiYFxa44n',
  'UPS Track': 'fldwVX83wkuZG4Rze',
  'Box Ops Notes': 'fldr1aaHCXWqu8Gl6',
  'Clients': 'fldgq7iDDfRm1fIrE',
};

export const BoxShipmentsFieldIdMapping = {
  'Name': 'fldmbgE6Ng0dPWQBL',
  'Image': 'fldW9Hfp928vYImQ9',
  'Box': 'fldaCYDJ7wKTzw2GF',
  'Creation Date & Time': 'fldFXdpSkzzK0wpz2',
  'Box User': 'fld98CsGyokkCCJne',
  'Flag (internal manual use)': 'fldL9HoSfDaYQPvZ7',
  'Image S3': 'fldWCRfq6VLIK00B5',
  'Boxes scheduled for pickup': 'fldbB0CFDIJpMG2WT',
  'Lab Name': 'fldGm4Umf7Qp9du6o',
  'Dropoff Type (from Dropoff Link) Rollup (from Box)': 'fldH4ymiH5XMiMa58',
  'Carrier Type (From Dropoffs) Rollup (from Box)': 'fldiyQR2IBEEfjR7R',
  'Notes': 'fldkUCChG2qnVRgv9',
  'Box Count': 'fldnQ0f6PtH0Oth0N',
  'All Boxes Linked': 'fldeUo5sJgtiC2YBB',
  'Courier Email Sent?': 'fldSqKKCoX8zEwnSV',
  'Manual Box Upload Images': 'fld8jH2UL9lMh9rYO',
  'Pickup Scheduling NOT Required? (from Dropoff Link) Rollup (from Box)': 'fldIVr0ORdkUFVfqE',
  'Dropoff Link (from Box)': 'fldbWuagA5mUnRV1e',
  'Created By': 'fldgeyMi2uGDHXg5m',
  'Exact Dropoff Lat Rollup (from Box)': 'fldWCGDeZuTEU3cOM',
  'Exact Dropoff Lon Rollup (from Box)': 'fld0UeWMBBVcHm1aN',
  'Courier Email Sent Date': 'fld5RycHSD1Zjldiv',
  'Calculation': 'fldxD8qyKfIbFhCse',
  'Box Record IDs': 'fldoXY6uFs84wHboW',
  'Unique Box IDs': 'flddZBwjnFHQ4ImeT',
  'TEST NEW All Boxes Linked': 'fld7MgtBoMUq1aIja',
  'Creation Date': 'fldsxuzx1gwx4DeUy',
  'Shipment Flags': 'fldootj8mJtfEzD12',
  'Exact Lat': 'fldPabSsKV3VwEGz2',
  'Exact Lon': 'fld1YKv8ZnQIHGrla',
  'Hostname': 'fldCgjykP30CZ95ok',
};

export const CourierPickupsFieldIdMapping = {
  'Name': 'flds3mJFd2kQl3DxG',
  'Qty Boxes Picked-Up': 'fldJFSs34onEbAj0h',
  'Notes': 'fldOo8M4kp1tAtRi8',
  'Lab': 'fld5tzcGo5WH22OZk',
  'Created': 'fldWpehBDoZ9eneVl',
  'Dropoff Link': 'fld1dZgOCn3ryhVcA',
  'Attachments': 'fld4HkKRszbHwwROT',
  'Form Source': 'fldMxMOPNpMP0BB13',
  'Create Time OVRD': 'fldvicg6wQziEXxfk',
  'Create Time Final': 'fldxh86pNfxbU25qA',
};

export const CrmBasePartitionsFieldIdMapping = {
  'Name': 'fldhdbpYKjfrHx8GL',
  'Start': 'fldV3MZHbGgLFfsW7',
  'End': 'fldKFqwa7djgjrpgA',
  'Base ID': 'fldLJEn4Ay4QNUraP',
  'Is Current?': 'fldrVgE8aOtKM3bQC',
};

export const InspectionsFieldIdMapping = {
  'Name': 'fldTaMg72qb3Yhv4j',
  'Inspection Type': 'fldaw9AzZkxTdtKlL',
  'Inventory Verified?': 'fldNeGPlOul1ZaZvd',
  'Submitter': 'fldzu7yswnE6bGxZ4',
  'Create Date': 'fldNQ2Pp3nEFkEB4q',
  'Create time': 'fldbtggpMWuGqAmvR',
  'Shift Link': 'fldi5vDP3XikeEZ4g',
  'Sched Date': 'fldAULDCj6bZWQpiu',
  'Sampling Arm Damaged? #inspection': 'fld0811KJjRyqb6EB',
  'Kubota Underbody Damaged? #inspection': 'fldyfDCyz0Z2bZWdp',
  'HPU Damaged? #inspection': 'fldQDe7XLetX7Er06',
  'Kubota Exterior Damaged? #inspection': 'fldx3cyQ3hoSP6GXA',
  'Kubota Interior Damaged? #inspection': 'fldyMqPiTjm2eB4tw',
  'Trailer Exterior Damaged? #inspection': 'fldSPhUKyDbLbbv8G',
  'Trailer secured? #inspection': 'fldxr1r5h2zCyBzSz',
  'Trailer Load Secured? #inspection': 'fldXdIJMUYxgDDOJZ',
  'Vehicle Exterior Damaged? #inspection': 'flduOGnLdNxiLpdIK',
  'Vehicle Interior Damaged? #inspection': 'fldT67oFhPyQODw5z',
  'Fluids Checked? #inspection': 'fld4N1xHdjZJk3MvU',
  'Engines Checked? #inspection': 'fldTrHbj3lEbpqrSm',
  'Key tools checked? #inspection': 'fldbPoRvRAUBxpfME',
  'Sampling Arm Damaged? Notes #inspection': 'fldcKVzpQ2aNlo6i3',
  'Kubota Underbody Damaged? Notes #inspection': 'fldBP9BwcjskdJqzc',
  'HPU Damaged? Notes #inspection': 'fldnD5cPUJCFPBXij',
  'Kubota Exterior Damaged? Notes #inspection': 'fldVLqTVgXu9bHPIT',
  'Kubota Interior Damaged? Notes #inspection': 'flddcVnLtuVwQj4Oz',
  'Trailer Exterior Damaged? Notes #inspection': 'fldxA1x2c8x803Pil',
  'Trailer secured? Notes #inspection': 'fldbeCAnmQoOEb8kf',
  'Vehicle Exterior Damaged? Notes #inspection': 'fld10h9yKn7F1VWFi',
  'Vehicle Interior Damaged? Notes #inspection': 'fldm5OUbzZrK8aACO',
  'Damage flagged by inspection?': 'fldaa6rhUzI41YWt5',
  'Issue tracking damage': 'fldD63ztKCJ3ViVf2',
  'Cleaning nubs stock #inspection': 'fldcLhD6jYtrB0PO6',
  'Drill bits stock #inspection': 'fldogzSDzYZ8nyK3P',
  'Boxes stock #inspection': 'fldQrdcmU0KnZiLIV',
  'Issues NEW': 'fldvM1fhbuUoHsGox',
  'Issue Found?': 'fldzjBffHIKYBZ61e',
  'Transferred to Issues Table': 'fldUZSfWkJxu5ianK',
  'Equipment': 'fldnBH2jnccElJhcN',
  'Issue Description': 'fld6T0xoD35M7nxiP',
  'Attachments': 'fld60uLZ2k9nQrVWo',
  'Repair Needed?': 'fldIIv9S7gZY1yluX',
};

export const EditFormsFieldIdMapping = {
  'Submitter': 'fldSCai63utt6vV0y',
  'Creation Date': 'fldeAFpgevl5M0Ipq',
  'Team Member': 'fldMVnZGybJlg4ruJ',
  'Jobs Corrected': 'fldzfQ9kHOTNO8qn6',
};

export const FinesFieldIdMapping = {
  'Name': 'fldsm82vk7j6zYuhB',
  'Area': 'fldRer5wcPcFfKo02',
  'Item': 'fldN6HSkSeORIRRwT',
  'Fine amount': 'fldNrTx8NszcWIao5',
  'Bonuses': 'fldzIqswWmX8LKabA',
  'Issues': 'fldgtn7zCmZo3Aze6',
};

export const EndOfShiftIssueReportFieldIdMapping = {
  'Name': 'fldvjVcjnTaBZfl1R',
  'Notes': 'fldnuBln2lq4YYhHL',
  'Number of Times Robot Disconnected': 'fldP5ZZW2RV1LZg2T',
  'Date': 'fldpReaoxvK6sB78l',
  'Robot': 'fldZ8zmCmSTbf9v2S',
  'Number of times the arrow would disappear on the map': 'fldZzHqcNMQmvZy3S',
  'Beta App?': 'fld4yRg44oe6VnbKS',
};

export const BonusPeriodsFieldIdMapping = {
  'Name': 'fldiKBKL2kwVkgvNs',
  'Start Date': 'fldPiPELfJawXj4N1',
  'End Date': 'fldZ4jk5xAnjOdA5w',
  'Current Period?': 'fldgDDFxODGGwtctr',
  'Previous Period?': 'fldHERRTfGOAt2053',
  'Next Period': 'fldBpEZTLuCxbVUZe',
  'All Bonuses/Deductions For Period': 'fld80Br0dlBkAtMvd',
  'Payouts for Period': 'fldo41Som0u4GbDUR',
};

export const BonusesFinesFieldIdMapping = {
  'Name': 'fldowxhdZfhHHgFkr',
  'Team Member': 'fldWRsTxFA6Chpr0q',
  'Bonus Balance': 'fldfhdHIYN4nghT5J',
  'Item Amount': 'fld0pKTVU0H9mkWbH',
  'Fine Reason': 'fldCtSTxOMMhGPT1m',
  'Date Created': 'fldwXQSawVZZw7lqt',
  'Bonus $/hr': 'fldkT8tHnNlER9UvY',
  'Bonus $/spl': 'fldqfSBHAPoCBO8Xs',
  '# Samples Taken': 'fld54o2Rj8m7aj33K',
  'Hours Worked': 'fld4EcvFTH9HXEAQK',
  'Type': 'fldyOOHz3XbL9cr0c',
  'Date OVRD': 'fldC9QjYtnswUMWKp',
  'Date Final': 'fldu2NKQTp7uwAEBH',
  'Bonus Period': 'fldEdzyfkni6IiAlD',
  'Current Period?': 'fldjDYLrlHSG01unX',
  'Previous Period?': 'fldX9UvzkiQhYhQPN',
  'Shift': 'fldbmKyFjbd7yTp9y',
  'Fine': 'fldOcpSO1EKbiK9nS',
  'Amount OVRD': 'fldpRAOHVdSLapTEn',
  'Fine Amount': 'flduMPlQhS4ya3e1b',
  '# Boundaries Collected': 'fldRIGlhrsBuRroji',
  'Fine Area': 'fld3dTEcod5HoUihk',
  '# Units': 'fldpVADZuyiPSLkSg',
  'Bonus Per Unit': 'fldpKTveKkK3QOQDT',
  'Issue': 'fldPEKnN3dtodJxNS',
  'Issue Description and Notes (from Issue)': 'fld51M1pFY1DVmHKW',
  'Equipment (from Issue)': 'fldyeVPFqII0bd4ir',
};

export const OldIssuesFieldIdMapping = {
  'Name': 'fldViE4dprDpKvWp3',
  'Owning Team': 'fldXJ6dQIh7Kqqba0',
  'Issue Status': 'flday5CXxqrVJoG4o',
  'Equipment': 'fldb86D0fV2Vyi2iS',
  'Repair Needed?': 'fldztUsHzA2lCWpE3',
  'Issue Description and Notes': 'fld7M1eOwW7xNrdcK',
  'Team Member Link': 'fldJ4oLYpwUeGWJqD',
  'Submitter / Familiar Person': 'fldBCZmyTo6sXUYkO',
  'Critical Flags': 'fldUfR6yYrsdKwba3',
  'Create time': 'flddB84v9XW2cONQB',
  'Shift Link (if significant)': 'fldkdnrVqYKG0Sqp0',
  'Create Date OVRD': 'fldnO8MPozcWA2hJG',
  'Attachments': 'fld88mz5plBJCFmh8',
  'Systemic issue (ClickUp link)': 'fld8NRA1MkwmR48Ol',
  'Downtime (minutes)': 'fld0baZrLSG8S6xqs',
  'Issue Type': 'fldziyDTVR4nxycjq',
  'Fixer': 'fldqcj1hsOMdrOqZ0',
  'Create Date': 'fldPYUDvqo616S2pa',
  'Field Issue Sub-Type': 'fldxAspKEkLCsQWFk',
  'Operator (from Jobs)': 'fldaLBQeC1elfdzGi',
  'Team Member Final': 'fldGFFAEfDbdTrJmZ',
  'Map-Making Sub-Type': 'fldE42PoTXuQGi3iB',
  'Quality Sub-Type': 'fldeaX8ovIQjvxozt',
  'Equipment Health Sub-Type': 'fldZbZIp7wC8MS6Xq',
  'Integrity/Misc Sub-Type': 'fldg1IuSXNdlOK0ZL',
  'Create Date Final': 'fldIdBTdrlmN9qILQ',
  'Bonuses Table Link': 'fldhYo3tIjsef86lQ',
  'Requires fine?': 'flduRSyAk96crgdCl',
  'Approve fine?': 'fldgilcF5loqMZxJU',
  'Fine': 'fldsf8vhgvnrjujO3',
  'Fine Reason': 'fldK33MM0KsesGDlE',
  'Fine amount': 'fldux3OtaObO2hOVq',
  'Box ID #lab': 'fldheETpyQmPBQSvk',
  'Job ID #lab': 'fldMtLKN9VMa4oU18',
  'Box Link': 'fld2IqYOua0AjUayR',
  'Needs resolution? #lab': 'fldwj4KHdsRUpYAao',
  'Submitter #lab': 'fld1ohvhrLQsVmq4l',
  'Lab feedback #lab': 'fldiKMwlmsNDS98x8',
  'Issue ID': 'fldsHG5aPSpOdtEKa',
  'Inspection link': 'fldMRUB4saArLo0uD',
  'Unknown Team Member': 'fld4fptTd7rLq9lSU',
  'Fix Priority': 'fldUqwtaLVhz6v3iU',
  'Full Issue Type': 'fldYWlQbgyQKvOzeI',
  'Mins since created': 'fldETvuki4ItUSRVD',
  'Ready to Approve Fine?': 'fldv5d6lCzawSLRih',
  'Lab (from Jobs)': 'fld9RQLSp9w5AN9kL',
  'Resolution Notes': 'fldMLmxxVcRQDLmBg',
  'Submitter Interpreter to Lab': 'fldxJEqF17zY2Y3b7',
  'Sub-Type (all)': 'fldlyWPdGUNDkZAKG',
  'Lab ID': 'fldv7pfUCAKYuvzWE',
  'TEST LAB FOR LOOKUP': 'fldx8X597hieP3Yew',
  'TEST 2 LAB FOR LOOKUP': 'fldDGXbdgdQaRGtln',
  'test 2 lookup': 'fldmzseUydIn8P440',
};

export const SourcesFieldIdMapping = {
  'Source Specific Name': 'fldy15x6SWtNHyf1m',
  'Source Group': 'fldvLcOTzIsGwd8TE',
  'Contacts': 'fldXZD0r3HPLizWMj',
  'Deals': 'fldWcEGY6yVMzDSrM',
  'Companies': 'fldocOrAprbWxUgZw',
};

export const CustomerStepsFieldIdMapping = {
  'Name': 'fldq4jqdwjkcYODNJ',
  'Rough Stage': 'fldPlg2sur7wJ0l92',
  'Score': 'fldBEpOfhq4FNP8bl',
  'Deals': 'fldNm7SQ1vOC0KSyE',
};

export const BarcodeTypesFieldIdMapping = {
  'Name': 'fld7kZLMPKjKc9nuU',
  'Human Readable Description': 'fldfIn0WAV1YpR7qJ',
  'Regex': 'fldjzk8I2lHuw6vg6',
  'Example': 'fldnMySy5SHWtxQbx',
  'Companies': 'fldPXotKOsnLWB2RH',
  'Labs': 'fldddzSmg0FAsa7Pr',
  'Deals': 'fldEbwDHtMHRmvAs4',
};

export const TableIds = {
  'Contacts': 'tbl2OGQy64kSZEM93',
  'Companies': 'tblDrzNazvQ3cSezu',
  'Meetings': 'tbl20qU1J5uyhc7FU',
  'Events': 'tblsGc4iLMZa5lz7a',
  'Branches': 'tblqxeZvHdLAg6wuM',
  'Deals': 'tblNcDBCsE9A4oqPV',
  'Quote/Invoice': 'tblrktYGDDR3ZP5LV',
  'Labs': 'tblYKqlSdzwbOImDA',
  'Jobs': 'tblXij9xpNwUCLRIM',
  'Job Ready': 'tblryEwzstfqUeuaG',
  'Shifts': 'tblrDo7eifN5597BN',
  'Shift Times': 'tblte6phY4vKoSx17',
  'Robot': 'tbl7XkHvxrGwFDTjK',
  'Dropoffs': 'tbljYQOmqGEHAQeyb',
  'Shift Dropoffs': 'tblrK7K9WfxIEbtqy',
  'Lab Tests': 'tblX3UomqWBPgisx1',
  'Team': 'tbltyutRGeqeflNU5',
  'Growers': 'tblYtsvOA6ckQYT9J',
  'Box': 'tblC2q4wgNPKhmOkM',
  'Box Shipments': 'tblqn2nZHojfQe2Zf',
  'Courier Pickups': 'tblDwv5Pnm227Djiw',
  'CRM Base Partitions': 'tblMP6j1V4izqFdgM',
  'Inspections': 'tblYByZzRuSx1PFFz',
  'Edit Forms': 'tblT6JBQIXAeEGSaQ',
  'Fines': 'tblxtGBooV5RcrHSq',
  'End of Shift Issue Report': 'tblQSDnOq61zZBfgX',
  'Bonus Periods': 'tbl6xsQ580P7dGEll',
  'Bonuses/Fines': 'tbl6wIbW9YichwrE8',
  'OLDIssues': 'tbl0JqNFevkTN360j',
  'Sources': 'tblUbZ1wCoX7Ik1lo',
  'Customer Steps': 'tblgDcNsWaUlvhfLR',
  'Barcode Types': 'tblEpw2Uwvrg0B7O2',
};

export const TableIdToObjectMapping = {
  'tbl2OGQy64kSZEM93': ContactsFieldIdMapping,
  'tblDrzNazvQ3cSezu': CompaniesFieldIdMapping,
  'tbl20qU1J5uyhc7FU': MeetingsFieldIdMapping,
  'tblsGc4iLMZa5lz7a': EventsFieldIdMapping,
  'tblqxeZvHdLAg6wuM': BranchesFieldIdMapping,
  'tblNcDBCsE9A4oqPV': DealsFieldIdMapping,
  'tblrktYGDDR3ZP5LV': QuoteInvoiceFieldIdMapping,
  'tblYKqlSdzwbOImDA': LabsFieldIdMapping,
  'tblXij9xpNwUCLRIM': JobsFieldIdMapping,
  'tblryEwzstfqUeuaG': JobReadyFieldIdMapping,
  'tblrDo7eifN5597BN': ShiftsFieldIdMapping,
  'tblte6phY4vKoSx17': ShiftTimesFieldIdMapping,
  'tbl7XkHvxrGwFDTjK': RobotFieldIdMapping,
  'tbljYQOmqGEHAQeyb': DropoffsFieldIdMapping,
  'tblrK7K9WfxIEbtqy': ShiftDropoffsFieldIdMapping,
  'tblX3UomqWBPgisx1': LabTestsFieldIdMapping,
  'tbltyutRGeqeflNU5': TeamFieldIdMapping,
  'tblYtsvOA6ckQYT9J': GrowersFieldIdMapping,
  'tblC2q4wgNPKhmOkM': BoxFieldIdMapping,
  'tblqn2nZHojfQe2Zf': BoxShipmentsFieldIdMapping,
  'tblDwv5Pnm227Djiw': CourierPickupsFieldIdMapping,
  'tblMP6j1V4izqFdgM': CrmBasePartitionsFieldIdMapping,
  'tblYByZzRuSx1PFFz': InspectionsFieldIdMapping,
  'tblT6JBQIXAeEGSaQ': EditFormsFieldIdMapping,
  'tblxtGBooV5RcrHSq': FinesFieldIdMapping,
  'tblQSDnOq61zZBfgX': EndOfShiftIssueReportFieldIdMapping,
  'tbl6xsQ580P7dGEll': BonusPeriodsFieldIdMapping,
  'tbl6wIbW9YichwrE8': BonusesFinesFieldIdMapping,
  'tbl0JqNFevkTN360j': OldIssuesFieldIdMapping,
  'tblUbZ1wCoX7Ik1lo': SourcesFieldIdMapping,
  'tblgDcNsWaUlvhfLR': CustomerStepsFieldIdMapping,
  'tblEpw2Uwvrg0B7O2': BarcodeTypesFieldIdMapping,
};
