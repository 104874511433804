import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import { Converter } from "./converter";

export const WKT: Readonly<Converter<string>> = {
    async toGeoJSON(data: string, properties: Record<string, any> = {}): Promise<GeoJSON.FeatureCollection> {
        const featureCollection: GeoJSON.FeatureCollection = {
            type: 'FeatureCollection',
            features: []
        };
        const feature: GeoJSON.Geometry | GeoJSON.Feature | GeoJSON.FeatureCollection = wktToGeoJSON(data);
        if (feature.type === 'FeatureCollection') {
            featureCollection.features = featureCollection.features.concat(feature.features);
        } else if (feature.type === 'Feature') {
            const unifiedProperties = {
                ...properties,
                ...feature.properties
            };
            featureCollection.features.push({
                ...feature,
                properties: unifiedProperties
            });
        } else {
            featureCollection.features.push({
                type: 'Feature',
                geometry: feature,
                properties
            });
        }
        return featureCollection;
    },

    async fromGeoJSON(geojson: GeoJSON.FeatureCollection | GeoJSON.Feature): Promise<string> {
        return geojsonToWKT(geojson);
    }
}