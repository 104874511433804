import { Box, Button, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { resetPassword, validateNewPasswordToken, updatePassword } from '../api/airtable_ops';
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from './LoadingComponent';
import * as Sentry from '@sentry/react';

interface PasswordResetProps {
    setLoggedIn: (user: any) => void;
}

export default function PasswordReset(props: PasswordResetProps) {
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [errors, setErrors] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState('');

    const navigate = useNavigate();

    const localhostInUrl = window.location.hostname.search('localhost') !== -1;
    const queryString = localhostInUrl ? window.location.hash.split('?')[1] : window.location.search; // localhost looks like "index#/reset-password?token=..."
    const params = new URLSearchParams(queryString);
    const token = params.get('token') ?? "";

    const handlePasswordReset = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (emailError) {
            setLoading(false);
            return;
        }
        setErrors('');


        try {
            await resetPassword(email);
            alert('Check your email for a link to reset your password.');
            navigate('/login');
        } catch (error: any) {
            logErrorToSentry(email, error);
            setCustomerErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordUpdate = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (emailError) {
            setLoading(false);
            return;
        }
        setErrors('');

        try {
            if (await updatePassword({"username": email, "password": password, "token": token})){
                alert('Your password has been updated.');
            } else {
                throw new PasswordResetError('Password update failed');
            }
            navigate('/login');
        } catch (error: Error | any) {
            logErrorToSentry(email, error);
            setCustomerErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    function logErrorToSentry(email: string, error: any) {
        Sentry.withScope(function (scope) {
            scope.setUser({ email: email });
            scope.setFingerprint(["custom-" + Date.now().toString()]); // forces Sentry to log each event as a separate issue, rather then grouping them together
            var e = new PasswordResetError(error.message);
            scope.captureException(e, { mechanism: { handled: true } });
        });
    }

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmailError('');
        // validate that username is an email with regex
        if (!emailPattern.test(event.target.value.toString())) {
            setEmailError("Invalid email address");
        }

        setEmail(event.target.value);
    };

    function setCustomerErrorMessage(error: any) {
        if (error.message.startsWith("User does not exist")) {
            setErrors("User does not yet exist in our database. Contact support@rogoag.com");
        } else {
            switch (error.message) {
                case "User is not registered":
                    setErrors("User is not registered. Contact software@rogoag.com"); break;
                case "Invalid link":
                    setErrors("Invalid link. Contact software@rogoag.com"); break;
                case "Error updating password":
                    setErrors("Error updating password. Contact software@rogoag.com"); break;
                default:
                    setErrors(error.message); break;
            }
        }
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setPasswordError('');
        const password = event.target.value;
        if (password == '') {
            setPasswordError('Password cannot be empty')
            return;
        }

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            return;
        }


        setPassword(password);
        if (password != confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

    }

    function handleConfirmPasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setConfirmPasswordError('');
        const confirmPassword = event.target.value;
        if (confirmPassword == '') {
            setConfirmPasswordError('Password cannot be empty')
            return;
        }

        if (confirmPassword.length < 8) {
            setConfirmPasswordError('Password must be at least 8 characters');
            return;
        }

        setConfirmPassword(password);
        if (confirmPassword != password) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
    }

    const [isTokenValid, setIsTokenValid] = React.useState(false);

    React.useEffect(() => {
        async function checkToken() {
            try {
                var validatedEmail = await validateNewPasswordToken(token)
                var isValid = isValidToken(validatedEmail);
                setIsTokenValid(isValid);

                function isValidToken(validatedEmail: string) {
                    var isEmptyToken = (token === null || token === '');
                    var isValid = false;
                    if (isEmptyToken){
                        isValid = false;
                    } else if (validatedEmail !== null && validatedEmail !== '' && !isEmptyToken) {
                        setEmail(validatedEmail);
                        isValid = true;
                    } else {
                        isValid = false;
                    }
                    return isValid;
                }
            } catch (error: Error | any) {
                Sentry.withScope(function (scope) {
                    scope.setUser({ email: email });
                    var e = new PasswordResetError(error.message);
                    scope.captureException(e, { mechanism: { handled: true } });
                });
            }
        }
        checkToken();
    }, []);

    if (isTokenValid) {
        return (
            <Box
                maxWidth="md"
                margin="auto"
                component="form"
                noValidate
                sx={{
                    mt: 1,
                }}
                onSubmit={handlePasswordUpdate}
            >
                {(loading) && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <LoadingComponent />
                    </Box>
                )}
                <TextField
                    variant="outlined"
                    margin="normal"
                    error={!!passwordError}
                    helperText={passwordError}
                    required
                    disabled={loading}
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onBlur={handlePasswordChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    required
                    disabled={loading}
                    fullWidth
                    name="Confirm password"
                    label="Confirm Password"
                    type="password"
                    id="Confirm password"
                    autoComplete="confirm-password"
                    onBlur={handleConfirmPasswordChange}
                />
                {errors &&
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        style={{ color: 'red' }}
                    >
                        {errors}
                    </Typography>}
                <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                    Please enter your new password.
                </Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                Update Password</Button>
    
            </Box>
    
        );
    } else {
        return (
            <Box
                maxWidth="md"
                margin="auto"
                component="form"
                noValidate
                sx={{
                    mt: 1,
                }}
                onSubmit={handlePasswordReset}
            >
                {(loading) && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <LoadingComponent />
                    </Box>
                )}
                <TextField
                    margin="normal"
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error = {!!emailError}
                    helperText = {emailError}
                    onBlur = {handleEmailChange}
                />
                {errors &&
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        style={{ color: 'red' }}
                    >
                        {errors}
                    </Typography>}
                <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                    Please enter the email associated with your Rogo Account.
                </Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                Reset Password</Button>
    
            </Box>
    
        );
    }

}

class PasswordResetError extends Error {
    constructor(message: string, stack?: string) {
      super(message);
      this.name = 'PasswordResetError';
      if (stack){
          this.stack = stack;
      }
      
      Object.setPrototypeOf(this, new.target.prototype);
    }
  }
