import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { ReadableStream } from 'stream/web';
import axios from 'axios';

const s3Client = new S3Client({
  region: "us-east-2",
  credentials: {
    accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  },
});
const BUCKET = 'airtableuploads';
const REGION = 'us-east-2';

export async function upload(filename: string, body: string | Uint8Array | Blob, contentType: string) {
  const upload = new Upload({
    client: s3Client,
    params: {
      Bucket: BUCKET,
      Key: filename,
      Body: body,
      ACL: 'public-read',
      ContentType: contentType,
    },
  });

  return upload.done();
}

function isEncoded(uri: string) {
  uri = uri || '';

  return uri !== decodeURIComponent(uri);
}

export function urlFor(filename: string) {
  if (isEncoded(filename)) {
    return `https://${BUCKET}.s3.${REGION}.amazonaws.com/${filename}`;
  } else {
    return `https://${BUCKET}.s3.${REGION}.amazonaws.com/${encodeURIComponent(filename)}`;
  }
}


export async function downloadFile<T=ArrayBuffer|Blob|string>(url: string, responseType: 'arraybuffer' | 'blob' | 'json' | 'text') {
  const response = await axios.get<T>(url, {
    responseType,
  });
  return response.data;
}


export async function checkIfS3FileExists(url: string) {
  // console.log(`Assembled URL: ${url}`);
  try {
    const response = await axios.head(url, {
      // TODO we need to confirm that this is the right behavior...
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    // console.log(`checkIfS3FileExists Response: ${url} ${response.status === 200}`)
    return response.status === 200;
  } catch (ex) {
    return false;
  }
}