import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_GroupingState,
  MRT_SortingState,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MRT_ColumnFiltersState,
  MRT_ColumnFilterFnsState,
  MRT_ToolbarAlertBanner,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_TablePagination,
  MRT_FilterFn,
  MRT_Row
} from 'material-react-table';

//Material UI Imports
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  lighten,
} from '@mui/material';

//Icons Imports
import {
  AccountCircle,
  Send,
  Delete,
  ContentCopy,
  Save,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowLeft,
  RefreshOutlined,
} from '@mui/icons-material';
import { useSearchParams } from "react-router-dom";

import { ATRecord, Jobs, getField, Companies, update, getFieldId, Branches, Contacts } from '@rogoag/airtable';
import { getBoundaryForJob, getUsersData, getJobs, getPointsForJob, getBranches, updateUserPortalSettings, updateCompanyPortalViews } from '../api/airtable_ops';
import { UserSettings, ViewCollection, ViewDefinition, ViewGroup } from '../types';
import { checkboxColumn, checkboxMultiselectColumn, dateColumn, fileColumn, numericColumn, textColumn } from './MRT_AirTableHelpers';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { UserContext } from '../hooks/UserContext';
import ViewSaveDialog from './ViewSaveDialog';
import AirtableRecordSelectionAutocomplete from './AirtableRecordSelectionAutocomplete';
import { toast } from 'react-toastify';
import { sleep } from '../utils';
//import useUrlState from '@ahooksjs/use-url-state';
import { MarkJobReadyPopover } from './MarkJobReadyPopover';
import { LoadingComponent } from './LoadingComponent';
import { Props as ViewSaveDialogProps } from './ViewSaveDialog';

import * as Sentry from '@sentry/react';

class FieldNames {
  columnLabel: string;
  airtable: string;
  columnName() {
    return this.columnLabel.toLowerCase();
  }

  constructor(display: string, airtable: string) {
    this.columnLabel = display;
    this.airtable = airtable;
  }
}

const N_Grower = new FieldNames("Grower", "Grower");
const N_Field = new FieldNames("Field", "Field Name Clean");
const N_Farm = new FieldNames("Farm", "Farm Name Clean");
const N_JobStatus = new FieldNames("Job Status", "Job Status - Simple");
const N_TestPackages = new FieldNames("Test Package", "Test Pckg - Display");
const N_Submitter = new FieldNames("Submitter", "Submitter");
const N_Season = new FieldNames("Season", "Season");
const N_Branch = new FieldNames("Branch", "Branch / Location #form");
const N_BoundaryAcres = new FieldNames("Boundary Acres", "Boundary Acres");
const N_FieldReadyDate = new FieldNames("Field Ready Date", "Field Ready Date");
const N_SampleDate = new FieldNames("Sample Date", "Sample Date");
const N_CreationDate = new FieldNames("Creation Date", "Creation Date with Time");
const N_DropShipDate = new FieldNames("Drop/Ship Date", "Drop/Ship Date");
const N_BoundaryChange = new FieldNames("Boundary Change?", "Bdy Change?");
const N_BoundaryApproved = new FieldNames("Boundary Approved?", "Bdy Approved for Send?");
const N_PointsChange = new FieldNames("Points Change?", "Pts Created/Edited?");
const N_ApprovedBilling = new FieldNames("Approved Billing?", "$ Approved Billing?");
const N_Boundary = new FieldNames("Boundary", "Bnd Shp");
const N_Points = new FieldNames("Points", "Pts Shp");
const N_SampleZones = new FieldNames("Sample Zones", "Sample Zones Shp");
const N_ExecutedPoints = new FieldNames("Executed Points", "Exe Pts Shp");
const N_ExecutedBoundary = new FieldNames("Executed Boundary", "Exe Bnd Shp");
const N_ExecutedPointsBoundary = new FieldNames("Executed Points + Boundary", "Exe Pts + Bnd Shps");
const N_LabSentResults = new FieldNames("Lab Sent Results", "Lab Results Sent Date");
const N_LabResults = new FieldNames("Lab Results", "Lab Results");
const N_SamplingCSV = new FieldNames("Sampling CSV", "CSV checkin - lab");

const DEFAULT_FILTER_FNS: MRT_ColumnFilterFnsState = {
  [N_SampleDate.columnName()]: 'greaterThanOrEqualTo',
  [N_FieldReadyDate.columnName()]: 'greaterThanOrEqualTo',
  [N_BoundaryAcres.columnName()]: 'between',
  [N_JobStatus.columnName()]: 'arrIncludesSome',
  [N_Field.columnName()]: 'fuzzy',
  [N_Farm.columnName()]: 'fuzzy',
  [N_CreationDate.columnName()]: 'greaterThanOrEqualTo',
  [N_DropShipDate.columnName()]: 'greaterThanOrEqualTo',
  [N_TestPackages.columnName()]: 'arrIncludesSome',
  'edits': 'fuzzy',
  'files last edited?': 'greaterThanOrEqualTo',
  [N_Submitter.columnName()]: 'fuzzy',
  [N_Branch.columnName()]: 'fuzzy',
  'map issue': 'equals',
  [N_LabSentResults.columnName()]: 'greaterThanOrEqualTo',
  [N_BoundaryApproved.columnName()]: 'equals',
  [N_BoundaryChange.columnName()]: 'equals',
  [N_PointsChange.columnName()]: 'equals',
  [N_Season.columnName()]: 'arrIncludesSome',
} as const;

const DEFAULT_FILTERS: MRT_ColumnFiltersState = [];
const DEFAULT_GROUPING: MRT_GroupingState = [N_Grower.columnName()];
const DEFAULT_COLUMN_SORT: MRT_SortingState = [];
const DEFAULT_VIS_STATE: MRT_VisibilityState = {};
const DEFAULT_COLUMN_ORDER: MRT_ColumnOrderState = [
  "mrt-row-select",
  "mrt-row-expand",
  N_Grower.columnName(),
  N_Field.columnName(),
  N_Farm.columnName(),
  N_JobStatus.columnName(),
  N_BoundaryAcres.columnName(),
  N_FieldReadyDate.columnName(),
  N_SampleDate.columnName(),
  N_CreationDate.columnName(),
  N_DropShipDate.columnName(),
  N_TestPackages.columnName(),
  "edits",
  "files last edited?",
  N_Submitter.columnName(),
  N_ApprovedBilling.columnName(),
  N_Boundary.columnName(),
  N_Points.columnName(),
  N_SampleZones.columnName(),
  N_ExecutedPoints.columnName(),
  N_ExecutedBoundary.columnName(),
  N_ExecutedPointsBoundary.columnName(),
  N_LabSentResults.columnName(),
  N_LabResults.columnName(),
  N_Branch.columnName(),
  "job flags",
  N_LabSentResults.columnName(),
]

const PARAM_SEPARATOR = '|';

const dashboardStateToURLParams = (state: DashboardURLState): URLSearchParams => {
  const params = new URLSearchParams()
  const {
    columnVisibility,
    sorting,
    grouping,
    columnOrder,
    columnFilterFunctions,
    columnFilterValues
  } = state;
  const urlSorting = sorting.map(sort => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`).join(PARAM_SEPARATOR);
  const urlGrouping = grouping.join(PARAM_SEPARATOR);
  const urlColumnOrder = columnOrder.join(PARAM_SEPARATOR);
  const urlColumnVisibility = Object.keys(columnVisibility).map(key => `${key}=${columnVisibility[key]}`).join(PARAM_SEPARATOR);
  const urlFunctions = Object.keys(columnFilterFunctions).map(key => `${key}=${columnFilterFunctions[key]}`).join(PARAM_SEPARATOR);
  const urlFilterValues = columnFilterValues.map(filter => `${filter.id}:${filter.value}`).join(PARAM_SEPARATOR);

  params.set('sort', urlSorting);
  params.set('group', urlGrouping);
  params.set('vis', urlColumnVisibility);
  params.set('order', urlColumnOrder);
  params.set('fns', urlFunctions);
  params.set('filter', urlFilterValues);

  params.sort();
  return params;
}

const DEFAULT_VIEW_NAME = 'Group by Season, Branch, Grower';

const DEFAULT_VIEWS: ViewCollection = [
  {
    name: DEFAULT_VIEW_NAME,
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: false,
        [N_Branch.columnName()]: false,
        [N_Season.columnName()]: false,
      },
      grouping: [N_Season.columnName(), N_Branch.columnName(), N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Group by Status',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_JobStatus.columnName()]: false,
      },
      grouping: ['job status'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Unready Jobs',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: 'job status', value: 'Unready' },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_TestPackages.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: false,
        [N_ExecutedBoundary.columnName()]: false,
        [N_ExecutedPointsBoundary.columnName()]: false,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Boundary Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: N_BoundaryChange.columnName(), value: true },
        { id: N_BoundaryApproved.columnName(), value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_BoundaryAcres.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        [N_TestPackages.columnName()]: false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: false,
        [N_ExecutedBoundary.columnName()]: true,
        [N_ExecutedPointsBoundary.columnName()]: true,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Points Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: N_PointsChange.columnName(), value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        [N_Grower.columnName()]: true,
        [N_Field.columnName()]: true,
        [N_Farm.columnName()]: true,
        [N_JobStatus.columnName()]: true,
        [N_BoundaryAcres.columnName()]: false,
        [N_FieldReadyDate.columnName()]: false,
        [N_SampleDate.columnName()]: false,
        [N_CreationDate.columnName()]: false,
        [N_DropShipDate.columnName()]: false,
        [N_TestPackages.columnName()]: false,
        "edits": false,
        "files last edited?": false,
        [N_Submitter.columnName()]: false,
        [N_ApprovedBilling.columnName()]: false,
        [N_Boundary.columnName()]: false,
        [N_Points.columnName()]: false,
        [N_SampleZones.columnName()]: false,
        [N_ExecutedPoints.columnName()]: true,
        [N_ExecutedBoundary.columnName()]: false,
        [N_ExecutedPointsBoundary.columnName()]: true,
        [N_LabSentResults.columnName()]: false,
        [N_LabResults.columnName()]: false,
        [N_Branch.columnName()]: false,
        "job flags": false,
        [N_LabSentResults.columnName()]: false,
        [N_PointsChange.columnName()]: true,
        [N_BoundaryChange.columnName()]: true,
        [N_BoundaryApproved.columnName()]: true,
      },
      grouping: [N_Grower.columnName()],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  }
];

interface DashboardURLState {
  sorting: MRT_SortingState;
  grouping: MRT_GroupingState;
  columnOrder: MRT_ColumnOrderState;
  columnVisibility: MRT_VisibilityState;
  columnFilterFunctions: MRT_ColumnFilterFnsState;
  columnFilterValues: MRT_ColumnFiltersState;
}


const urlParamsToDashboardState = (urlParams: URLSearchParams): DashboardURLState => {
  const rawSortParam = urlParams.get('sort');
  const rawGroupParam = urlParams.get('group');
  const rawOrderParam = urlParams.get('order');
  const rawVisParam = urlParams.get('vis');
  const rawFnsParam = urlParams.get('fns');
  const rawFilterParam = urlParams.get('filter');
  const columnFilterValues = rawFilterParam ? rawFilterParam.split(PARAM_SEPARATOR).map(filter => ({ id: filter.split(':')[0], value: filter.split(':')[1] })) : [];
  const viewState: DashboardURLState = {
    sorting: rawSortParam ? rawSortParam.split(PARAM_SEPARATOR).map(sort => ({ id: sort.split(':')[0], desc: sort.split(':')[1] === 'desc' })) : [],
    grouping: rawGroupParam ? rawGroupParam.split(PARAM_SEPARATOR) : [],
    columnOrder: rawOrderParam ? rawOrderParam.split(PARAM_SEPARATOR) : [],
    columnVisibility: rawVisParam ? rawVisParam.split(PARAM_SEPARATOR).reduce((acc: MRT_VisibilityState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value === 'true';
      return acc;
    }, {}) : {},
    columnFilterFunctions: rawFnsParam ? rawFnsParam.split(PARAM_SEPARATOR).reduce((acc: MRT_ColumnFilterFnsState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value;
      return acc;
    }, {}) : {},
    columnFilterValues
  };
  return viewState;
}

interface Props {
  setDrawerOpenState: (open: boolean) => void;
  setDisplayBoundaries: (boundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>) => void;
  setPoints: (points: Record<string, FeatureCollection<Point>>) => void;
  setLoading: (loading: boolean) => void;
  drawerOpen: boolean;
}

const isBetaPortal = window.location.hostname.toLowerCase().includes('portalbeta');

export const JobsDashboardTable = (props: Props) => {
  const userContext = useContext(UserContext);

  const [inputDialogState, setInputDialogState] = useState<ViewSaveDialogProps>({
    open: false,
    title: '',
    content: '',
    submitText: '',
    submit: () => { },
    viewName: '',
  });
  // use react router search params to filter the data
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchStateParams = () => {
    const urlParams = dashboardStateToURLParams({
      sorting,
      grouping,
      columnOrder,
      columnVisibility,
      columnFilterFunctions: columnFilterFns,
      columnFilterValues: columnFilters
    });
    urlParams.sort()
    setSearchParams(urlParams);
  }

  // dashboard state
  const dashboardState = urlParamsToDashboardState(searchParams);
  const [sorting, setSorting] = useState<MRT_SortingState>(dashboardState.sorting ?? DEFAULT_COLUMN_SORT);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(dashboardState.columnVisibility ?? DEFAULT_VIS_STATE);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(dashboardState.grouping ?? DEFAULT_GROUPING);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(dashboardState.columnOrder ?? DEFAULT_COLUMN_ORDER);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(DEFAULT_FILTERS);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(DEFAULT_FILTER_FNS);

  const [retrievingJobs, setRetrievingJobs] = useState(false);
  const [updatingBoundaries, setUpdatingBoundaries] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<ATRecord<Companies>[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ATRecord<Companies> | null>(null);
  const [views, setViews] = useState<ViewCollection>(DEFAULT_VIEWS);
  const [selectedViewDirty, setSelectedViewDirty] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<ViewDefinition | null>(null);
  const [branches, setBranches] = useState<ATRecord<Branches>[]>([]);

  const [jobs, setJobs] = useState<ATRecord<Jobs>[]>([]);
  const [userRecord, setUserRecord] = useState<ATRecord<Contacts>>();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectionDisplayedOnMap, setSelectionDisplayedOnMap] = useState<MRT_RowSelectionState>({});

  const [markJobsReadyVisible, setMarkJobsReadyVisible] = useState(false);

  const selectedJobs = Object.keys(rowSelection).map((key) => {
    const index = parseInt(key);
    if (!isNaN(index) && rowSelection[key]) {
      return jobs[index];
    }
  }) as ATRecord<Jobs>[];
  
  const unreadySelectedJobs = selectedJobs.filter(job => !getField(job, N_FieldReadyDate.airtable));

  useEffect(() => {
    // TODO Might not be necessary, might be ROGO user only setting
    const paramsCopy = new URLSearchParams(searchParams.toString());
    paramsCopy.sort();

    const selectedParams = new URLSearchParams(selectedView?.url ?? '');
    selectedParams.sort();

    setSelectedViewDirty(selectedView === null || selectedParams.toString() !== paramsCopy.toString());

    updateSearchStateParams();
  }, [selectedView, grouping, sorting, columnOrder, columnVisibility, columnFilterFns, columnFilters]);

  async function onCompanyChanged(value: ATRecord<Companies> | null) {
    setRetrievingJobs(true);
    try {
      setSelectedCompany(value);
      if (companyOptions.length > 1) {
        // updateSearchParams('company', value?.id ?? '');
        updateSearchStateParams();
      }
      setRowSelection({});
      let jobs: ATRecord<Jobs>[] = [];

      // remove all company views from the views state
      // const newViews: ViewCollection = [...views.filter(view => view.group !== 'Company Views')];

      const userPortalSettingsString = getField(userRecord, "Portal Settings") as string;
      const userPortalSettings: UserSettings = userPortalSettingsString ? JSON.parse(userPortalSettingsString) : {};

      const newViews: ViewCollection = [
        ...userPortalSettings.views ?? []
      ];

      let branches: ATRecord<Branches>[] = [];
      if (value) {
        jobs = await getJobs(value);

        branches = await getBranches(value);
        setBranches(branches);

        // get company views from 'Portal Views' property on company record
        const portalViews = getField(value, "Portal Views") as string;
        if (portalViews) {
          const companyViews = JSON.parse(portalViews) as ViewDefinition[];
          for (const view of companyViews) {
            newViews.push({ ...view });
          }
        }
      }

      newViews.push(...DEFAULT_VIEWS);

      setViews(newViews);

      // if (!searchParams.toString()) {
      // TODO we should actually get the last visited view, or the customer default view here
      const defaultView = views.filter(view => view.name === DEFAULT_VIEW_NAME)[0];
      const urlParams = new URLSearchParams(defaultView.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      let grouping = dashboardState.grouping;
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setSorting(dashboardState.sorting);
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setSelectedView(defaultView);
      setSearchParams(urlParams);
      // }

      setMultiSelectOptions(jobs, branches);
      setJobs(jobs);
    } catch {
      // handle error
    } finally {
      setRetrievingJobs(false);
    }
  }

  const refreshJobs = async () => {
    try {
      if (!selectedCompany) {
        return;
      }
      setRetrievingJobs(true);
      const jobs = await getJobs(selectedCompany);
      setMultiSelectOptions(jobs);
      setJobs(jobs);
    } catch (error) {
      toast.error('Failed to refresh jobs');
      Sentry.captureException(error, { mechanism: { handled: true } });
    } finally {
      setRetrievingJobs(false);
    }
  }


  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getData() {
      try {
        // setTableIsLoading(true);
        if (userContext == undefined){
          Sentry.captureMessage("userContext is undefined: JobsDashboardTable.tsx", "info");
          return;
        }
        const [userRecord, companyRecords, deals, importOptions, usersCompany] = await getUsersData(userContext?.id);
        setUserRecord(userRecord);
        companyRecords.sort((companyA, companyB) => getField(companyA, "Name", "").localeCompare(getField(companyB, "Name", "")));
        setCompanyOptions(companyRecords);
        if (companyRecords.length === 1) {
          await onCompanyChanged(companyRecords[0]);
        } else if (companyRecords.length > 1) {
          const urlCompanyId = searchParams.get('company');
          if (urlCompanyId) {
            const company = companyRecords.find(company => company.id === urlCompanyId);
            if (company) {
              await onCompanyChanged(company);
            }
          }
        }
      } catch (error) {
        toast.error('Failed to load data');
        Sentry.captureException(error, { mechanism: { handled: true } });
      }
    };

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!jobs.length) {
      getData();
    }
  }, [userContext]);

  // useEffect(() => {
  const updateBoundaries = async () => {
    try {
      // setUpdatingBoundaries(true);

      const selectedJobs = Object.keys(rowSelection).map((key) => {
        const index = parseInt(key);
        if (!isNaN(index) && rowSelection[key]) {
          return jobs[index];
        }
      });

      // open the drawer if rows are selected
      props.setDrawerOpenState(Object.keys(rowSelection).length > 0);

      const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
      const displayedPoints: Record<string, FeatureCollection<Point>> = {};
      await Promise.all(selectedJobs.map(async job => {
        if (!job) {
          return;
        }

        const [boundary, points] = await Promise.all([
          getBoundaryForJob(job),
          getPointsForJob(job)
        ]);
        if (boundary) {
          displayedBoundaries[job.id] = boundary;
        }

        if (points) {
          // @ts-ignore
          // TODO disabling for now. This will instead be turned on/selected on a per job basis. 
          displayedPoints[job.id] = points;
        }

      }));

      props.setDisplayBoundaries(displayedBoundaries);
      props.setPoints(displayedPoints);
      setSelectionDisplayedOnMap(rowSelection);
      return [Object.keys(displayedBoundaries).length, Object.keys(displayedPoints).length] as const;
    } catch (error) {
      console.error(error);
      return [0, 0] as const;
    } finally {
      // setUpdatingBoundaries(false);
    }
  }
  
  const [jobStatusList, setJobStatusList] = useState<string[]>([]);
  const [testPackageList, setTestPackageList] = useState<string[]>([]);
  const [submitterList, setSubmitterList] = useState<string[]>([]);
  const [seasonList, setSeasonList] = useState<string[]>([]);
  const [branchList, setBranchList] = useState<string[]>([]);
  const setMultiSelectOptions = (jobs: ATRecord<Jobs>[], branches: ATRecord<Branches>[] = []) => {
    setSelectList(jobs, N_JobStatus.airtable, setJobStatusList);
    setSelectList(jobs, N_TestPackages.airtable, setTestPackageList);
    setSelectList(jobs, N_Submitter.airtable, setSubmitterList);
    setSelectList(jobs, N_Season.airtable, setSeasonList);
    setSelectList(jobs, N_Branch.airtable, setBranchList, branches);
  }

  function setSelectList(jobs: ATRecord<Jobs>[], fieldName: string, setList: (list: any[]) => void, branches: ATRecord<Branches>[] = []) {
    var all = jobs.map(job => getField_(job, fieldName, branches) as string).filter((value, index, self) => self.indexOf(value) === index).flat();
    var distinct = [...new Set(all)].filter(value => value !== undefined).sort();
    setList(distinct);

    function getField_(job: ATRecord<Jobs>, fieldName: string, branches: ATRecord<Branches>[] = []) {
      if (fieldName === N_Branch.airtable) {
        return getBranchField(job, true, branches);
      } else {
        return getField(job, fieldName);
      }
    }
  }

  function getBranchField(job: ATRecord<Jobs>, filter: boolean = false, b: ATRecord<Branches>[] = []) {
    const branchIds = getField(job, N_Branch.airtable) as string[];
    if (!branchIds) { 
      return ""; 
    }
    if (!branchIds.length) { 
      return ""; 
    }

    const branchId = branchIds[0];
    var branch = branches.find(branch => branch.id === branchId);
    if (!branch) { 
      branch = b.find(branch => branch.id === branchId);
    }
    if (!branch) { 
      return "";
    }

    return getField(branch, "Branch Name", branchId);
  }

  // PROOF OF CONCEPT
  // this function is pretty rudementary, and will need improvement before it can go live.
  // also, it doesn't yet work properly with `onColumnFilterFnsChange: setColumnFilterFns,`
  const booleanFilterFn: MRT_FilterFn<any> = (row, columnId, filterValue) => {
    const columnValue = row.getValue<string>(columnId).toLowerCase();
    const queryElements = filterValue.match(/\w+/g);
    const terms = queryElements?.filter((element: string) => element !== "AND" && element !== "OR").map((element: string) => element.toLowerCase());
    const operators = filterValue.match(/(AND|OR)/g); // Extract operators
    
    if (!terms || terms.length === 0) {
      return true; // No filter applied
    }
    
    const evaluateAnds = (terms: string[], columnValue: string) => {
      return terms.reduce((acc, term) => acc && columnValue.includes(term), true);
    };

    let result = false;
    let currentTerms: string[] = [];
    let currentOperator = "OR";

    for (let i = 0; i < terms.length; i++) {
      currentTerms.push(terms[i]);
      const nextOperator = operators?.[i] || "OR";

      if (nextOperator === "OR" || i === terms.length - 1) {
      const andResult = evaluateAnds(currentTerms, columnValue);
      if (currentOperator === "AND") {
        result = result && andResult;
      } else {
        result = result || andResult;
      }
      currentTerms = [];
      currentOperator = nextOperator;
      }
    }
  
    return result || false;
  };

  function hasValue(record: ATRecord<Jobs>, fieldName: string) {
    return !!(getField(record, fieldName) || false);
  }

  const columns = useMemo<MRT_ColumnDef<ATRecord<Jobs>>[]>(
    () =>
      [
        textColumn(N_Grower.columnLabel, { size: 200 }),
        textColumn(N_Field.columnLabel, { accessorKeyOrFn: N_Field.airtable, filterFn: booleanFilterFn }),
        textColumn(N_Farm.columnLabel, { accessorKeyOrFn: N_Farm.airtable }),
        textColumn(N_JobStatus.columnLabel, { accessorKeyOrFn: N_JobStatus.airtable, filterVariant: 'multi-select', filterSelectOptions: jobStatusList, filterFn: 'arrIncludesSome' }),
        numericColumn(N_BoundaryAcres.columnLabel, { aggregationFn: 'sum' }),
        textColumn(N_Branch.columnLabel, { accessorKeyOrFn: getBranchField, filterVariant: 'multi-select', filterSelectOptions: branchList, filterFn: 'arrIncludesSome' }),
        dateColumn(N_FieldReadyDate.columnLabel),
        dateColumn(N_SampleDate.columnLabel),
        dateColumn(N_CreationDate.columnLabel, { accessorKeyOrFn: N_CreationDate.airtable }),
        dateColumn(N_DropShipDate.columnLabel, { defaultText: 'Not shipped' }),
        textColumn(N_TestPackages.columnLabel, { accessorKeyOrFn: N_TestPackages.airtable, filterVariant: 'multi-select', filterSelectOptions: testPackageList, filterFn: 'arrIncludesSome' }),
        checkboxColumn(N_BoundaryChange.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_BoundaryChange.airtable); } }),
        checkboxColumn(N_BoundaryApproved.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_BoundaryApproved.airtable); } }),
        checkboxColumn(N_PointsChange.columnLabel, { accessorKeyOrFn: (record) => { return hasValue(record, N_PointsChange.airtable); } }),
        textColumn(N_Submitter.columnLabel, { filterVariant: 'multi-select', filterSelectOptions: submitterList, filterFn: 'arrIncludesSome' }), // Lab Results
        textColumn(N_Season.columnLabel, { filterVariant: 'multi-select', filterSelectOptions: seasonList, filterFn: 'arrIncludesSome' }),
        checkboxColumn(N_ApprovedBilling.columnLabel, { accessorKeyOrFn: N_ApprovedBilling.airtable }),
        checkboxMultiselectColumn('Job Flags', 'Map Issue'),

        // BILLING FIELDS
        // textColumn('$ Approved Billing?'),
        // numericColumn('Base Price', { accessorKeyOrFn: 'Base Price Subtotal - 1st Entity #$ Prim', money: true, aggregationFn: undefined }),
        // numericColumn('Total Addon Price', { accessorKeyOrFn: 'Total Addons Subtotal #$ Calc', money: true, aggregationFn: undefined }),
        // numericColumn('Total Price', { accessorKeyOrFn: 'Total Price Subtotal #$ Calc', money: true, aggregationFn: undefined }),
        // numericColumn('$/Ac Cost', { accessorKeyOrFn: '$/Ac Cost - 1st Entity', money: true, aggregationFn: undefined }),
        // numericColumn('Adjustments', { accessorKeyOrFn: 'Addons Text (Adjustments)', money: true, aggregationFn: undefined }),

        // ops template view

        // $ Approved Billing?
        // Base Price Subtotal - 1st Entity #$ Prim
        // Total Addons Subtotal #$ Calc
        // Total Price Subtotal #$ Calc
        // $/Ac Cost - 1st Entity
        // Addons Text (Adjustments)

        // COMPANY OVERVIEW
        // Acres Short of Commit for Company
        // Credit Left $ for Company

        fileColumn(N_Boundary.columnLabel, { accessorKeyOrFn: N_Boundary.airtable }),
        fileColumn(N_Points.columnLabel, { accessorKeyOrFn: N_Points.airtable }),
        fileColumn(N_SampleZones.columnLabel, { accessorKeyOrFn: N_SampleZones.airtable }),
        fileColumn(N_ExecutedPoints.columnLabel, { accessorKeyOrFn: N_ExecutedPoints.airtable }),
        fileColumn(N_ExecutedBoundary.columnLabel, { accessorKeyOrFn: N_ExecutedBoundary.airtable }),
        fileColumn(N_ExecutedPointsBoundary.columnLabel, { accessorKeyOrFn: N_ExecutedPointsBoundary.airtable }),

        dateColumn(N_LabSentResults.columnLabel, { accessorKeyOrFn: N_LabSentResults.airtable, defaultText: 'Not Sent' }),
        fileColumn(N_LabResults.columnLabel),
        fileColumn(N_SamplingCSV.columnLabel, { accessorKeyOrFn: N_SamplingCSV.airtable }),
      ],
    [branches],
  );

  const hasSelections = Object.keys(rowSelection).length > 0;
  const hasGrouping = grouping.length > 0;
  const tableHeight = hasSelections && hasGrouping ? `calc(100vh - 240px)` : `calc(100vh - 190px)`;

  const table = useMaterialReactTable({
    columns,
    data: jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableColumnFilterModes: true,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'subheader',
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableHiding: true,
    enableFacetedValues: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTableFooter: true,
    enableMultiSort: true,
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    // enableRowActions: true,
    // muiTableContainerProps: { sx: { height: `calc(100vh - 240px)` }, },
    muiTableContainerProps: { sx: { height: tableHeight }, },

    muiTablePaperProps: { sx: { height: '100vh - 30px)' } },
    enableRowSelection: true,
    maxMultiSortColCount: 3,
    initialState: {
      expanded: true,
      showGlobalFilter: true,
      density: 'compact',
      pagination: {
        pageSize: 20,
        pageIndex: 0
      },
      columnVisibility: {
        [N_Grower.columnName()]: false
      },
    },
    filterFns: {
      'booleanFilter': booleanFilterFn,
    },
    selectAllMode: 'all',
    state: {
      // URL based state
      columnVisibility,
      // grouping: grouping.filter(group => group !== 'branch' || branches.length > 1),
      grouping,
      sorting,
      columnOrder,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFns,

      // Other state
      rowSelection,

      isLoading: retrievingJobs || updatingBoundaries,
    },
    enableSubRowSelection: true,
    muiCircularProgressProps: {
      Component: <LoadingComponent />,
    },
    onGroupingChange: setGrouping,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    groupedColumnMode: false,
    displayColumnDefOptions: {
      'mrt-row-select': {
        visibleInShowHideMenu: true,
        // enableHiding: true,
      },
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        // @ts-ignore
        GroupedCell: ({ row, column }) => {
          const value = row.getValue(grouping[grouping.length - 1]);
          // if date, return formatted date
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        },
        Cell: ({ row, column }) => {
          const groupingId = grouping[Math.min(row.depth, grouping.length - 1)];
          const value = row.getValue(groupingId);

          let label: string | unknown = value
          // if date, return formatted date
          if (value instanceof Date) {
            label = value.toLocaleDateString();
          }
          return (
            <Stack direction="row" alignItems="center">
              <MRT_ExpandButton row={row} table={table} />
              {/* <Box>{titleCase(groupingId)}: {label?.toString() || "n/a"}</Box> */}
              <Box>{label?.toString() || "n/a"}</Box>
            </Stack>
          );
        },
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
        visibleInShowHideMenu: true,
      },
    },
    paginationDisplayMode: 'default',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      shape: 'rounded',
      variant: 'outlined',
      showRowsPerPage: !props.drawerOpen,
    },
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic..
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      const noJobs = jobs.length === 0;
      const noViewSelected = !selectedView;
      const viewIsReadonly = !!selectedView && selectedView.group === ViewGroup.Default;

      // we will actually always allow the save button when we have a default view selected
      // that way we will allow the user to "copy" the view by saving it under their own name
      const canSaveViewState = !viewIsReadonly && !selectedViewDirty;
      const cantSaveViewState = !canSaveViewState;
      const saveViewDisabled = noJobs && cantSaveViewState;
      const deleteViewDisabled = viewIsReadonly || noJobs || noViewSelected;
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            // gap: '0.5rem',
            pl: '8px',
            // justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>

          {/* These are the components that we will pin to the right */}
          <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {/* only show view controls if NOT expanded */}
              {!props.drawerOpen && (
                <>
                  {companyOptions.length > 1 &&
                    <AirtableRecordSelectionAutocomplete
                      key="Name"
                      loading={false}
                      options={companyOptions}
                      selectedOption={selectedCompany}
                      onChange={onCompanyChanged}
                      label="Choose your company"
                      style={{ width: '20vw', marginTop: 11 }}
                      tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
                      placement='right'
                      size="small"
                    />
                  }
                  <Autocomplete
                    style={{ marginTop: 11, width: '15vw' }}
                    options={views}
                    value={selectedView}
                    disabled={jobs.length === 0}
                    groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
                    getOptionKey={(option) => option.group + option.name}
                    getOptionLabel={(option) => option.name}
                    onChange={async (event, value) => {
                      event.stopPropagation();
                      if (!value) {
                        setSelectedView(value);
                        return;
                      }

                      try {
                        const urlParams = new URLSearchParams(value.url);
                        urlParams.sort();
                        const dashboardState = urlParamsToDashboardState(urlParams);
                        setSorting(dashboardState.sorting);
                        let grouping = dashboardState.grouping;
                        // This is special logic to filter out the branch grouping if there is only one branch
                        if (branches.length <= 1) {
                          grouping = grouping.filter(group => group !== 'branch');
                        }
                        setGrouping(grouping);
                        setColumnOrder(dashboardState.columnOrder);
                        setColumnVisibility(dashboardState.columnVisibility);
                        setColumnFilters(dashboardState.columnFilterValues);
                        setColumnFilterFns(dashboardState.columnFilterFunctions);

                        setSearchParams(urlParams);
                        setSelectedView(value);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label="Select saved view" variant="outlined" size="small" />}
                  />
                  <IconButton
                    onClick={refreshJobs}
                    disabled={jobs.length === 0}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                  >
                    <RefreshOutlined fontSize='large' style={{ 'color': jobs.length === 0 ? 'grey' : 'blue' }} />
                  </IconButton>

                  <IconButton onClick={async () => {
                    let newName = selectedView?.name;
                    // TODO prompt for name
                    const result = await new Promise<[string, ViewGroup] | undefined>((resolve) => {
                      setInputDialogState({
                        open: true,
                        title: 'Save current view',
                        content: 'Please enter a name for this view',
                        submitText: 'Save',
                        submit: (result) => {
                          resolve(result);
                          setInputDialogState({ ...inputDialogState, open: false });
                        },
                        viewName: selectedView?.name ?? '',
                      });
                    });

                    if (!result) {
                      return;
                    }

                    const [name, type] = result;

                    const newViews: ViewCollection = [
                      ...views,
                    ];

                    const newView: ViewDefinition = {
                      name,
                      group: type,
                      url: searchParams.toString(),
                      lastUpdated: new Date().toISOString()
                    };
                    if (selectedView && name === selectedView.name && type === selectedView.group) {
                      newViews[newViews.indexOf(selectedView)] = newView;
                    } else {
                      // look for a view that has the same group type as our new value
                      for (const view of newViews) {
                        if (view.group === newView.group || view.group === ViewGroup.Default) {
                          // insert the view right before the first view with the same group
                          newViews.splice(newViews.indexOf(view), 0, newView);
                          break;
                          // because we always sort the Default group to the 
                          // end of the list, we can break here 
                        }
                      }
                    }
                    setViews(newViews);
                    setSelectedView(newView);

                    if (newView.group === ViewGroup.User) {
                      await updateUserPortalSettings(userContext?.id ?? "", { views: newViews.filter(view => view.group === ViewGroup.User) });
                    } else {
                      await updateCompanyPortalViews(selectedCompany!, newViews.filter(view => view.group === ViewGroup.Company));
                    }
                  }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    // variant='contained'
                    disabled={saveViewDisabled}>
                    <Save fontSize='large' style={{ 'color': saveViewDisabled ? 'grey' : 'green' }} />
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      try {
                        // if this fails, it will throw
                        await navigator.clipboard.writeText(window.location.href);

                        // otherwise if we got here, then it should have worked
                        toast.success('Link copied to clipboard');
                      } catch (error) {
                        toast.error('Failed to copy link to clipboard');
                      }
                    }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    disabled={noJobs}
                  >
                    <ContentCopy fontSize='large' style={{ 'color': noJobs || noViewSelected ? 'grey' : 'blue' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (selectedView) {
                        const newViews: ViewCollection = [...views];
                        const index = newViews.indexOf(selectedView);
                        newViews.splice(index, 1);
                        setViews(newViews);
                        setSelectedView(null);

                        if (selectedView.group === ViewGroup.User) {
                          updateUserPortalSettings(userContext?.id ?? "", { views: newViews.filter(view => view.group === ViewGroup.User) });
                        } else if (selectedView.group === ViewGroup.Company) {
                          updateCompanyPortalViews(selectedCompany!, newViews.filter(view => view.group === ViewGroup.Company));
                        }
                      }
                    }}
                    size={'medium'}
                    style={{
                      marginTop: 8,
                      height: '100%',
                    }}
                    // variant='contained'
                    disabled={deleteViewDisabled}
                  >
                    <Delete fontSize='large' style={{ 'color': deleteViewDisabled ? 'grey' : 'red' }} />
                  </IconButton>
                </>
              )}

              {/* <Button onClick={() => {
                throw new Error('Not implemented');
              }} style={{
                height: '100%',
                marginTop: 12,
              }} color="primary" variant="contained">
                Export
              </Button> */}
              <Button onClick={() => {
                setMarkJobsReadyVisible(true);
              }}
                style={{
                  height: '100%',
                  marginTop: 12,
                }}
                color="primary"
                // disabled={unreadySelectedJobs.length === 0}
                variant="contained" >
                {unreadySelectedJobs.length === 0 ?
                  'Mark Jobs Ready' :
                  `Mark ${unreadySelectedJobs.length} Jobs Ready`
                }
              </Button>
              <Button
                onClick={async () => {
                  const loadingBoundariesToast = toast.loading('Updating boundaries...');
                  const [boundaryCount, pointCount] = await updateBoundaries();
                  toast.dismiss(loadingBoundariesToast);
                  toast.info(`Displaying ${boundaryCount} boundaries and ${pointCount} point groups`);
                }}
                style={{
                  height: '100%',
                  marginTop: 12,
                }}
                color="primary"
                variant="contained"
                disabled={
                  Object.keys(selectionDisplayedOnMap).length === Object.keys(rowSelection).length &&
                  Object.keys(rowSelection).every((key) => !!selectionDisplayedOnMap[key])
                }
              >
                {(selectionDisplayedOnMap !== rowSelection) && props.drawerOpen ? 'Update Map' : 'Show on Map'}
              </Button>
              <IconButton
                onClick={() => {
                  props.setDrawerOpenState(!props.drawerOpen);
                }}
                size={'medium'}
                style={{
                  marginTop: 6,
                  height: '100%',
                }}
                // variant='contained'
                disabled={noJobs} >
                {props.drawerOpen ?
                  <KeyboardDoubleArrowRight fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} /> :
                  <KeyboardDoubleArrowLeft fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} />
                }
              </IconButton>
              {/* <Button
                color="success"
                onClick={handleSkyImport}
                variant="contained"
              >
                Import from AgVance SKY Mapping
              </Button>
              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
              >
                Deactivate
              </Button>
              <Button
                color="success"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant="contained"
              >
                Activate
              </Button> */}
              {/* <Button
                color="info"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleContact} 
                variant="contained"
              >
                Contact
              </Button> */}
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return <>
    <MarkJobReadyPopover
      open={markJobsReadyVisible}
      jobs={jobs.filter(job => !getField(job, N_FieldReadyDate.airtable))}
      intialSelectedJobs={
        markJobsReadyVisible ?
          unreadySelectedJobs :
          []
      }
      setLoading={setRetrievingJobs}
      onSuccess={async () => {
        await refreshJobs();
      }}

      setOpen={(open) => setMarkJobsReadyVisible(open)}
    />
    <ViewSaveDialog {...inputDialogState} />
    <MaterialReactTable table={table} />
  </>;
};

