import { Grid, Tooltip } from "@mui/material";
import PortalTooltip from "./PortalTooltip";

export default function FormField(props: {
    title: string, 
    input: JSX.Element, 
    helpText?: string, 
    required?: boolean, 
    hidden?: boolean,
    error?: string
}) {

    if (props.hidden) {
        return;
    }
    return (
        <>
            <Grid item xs={4}>
                <PortalTooltip title={props.helpText || props.title} placement="top-start">
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', font: 'icon' }}>
                        {props.title}
                        {props.required && <span style={{ color: 'red' }}> *</span>}
                    </div>
                </PortalTooltip>
            </Grid>
            <Grid item xs={7} style={{ width: "100%" }}>
                {props.input}
                {props.error && <span style={{ color: 'red' }}>{props.error}</span>}
            </Grid>
        </>
    )
}