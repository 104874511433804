import { MRT_Cell, MRT_ColumnDef, MRT_FilterFn, MRT_Row } from "material-react-table";
import { Accessor } from "../types";
import { ATRecord, getField } from "@rogoag/airtable";
import { Attachment, FieldSet } from 'airtable';
import { Box, Button, Checkbox, Tooltip, Typography } from '@mui/material';
import { toast } from "react-toastify";
import JSZip from "jszip";
import PortalTooltip from "./PortalTooltip";
import { Attachment as AttachmentIcon, Download, DownloadDone } from "@mui/icons-material";

interface TextColumnOptions<T extends ATRecord<V>, V extends FieldSet> {
  accessorKeyOrFn?: Accessor<string, T, V>;
  size?: number;
  filterVariant?: string;
  filterSelectOptions?: string[];
  filterFn?: MRT_FilterFn<any>;
}
export const textColumn = <T extends FieldSet, U extends ATRecord<T>>(
  header: string,
  {
    accessorKeyOrFn = undefined,
    size = 100,
    filterVariant = 'text',
    filterSelectOptions = [],
    filterFn = 'fuzzy'
  }: TextColumnOptions<U, T> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => {
    const value = (typeof accessorKeyOrFn === 'function') ? accessorKeyOrFn(row) : getField(row, accessorKeyOrFn || header);
    return Array.isArray(value) ? value.join(', ') : value;
  },
  header,
  minSize: size,
  maxSize: size,
  // enableGlobalFilter: true,
  filterVariant: filterVariant,
  filterSelectOptions: filterSelectOptions,
  filterFn: filterFn,
  GroupedCell: ({ cell }) => {
    const value = cell.getValue<string>();
    return (
      <Tooltip title={value}>
        <Typography noWrap>B. {value}</Typography>
      </Tooltip>
    );
  }
}) as MRT_ColumnDef<U>;


interface NumericColumnOptions<T extends ATRecord<V>, V extends FieldSet> {
  accessorKeyOrFn?: Accessor<number, T, V>;
  money?: boolean;
  decimals?: number;
  aggregationFn?: 'sum' | 'average' | 'min' | 'max';
}

export const numericColumn = <T extends FieldSet, U extends ATRecord<T>>(
  header: string, 
  {
    accessorKeyOrFn = undefined,
    money = false,
    decimals = 0.01,
    aggregationFn = undefined
  }: NumericColumnOptions<U, T> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (getField(row, accessorKeyOrFn || header) as number),
  header,
  size: 100,
  // filterVariant: 'numeric',
  filterFn: 'between',
  aggregationFn: aggregationFn,
  Cell: ({ cell }) => (
    <Box
      component="span"
      sx={(theme) => ({
        // backgroundColor: theme.palette.success.dark,
        borderRadius: '0.25rem',
        // color: '#fff',
        maxWidth: '9ch',
        p: '0.25rem',
      })}
    >
      {cell.getValue<number>() ? 
        `${money ? '$' : ''}${Math.round(cell.getValue<number>() * (1 / decimals)) / (1 / decimals)}`
          : '-'
      }
    </Box>
  ),
  AggregatedCell: !!aggregationFn ? ({ cell }) => (
    <Box
      component="span"
      sx={(theme) => ({
        // backgroundColor: 'blue',
        fontWeight: 'bold',
        borderRadius: '0.25rem',
        color: '#000',
        maxWidth: '9ch',
        p: '0.25rem',
      })}
    >
      {/* {money ? '$' : ''}{Math.round(cell.getValue<number>() * (1 / decimals)) / (1 / decimals)} */}
      {cell.getValue<number>() ? 
        `${money ? '$' : ''}${Math.round(cell.getValue<number>() * (1 / decimals)) / (1 / decimals)}`
          : '-'
      }
    </Box>
  ) : null,
}) as MRT_ColumnDef<U>;

interface DateColumnOptions<T extends ATRecord<V>, V extends FieldSet> {
  accessorKeyOrFn?: Accessor<Date, T, V>;
  defaultText?: string;
}

export const dateColumn = <T extends ATRecord<U>, U extends FieldSet>(
  header: string,
  {
    accessorKeyOrFn = undefined,
    defaultText = 'Not Sampled'
  }: DateColumnOptions<T, U> = {}
) => ({
  id: header.toLowerCase(),
  // @ts-ignore TODO Need to make this a better type based paradigm...
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : new Date(getField(row, accessorKeyOrFn || header)),
  header,
  size: 100,
  visibleInShowHideMenu: true,
  filterVariant: 'date',
  filterFn: 'greaterThanOrEqualTo',
  // sortingFn: 'datetime',
  // TODO: enable grouping for dates
  enableGrouping: true,
  Cell: ({ cell }) => {
    const date = cell.getValue<Date>();
    if (!date || isNaN(date.valueOf())) {
      return defaultText;
    }
    return date.toLocaleDateString();
  },
  GroupedCell: ({ cell }) => {
    const date = cell.getValue<Date>();
    if (!date || isNaN(date.valueOf())) {
      return defaultText;
    }
    return date.toLocaleDateString();
  },
}) as MRT_ColumnDef<T>;


interface FileColumnOptions<T extends ATRecord<V>, V extends FieldSet> {
  accessorKeyOrFn?: Accessor<Attachment[], T, V>;
  size?: number;
}
export const fileColumn = <T extends FieldSet, U extends ATRecord<T>>(
  header: string,
  { accessorKeyOrFn = undefined, size = 100 }: FileColumnOptions<U, T> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : getField(row, accessorKeyOrFn || header) || [],
  header,
  minSize: size,
  enableColumnFilter: false,
  Cell: ({ cell }) => {
    const attachments = cell.getValue<Attachment[]>();
    if (!attachments || !attachments.length) {
      return '';
    }

    return (
      <PortalTooltip title={attachments[0].filename}>
        <Button
          // variant="contained"
          color="primary"
          size='small'
          style={{ paddingTop: 0, paddingBottom: 0 }}
          onClick={async () => {
            const toastDownloading = toast.loading('Downloading files...');
            try {
              const response = await fetch(attachments[0].url);
              const blob = await response.blob();

              const zip = new JSZip();
              zip.file(attachments[0].filename, blob);

              zip.generateAsync({ type: 'blob' }).then((content) => {
                const url = URL.createObjectURL(content);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${attachments[0].filename}`;
                a.click();
                URL.revokeObjectURL(url);
                toast.success(`Downloaded ${attachments[0].filename}!`);
              });
            } catch (error) {
              console.error(error);
              toast.error('Failed to download files');
            } finally {
              toast.dismiss(toastDownloading);
            }
          }}
        >
          <AttachmentIcon style={{
            transform: 'rotate(270deg)',
          }} />
        </Button>
      </PortalTooltip>
    );
  },
  aggregationFn: (columnId, leftRows) => {
    const allAttachments = leftRows.map((row) => {
      return row.getValue(columnId) as Attachment[];
    }).flat();
    return allAttachments;
  },
  Header: ({ column, table }) => {
    // The layout will be like this
    // first will be the title
    // after the title will be two buttons
    // one will be to download all files
    // and one will be to download only selected files
    return (
      // Make a box that makes the button below the text
      // <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ flexDirection: 'column' }} >
        <div>{header}</div>
        <Box>
          <PortalTooltip title={`Download all ${table.getPreGroupedRowModel().flatRows.length} files`}>
            <Button
              variant="contained"
              color="primary"
              size='small'
              style={{ paddingTop: 0, paddingBottom: 0 }}
              onClick={async () => {
                const toastDownloading = toast.loading('Downloading all files...');
                try {
                  // TODO we are casting to MRT_Row<U> but this is technically missing a function
                  // I'm not sure why the MRT_Row type is expected to have this function but the Row
                  // type we get here does not, but we don't need that function so this should be
                  // fine for now...
                  const jobs = table.getRowModel().flatRows as MRT_Row<U>[];
                  const attachments = getAllAttachments(jobs, column.id);

                  const result = await downloadAllAttachments(attachments);
                  if (result) {
                    toast.success(`Retrieved and downloaded ${result} files!`);
                  } else {
                    toast.error('Failed to download files');
                  }
                } catch (error) {
                  console.error(error);
                  toast.error('Failed to download files');
                } finally {
                  toast.dismiss(toastDownloading);
                }
              }}
            >
              <Download />
            </Button>
          </PortalTooltip>
          <PortalTooltip title={`Download ${table.getSelectedRowModel().rows.length} selected files`}>
            {/* This span is in place to avoid an MUI error about a disabled child of a tooltip */}
            <span>
              <Button
                variant="text"
                color="primary"
                size='small'
                disabled={!table.getSelectedRowModel().rows.length}
                onClick={async () => {
                  const toastDownloading = toast.loading(`Downloading ${table.getSelectedRowModel().rows.length} files...`);
                  try {
                    // TODO we are casting to MRT_Row<U> but this is technically missing a function
                    // I'm not sure why the MRT_Row type is expected to have this function but the Row
                    // type we get here does not, but we don't need that function so this should be
                    // fine for now...
                    const jobs = table.getSelectedRowModel().rows as MRT_Row<U>[];
                    // const attachments = jobs.map(job => job.getValue(column.id) as Attachment).filter(attachment => attachment);
                    const attachments = getAllAttachments(jobs, column.id);

                    const result = await downloadAllAttachments(attachments);
                    if (result) {
                      toast.success(`Retrieved and downloaded ${result} files!`);
                    } else {
                      toast.error('Failed to download files');
                    }
                  } catch (error) {
                    console.error(error);
                    toast.error('Failed to download files');
                  } finally {
                    toast.dismiss(toastDownloading);
                  }
                }}
              >
                <DownloadDone />
              </Button>
            </span>
          </PortalTooltip>
        </Box>
      </Box>
    );
  },
  AggregatedCell: ({ cell }) => {
    const attachments = cell.getValue<Attachment[]>();
    return (
      <PortalTooltip title={`Download ${attachments.length} files for this group`}>
        {/* Wrapping button with span to avoid MUI errors related to tooltips + disabled buttons */}
        <span>
          <Button
            variant="outlined"
            disabled={!attachments.length}
            color="primary"
            size='small'
            style={{ paddingTop: 0, paddingBottom: 0 }}
            // startIcon={<SimCardDownloadIcon />}
            onClick={async () => {
              const toastDownloading = toast.loading('Downloading all attachments...');
              // download and zip all attachment files
              // then display a toast message

              try {
                const result = await downloadAllAttachments(attachments);
                if (result) {
                  toast.success(`Retrieved and downloaded ${result} attachments!`);
                } else {
                  toast.error('Failed to download attachments');
                }
              } catch (error) {
                console.error(error);
                toast.error('Failed to download attachments');
              } finally {
                toast.dismiss(toastDownloading);
              }
            }}
          >
            <Download />
          </Button>
        </span>
      </PortalTooltip>
    );
  }
}) as MRT_ColumnDef<U>;

interface CheckboxColumnOptions<T extends ATRecord<V>, V extends FieldSet> {
  accessorKeyOrFn?: Accessor<boolean, T, V>;
}

export const checkboxColumn = <T extends FieldSet, U extends ATRecord<T>>(header: string, {
  accessorKeyOrFn = undefined
}: CheckboxColumnOptions<U, T> = {}) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (getField(row, accessorKeyOrFn || header) || false) as boolean,
  header,
  size: 100,
  filterVariant: 'checkbox',
  Cell: ({ cell }) => {
    const value = cell.getValue<boolean>();
    return <Checkbox style={{ margin: 0, padding: 0 }} checked={value} readOnly={true} />;
  }
}) as MRT_ColumnDef<U>;

export const checkboxMultiselectColumn = <T extends FieldSet, U extends ATRecord<T>>(
  header: string,
  value: string,
  accessorKeyOrFn?: Accessor<string[], U, T>
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (getField(row, accessorKeyOrFn || header) || []) as string[],
  header: value,
  size: 100,
  filterVariant: 'checkbox',
  // format as Material UI Checkbox component
  Cell: ({ cell }) => {
    const values = cell.getValue<string[]>();
    // console.log(values);
    return <Checkbox style={{ margin: 0, padding: 0 }} checked={values.includes(value)} />;
  }
}) as MRT_ColumnDef<U>;


function getAllAttachments<U extends ATRecord<FieldSet>>(rows: MRT_Row<U>[], columnId: string) {
  const allAttachments = rows.map((row) => {
    return row.getValue(columnId) as Attachment[];
  }).flat();
  return allAttachments;
}

async function downloadAllAttachments(attachments: Attachment[]) {
  const blobs = await Promise.all(attachments.map(async (attachment) => {
    try {
      const response = await fetch(attachment.url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      // TODO log sentry error?
      console.error(error);
      return null;
    }
  }));

  const zip = new JSZip();
  const validBlobs = blobs.filter(blob => !!blob);
  validBlobs.forEach((blob, i) => {
    zip.file(attachments[i].filename, blob);
  });

  const result = await zip.generateAsync({ type: 'blob' }).then((content) => {
    try {
      const url = URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'attachments.zip';
      a.click();
      URL.revokeObjectURL(url);
      return validBlobs.length;
    } catch (error) {
      // TODO log sentry error?
      console.error(error);
      return 0;
    }
  });
  return result;
}