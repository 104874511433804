import { Tooltip, TooltipProps } from "@mui/material";

export type MUITooltipPlacement = TooltipProps['placement'];

interface Props {
    children: React.ReactElement<any, any>;
    title: React.ReactNode;
    placement?: MUITooltipPlacement;
}

export default function PortalTooltip({ children, title, placement }: Props) {
    return <Tooltip enterNextDelay={300} arrow placement={placement} title={
        <div style={{ whiteSpace: 'pre-line', fontSize: 20 }}>{title}</div>
    }>{children}</Tooltip>;
}