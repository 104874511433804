import axios from "axios"
import { login } from "./airtable_ops"
import { decodeJwt } from "./proagrica_ops"

// Short duration JWT token (5-10 min)
export function getJwtToken() {
    return localStorage.getItem("jwt")
}

export function setJwtToken(token: string) {
    localStorage.setItem("jwt", token)
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return localStorage.getItem("refreshToken")
}

export function setRefreshToken(token: string) {
    localStorage.setItem("refreshToken", token)
}

class ProAgricaTransaction {
    access_token: string | null = null;
    sync_id: string | null = null;
    readonly: boolean = false;
    transaction_id: string | null = null;
    TOKEN_EXPIRATION_MARGIN: number = 60; // Assuming 60 seconds for example
    AGX_SYNC_BASE_URL: string = 'http://example.com'; // Replace with actual base URL

    async startTransaction() {
        console.log("Start Transaction");

        let needsRefresh = true;
        if (this.access_token) {
            const decodedJwt: any = decodeJwt(this.access_token);
            if (decodedJwt) {
                const remainingSeconds = decodedJwt.exp - Math.floor(Date.now() / 1000);
                const timeUntilExpire = remainingSeconds;
                console.log(`Token expires in ${timeUntilExpire} ${timeUntilExpire > this.TOKEN_EXPIRATION_MARGIN}`);
                if (timeUntilExpire > this.TOKEN_EXPIRATION_MARGIN) {
                    needsRefresh = false;
                    this.sync_id = decodedJwt.sub;
                }
            }
        }

        if (needsRefresh) {
            await this.refreshCredentials();
        }

        if (!this.sync_id) {
            throw new Error("No sync ID found");
        }

        if (!this.access_token) {
            throw new Error("No access token found");
        }

        const headers = { 
            "Authorization": `Bearer ${this.access_token}` 
        };

        if (this.readonly) {
            return;
        }

        const response = await axios.get(
            `${this.AGX_SYNC_BASE_URL}/api/v4/Account/${this.sync_id}/Transaction`,
            { headers: headers }
        );

        if (response.data) {
            this.transaction_id = response.data;
        }
    }

    async refreshCredentials() {
        const proagrica_refresh_token = localStorage.getItem('proagrica_refresh_token');
        
        // Implement the refresh credentials logic here
    }
}


// async function handleLogin({ email, password }: { email: string, password: string }) {
//   // Call login method in API
//   // The server handler is responsible for setting user fingerprint cookie during this as well
//   const { jwtToken, refreshToken } = await login({ username: email, password })
//   setJwtToken(jwtToken)
//   setRefreshToken(refreshToken)

//   // If you like, you may redirect the user now
//   return true;
// }