import { Autocomplete, Tooltip, TextField, Grid, Typography } from "@mui/material";
import { ATRecord, Deals, getField, getFieldId } from "@rogoag/airtable";
import PortalTooltip from "./PortalTooltip";
import { matchSorter } from 'match-sorter';

interface Props {
    deals: ATRecord<Deals>[];
    selectedDeals: ATRecord<Deals>[];
    loading: boolean;
    setSelectedDeals: (deals: ATRecord<Deals>[]) => void;
}

function DealLabel(deal: ATRecord<Deals>, generateTooltip = false) {
    const density = getField(deal, 'Density/Grid #serviceinfo');
    const depth = getField(deal, 'Depth (in.) #serviceinfo');
    const dealType = getField(deal, "Sites Type #serviceinfo") || 'Grid';
    const testPackage = getField(deal, 'Test Pckg Display') || 'No test package specified';
    const season = getField(deal, 'Season');
    // const dealLabelFields = [`${density} Acre ${dealType}`, depth, testPackage];
    let label = `${density} Acre ${dealType} - ${depth}" - ${testPackage}`;
    const specialTag = getField(deal, 'Sampling Type Special');
    let tooltip = '';
    if (generateTooltip) {
        tooltip = `Details:
        Density:       ${density} Acre
        Sampling Type: ${dealType}
        Depth:         ${depth}"
        Test Package:  ${testPackage}
        Season:        ${season}`;
    }
    if (specialTag) {
        label += ` ${specialTag}`;
        if (generateTooltip) {
            tooltip += `
            Special:       ${specialTag}`;
        }
    }

    const customizer = getField(deal, 'Config/Deal Type Customizer');
    if (customizer) {
        label += ` - ${customizer}`;
        if (generateTooltip) {
            tooltip += `
            Customizer:    ${customizer}`;
        }
    }
    
    if (!generateTooltip) {
        return [label, tooltip] as const;
    }
    return [label, tooltip] as const;
}

const DEAL_SEARCH_KEYS = ([
    "Density/Grid #serviceinfo",
    "Depth (in.) #serviceinfo",
    "Sites Type #serviceinfo",
    "Test Pckg Display",
    "Season",
    "Sampling Type Special",
    "Config/Deal Type Customizer"
] as const).map(fieldName => getFieldId("Deals", fieldName));

function fuzzySearchMultipleWords(
    rows: ATRecord<Deals>[],
    filterValue: string,
) {
    if (!filterValue || !filterValue.length) {
        return rows;
    }

    const terms = filterValue.split(' ');
    if (!terms || !terms.length) {
        return rows;
    }

    // reduceRight will mean sorting is done by score for the _first_ entered word.
    return terms.reduceRight(
        (results, term) => matchSorter(results, term, {
            keys: DEAL_SEARCH_KEYS
        }),
        rows,
    )
}

export default function DealSelectionAutocomplete(props: Props) {
    const dealKeys = props.deals.map(deal => DealLabel(deal));
    return <Grid item xs={12} >
        <Autocomplete style={{ marginTop: 20 }}
            // multiple // TODO will add multiple deal selection soon
            options={props.deals.sort((a, b) => {
                const [aLabel] = DealLabel(a);
                const [bLabel] = DealLabel(b);
                return aLabel.localeCompare(bLabel);
            })}
            filterOptions={(options, { inputValue }) => fuzzySearchMultipleWords(options, inputValue)}
            disabled={!!props.loading}
            getOptionKey={(option) => option.id}
            //multiple
            value={props.selectedDeals.length ? props.selectedDeals[0] : null}
            getOptionLabel={(deal) => DealLabel(deal)[0]}
            onChange={(event, value) => {
                event.stopPropagation();
                props.setSelectedDeals(value ? [value] : [])
            }} // TODO this is selecting single but we are planning for multiple...
            renderInput={(params) =>
                <PortalTooltip title="Select your configuration. Contact us for more configurations">
                    <TextField {...params} label="Select the most common sampling configuration you need right now" variant="outlined" />
                </PortalTooltip>
            }
            renderOption={(props, option, { selected }) => {
                const [label, tooltip] = DealLabel(option, true);
                return <li {...props}>
                    <PortalTooltip title={<Typography>{tooltip}</Typography>} placement='right-end'>
                        <div>{label}</div>
                    </PortalTooltip>
                </li>
            }}
        />
    </Grid>
}